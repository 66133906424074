import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';
import { Button, ButtonProps } from 'src/ui/Button/Button';

type BackToChatButtonProps = {
  redirectUrl: string;
} & ButtonProps;

const BackToChatButton = ({ redirectUrl, ...props }: BackToChatButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant={'primary'}
      className='font-semibold'
      onClick={() => {
        window.open(redirectUrl, '_blank');
      }}
      {...props}
    >
      <FaArrowLeft className='h-4 w-4 mr-2' />
      {t('paymentStatus.back')}
    </Button>
  );
};

export { BackToChatButton };
