import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '../DropDownRadix/DropDown';

const sequence = (start: number, end: number, reverse = false) => {
  const res = Array.from({ length: end - start + 1 }, (_, index) => start + index);
  return !reverse ? res : res.reverse()
}

function datesInMonth(year: number, month: number) {
  switch (month) {
    case 1:
      const leapYear = ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
      return leapYear ? 29 : 28;

    case 0: case 2: case 4: case 6: case 7: case 9: case 11:
      return 31;

    default:
      return 30;
  }
}

interface DateType {
  year: number;
  month: number;
  date: number;
}

export interface DatePickerProps {
  year: number,
  month: number,
  date: number,
  maxAge: number,
  minAge: number,
  setDate: (date: DateType) => void
}

export const DatePicker: React.FC<DatePickerProps> = ({
  date,
  maxAge,
  minAge,
  month,
  year,
  setDate
}) => {

  const { t } = useTranslation();
  const namesOfMonths: string[] = useMemo(
    () => t('onboarding.common.months', { returnObjects: true }) as string[],
    [t]
  );

  const updateDate = (data: Partial<DateType>) => {
    const newDate = { date, month, year, ...data };
    setDate(newDate);
  };

  const [years, setYears] = useState<{ text: string; value: string }[]>([]);
  const [months, setMonths] = useState<{ text: string; value: string }[]>([]);
  const [dates, setDates] = useState<{ text: string; value: string }[]>([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const minYearCurrent = currentYear - maxAge;
    const maxYearCurrent = currentYear - minAge;

    setYears(
      sequence(minYearCurrent, maxYearCurrent, true).map((v) => ({
        text: v.toString(),
        value: v.toString()
      }))
    );
    setMonths(
      sequence(0, 11).map((v, idx) => ({
        text: namesOfMonths[idx],
        value: v.toString()
      }))
    );
  }, [maxAge, minAge, namesOfMonths]);

  useEffect(() => {
    const numDates = datesInMonth(year, month);
    setDates(
      sequence(1, numDates).map((v) => ({
        text: v.toString(),
        value: v.toString()
      }))
    );

    if (date > numDates) {
      updateDate({
        date: numDates
      });
    }
  }, [year, month, date]);

  const setYear = (selectedYear: number) => {
    updateDate({ year: selectedYear });
  };

  const setMonth = (selectedMonth: number) => {
    updateDate({ month: selectedMonth });
  };

  const setDay = (selectedDate: number) => {
    updateDate({ date: selectedDate });
  };

  return (
    <div className='flex items-center gap-[15px] w-full'>
      <div className='grow'>
        <DropDown
          key={1}
          onValueChange={v => v && setMonth(+v)}
          options={months}
          value={month?.toString()}
          name='month'
          placeholder='Month'
          className='text-[16px] h-[50px]'
        />
      </div>
      <div className='grow'>
        <DropDown
          key={0}
          onValueChange={v => v && setDay(+v)}
          options={dates}
          value={date?.toString()}
          name='date'
          placeholder='Day'
          className='text-[16px] h-[50px]'
        />
      </div>
      <div className='grow'>
        <DropDown
          key={2}
          onValueChange={v => v && setYear(+v)}
          options={years}
          value={year?.toString()}
          name='year'
          placeholder='Year'
          className='text-[16px] h-[50px]'
        />
      </div>
    </div>
  );
};