const steps = [
  {
    name: 'About you',
  },
  {
    name: 'Branding',
  },
  {
    name: 'Subscriptions',
  },
  {
    name: 'Payments',
  },
  {
    name: 'Сhannels',
  },
  {
    name: 'Offer',
  },
];

enum OnboardingStepsEnum {
  AboutYou = 0,
  Branding = 1,
  Subscriptions = 2,
  Payments = 3,
  Channels = 4,
  Offer = 5,
}

export { OnboardingStepsEnum, steps };
