import React from 'react';
import { AuthWrapper } from './AuthWrapper';
import { useDispatch } from 'react-redux';
import { authUser } from 'src/store/user/user.actions';
import { Form } from 'src/components';
import { getApiUrl } from 'src/common/utils/getApiUrl';
import { FaGoogle, FaLinkedinIn } from 'react-icons/fa';
import { TextInput } from 'src/ui/TextInput/TextInput';
import { Button } from 'src/ui/Button/Button';
import { z } from 'zod';
import { emailSchema } from 'src/common/utils/schema/email.schema';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldError } from 'src/components/FieldError';
import { Trans, useTranslation } from 'react-i18next';
import { InstallPWABar } from 'src/components/InstallPWABar';

type Inputs = z.infer<typeof emailSchema>;
interface FormContentProps {
  title: string;
}

const FormContent: React.FC<FormContentProps> = ({ title }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<Inputs>({
    resolver: zodResolver(emailSchema),
  });

  const onSubmit = (data: Inputs) => {
    const { email } = data;
    dispatch(authUser({ email }));
  };

  return (
    <Form title={title} className='px-5 sm:px-[30px] py-6 sm:py-[50px]'>
      <form
        action=''
        className='flex flex-col gap-[16px] my-[25px]'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <TextInput
              className='h-[50px]'
              required
              id='email-auth'
              placeholder='Enter your email'
              value={field.value}
              onChange={e => field.onChange(e.target.value)}
              error={errors.email ? errors.email.message : null}
            />
          )}
        />
        <Button variant='primary' type='submit' className='px-4'>
          {t('auth.sendCode')}
        </Button>
      </form>
      <div className='relative flex items-center'>
        <div className='flex-grow border-t-[0.5px] border-t border-grey-300'></div>
        <span className='flex-shrink mx-4 text-grey-600 uppercase text-[11px] leading-[18px] tracking-widest'>
          {t('auth.or')}
        </span>
        <div className='flex-grow border-t-[0.5px] border-t border-grey-300'></div>
      </div>
      <div className='flex mt-[25px] gap-2'>
        <Button
          onClick={() => window.open(`${getApiUrl()}/google`, '_self')}
          variant='primary_light'
          className='w-full py-[17px]'
        >
          <FaGoogle className='w-[16px] h-[16px] text-danger' />
        </Button>
        <Button
          onClick={() => window.open(`${getApiUrl()}/linkedin`, '_self')}
          variant='primary_light'
          className='w-full py-[17px]'
        >
          <FaLinkedinIn className='w-[16px] h-[16px] text-[#0077B5]' />
        </Button>
      </div>
    </Form>
  );
};

export const LoginPage = () => {
  return (
    <>
      <InstallPWABar />
      <AuthWrapper
        form={<FormContent title='Expert Sign In' />}
        footer={
          <Trans
            i18nKey={'auth.agreement'}
            components={{
              1: (
                <a
                  className='text-primary text-sm md:text-[14px]'
                  href='https://www.anamata.io/terms-of-use'
                />
              ),
              2: (
                <a
                  className='text-primary text-sm md:text-[14px]'
                  href='https://www.anamata.io/privacy-policy'
                />
              ),
            }}
          />
        }
      />
    </>
  );
};
