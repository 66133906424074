import React from 'react';
import { cn } from 'src/common/utils';

export interface TrailProps {
  steps: {
    name: string;
  }[];
  activeStep: number;
}

export const Trail: React.FC<TrailProps> = ({ steps, activeStep }) => {
  return (
    <div className='flex items-center gap-[20px] text-[12px] leading-[18px]'>
      {steps.map((step, idx) => {
        const isActive = idx === activeStep;
        const isPast = idx < activeStep;
        return (
          <div key={idx} className='flex items-center gap-[10px] min-w-fit'>
            <div
              className={cn(
                'w-[19px] h-[19px] rounded-full text-center leading-[19px] bg-light text-grey-500',
                {
                  'bg-primary text-white': isActive,
                  'bg-primary-light text-primary': isPast,
                },
              )}
            >
              {idx + 1}
            </div>
            <div
              className={cn({
                'text-grey-500': !isActive,
              })}
            >
              {step.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};
