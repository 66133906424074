import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSocketContext } from 'src/context/socket.context';
import { ROUTES } from 'src/router/routes';
import { reset, selectUser } from 'src/store/user/user.slice';

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const socket = useSocketContext();

  const logout = () => {
    dispatch(reset());
    navigate(`/${ROUTES.LOGIN}`);
    socket.emit('offline', user?.id);
  };

  return { logout };
};

export { useLogout };
