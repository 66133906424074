import React from "react";
import { cn } from "src/common/utils";

export interface ProgressBarProps {
    completed: number,
    total: number,
    className?: string
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    completed,
    total,
    className
}) => {

    return (
        <div className={cn('w-full h-[5px] bg-secondary relative rounded-[16px] overflow-hidden', className)}>
            <div className={`bg-success absoulte h-[5px]`} style={{
                width: `${completed / total * 100}%`
            }}></div>
        </div>
    )
}