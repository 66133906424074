import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { useOnboardingStatus } from "src/common/hooks";
import { ROUTES } from "src/router/routes";
import { Button } from "src/ui/Button/Button";
import { Skeleton } from "src/ui/Skeleton/Skeleton";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/ui/Table/Table';

export const TableSkeletonBody = () => {
    return <TableBody>
        {new Array(3).fill(1).map((_, i) => (
            <TableRow key={i}>
                <TableCell>
                    <div className='flex gap-2 items-center'>
                        <Skeleton className='w-8 h-8 animate-none rounded-full' />
                        <Skeleton className='w-32 h-4 animate-none rounded-md' />
                    </div>
                </TableCell>
                <TableCell>
                    <Skeleton className='w-32 h-4 animate-none rounded-md' />
                </TableCell>
                <TableCell>
                    <Skeleton className='w-24 h-4 animate-none rounded-md' />
                </TableCell>
                <TableCell className='text-right'>
                    <Skeleton className='w-24 h-4 animate-none rounded-md' />
                </TableCell>
                <TableCell className='text-right'>
                    <Skeleton className='w-24 h-4 animate-none rounded-md' />
                </TableCell>
            </TableRow>
        ))}
    </TableBody>
}

export const MobileSkeletonBody = () => {
    return <div className='flex flex-col gap-[20px] mt-[20px]'>
        <div className='flex justify-between items-center'>
            <div className='flex gap-[8px] items-center'>
                <Skeleton className='w-[30px] h-[30px] rounded-full' />
                <Skeleton className='w-[100px] h-4 animate-none rounded-md' />
            </div>
            <div>
                <Skeleton className='w-32 h-4 animate-none rounded-md' />
            </div>
        </div>
        <div>
            <Skeleton className='w-[50px] h-4 animate-none rounded-md' />
            <Skeleton className='w-[100px] h-4 animate-none rounded-md mt-[8px]' />
        </div>
        <div className='flex items-center justify-between'>
            <div>
                <Skeleton className='w-[50px] h-4 animate-none rounded-md' />
                <Skeleton className='w-[100px] h-4 animate-none rounded-md mt-[8px]' />
            </div>
            <Skeleton className='w-[40px] h-8 animate-none rounded-md mt-[8px]' />
        </div>
    </div>
}

export const TransactionsSkeleton = () => {
    const { t, isCompleted, handleBackToOnboarding } = useOnboardingStatus();

    const handleBack = () => {
        handleBackToOnboarding();
    };

    return (
      <div className='mt-6 gap-20 p-12 justify-center bg-white'>
        <div className='w-full flex justify-center'>
          <div className='max-w-[600px] flex flex-col gap-[24px] text-center items-center'>
            <h1 className='text-[64px] leading-1'>💵</h1>
            {isCompleted ? (
              <p className='text-[16px] -mt-[20px]'>
                <Trans
                  i18nKey={'transactions.empty.subtitle'}
                  components={{ 1: <Link to={`/${ROUTES.LINKS}`} className='text-primary' /> }}
                ></Trans>
              </p>
            ) : (
              <div className='flex flex-col items-center gap-[24px] -mt-[20px]'>
                <p className='text-[16px]'>{t('transactions.uncompleted.title')}</p>
                <Button variant='primary' className='w-fit' onClick={handleBack}>
                  {' '}
                  {t('transactions.uncompleted.button')}
                </Button>
              </div>
            )}
          </div>
        </div>
        <Table className='mt-12 hidden md:table'>
          <TableHeader>
            <TableRow>
              <TableHead className='w-[400px]'>{t('transactions.table.payer')}</TableHead>
              <TableHead>{t('transactions.table.optionPrice')}</TableHead>
              <TableHead>{t('transactions.table.amount')}</TableHead>
              <TableHead>{t('transactions.table.status')}</TableHead>
              <TableHead>{t('transactions.table.invoice')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableSkeletonBody />
        </Table>
      </div>
    );
}