import { MethodEnum, StorageKeysEnum } from 'src/common/enums';
import { PlainObject } from 'src/common/interfaces';

const prepareFiles = (files?: File[]) => {
  if (files?.length) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }

    return formData;
  }

  return null;
};

export const fetchBuilder = async (
  credentials: PlainObject,
  route: string,
  method: MethodEnum,
  files?: File[],
  mode: RequestMode = 'cors',
  isBlob = false,
) => {
  const { token, ...data } = credentials;
  const access_token = token || localStorage.getItem(StorageKeysEnum.TOKEN);
  const media = prepareFiles(files);

  const apiUrl =
    process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : '/api';

  const response = await fetch(`${apiUrl}/${route}`, {
    method,
    headers: {
      ...(!media && { 'Content-Type': 'application/json' }),
      ...(access_token && { Authorization: `Bearer ${access_token}` }),
    },
    mode,
    ...(method !== MethodEnum.GET && { body: !media ? JSON.stringify(data) : media }),
  });

  let body: any = null;
  try {
    body = await (!isBlob ? response.json() : response.blob());
  } catch (err) {
    console.log(err, response.url);
  }

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Unauthorized');
    } else if (response.status === 429) {
      throw new Error('Extended limit');
    } else {
      throw new Error(body.message || 'Backend connection failed...');
    }
  }

  return body;
};
