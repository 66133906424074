import React from 'react';
import { createPortal } from 'react-dom';
import { cn } from 'src/common/utils';

interface ModalProps {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  backgroundImage?: string | null;
}

export const PreviewModal: React.FC<ModalProps> = ({ open, children, onClose, backgroundImage }) => {
  if (!open) {
    return null;
  }

  const handleOnClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    e.defaultPrevented;
    onClose();
  };

  return createPortal(
    <div
      className={cn(
        'top-0 left-0 z-50 fixed w-[100vw] h-[100vh] bg-black/80 flex justify-center items-center',
        { 'bg-white': !!backgroundImage },
      )}
      onClick={handleOnClose}
    >
      {backgroundImage ? (
        <div
          onClick={handleOnClose}
          className='absolute w-full h-full bg-no-repeat bg-cover'
          style={{
            backgroundImage: `url(${backgroundImage})`,
            filter: 'blur(50px)',
            opacity: 0.5,
          }}
        ></div>
      ) : null}
      <div className='w-fit sm:h-fit'>{children}</div>
    </div>,
    document.body,
  );
};
