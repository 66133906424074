import React from 'react';
import ReactMarkdown from 'react-markdown';
import type { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { cn } from 'src/common/utils';

import './markdown.css';

const Markdown = ({ children, className, ...props }: ReactMarkdownOptions) => {
  return (
    <ReactMarkdown
      {...props}
      className={cn('markdown', className)}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
