import React, { useEffect, useState } from 'react';
import { Trail } from 'src/ui/Trail/Trail';
import { OnboardingStepsEnum, steps } from './Onboarding.constants';
import { Switch } from 'src/ui/Switch/Switch';
import { FaArrowRight, FaRobot } from 'react-icons/fa6';
import { Button } from 'src/ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { AboutYouLeft, AboutYouRight } from './Subpages';
import { Branding } from './Subpages/Branding.subpage';
import { ConnectionLeft, ConnectionRight } from './Subpages/Connection/Connection.subpage';
import { PaymentsLeft } from './Subpages/Payments/Payments.subpage';
import { SubscriptionsLeft, SubscriptionsPreview } from './Subpages/Subscriptions.subpage';
import { OfferLeft, OfferPreview } from './Subpages/Offer.subpage';
import { CongratulationsLeft } from './Subpages/Congratulations.subpage';
import { AiHelperChat } from './Subpages/AiHelper/AiHelperChat.subpage';
import { updateUser } from 'src/store/user/user.actions';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/router/routes';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { OnboardingProvider, useOnboardingContext } from 'src/context/Onboarding.context';
import { selectUser } from 'src/store/user/user.slice';
import SEO from 'src/components/Seo';
import { cn } from 'src/common/utils';
import { useOnboardingStatus, useSearchParams } from 'src/common/hooks';
import { OnboardingSteps } from 'src/common/enums/onboardingSteps.enum';
import { setStep } from 'src/store/onboarding/onboarding.slice';
import { FaSpinner } from 'react-icons/fa';
import { useTutorialNavigation } from 'src/common/hooks/useTutorialNavigations';

const stepComponents = [
  {
    left: <AboutYouLeft />,
    right: <AboutYouRight />,
  },
  {
    left: <Branding />,
    right: <AboutYouRight />,
  },
  {
    left: <SubscriptionsLeft />,
    right: <SubscriptionsPreview />,
  },
  {
    left: <PaymentsLeft />,
    right: <OfferPreview />,
  },
  {
    left: <ConnectionLeft />,
    right: <ConnectionRight />,
  },
  {
    left: <OfferLeft />,
    right: <OfferPreview showPdf />,
  },
  {
    left: <CongratulationsLeft />,
    right: <AboutYouRight withCreds />,
  },
];

export const OnboardingPage: React.FC = () => {
  const { t } = useTranslation();
  const [isAiActive, setIsAiActive] = useState<boolean>(false);
  const { step, lastStep, previewMode } = useSelector((state: AppStateType) => state.onboarding);
  const { user } = useSelector((state: AppStateType) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchParams } = useSearchParams();
  const { lastEmptyStep, subscriptions } = useOnboardingStatus();
  const [isLoaded, setIsLoaded] = useState(false);

  const { clearTutorialInformation } = useTutorialNavigation();

  const [currentPages, setCurrentPages] = useState<{
    left: JSX.Element;
    right: JSX.Element;
  }>(stepComponents[step]);

  useEffect(() => {
    if (subscriptions.length && user && !isLoaded) {
      dispatch(setStep(lastEmptyStep));
      setIsLoaded(true);
    }
  }, [user, lastEmptyStep, subscriptions, isLoaded]);

  useEffect(() => {
    setCurrentPages(stepComponents[step]);
    if (step === OnboardingSteps.CONGRATULATIONS) {
      setIsAiActive(false);
    }
  }, [step]);

  useEffect(() => {
    if (previewMode) {
      window.scrollTo(0, 0);
    }
  }, [previewMode]);

  const handleSkipWizzard = async () => {
    await dispatch(
      updateUser({
        userData: {
          id: user?.id,
          hadUserOnboarding: true,
        },
      }),
    );
    navigate(`/${ROUTES.CHATS}`);
  };

  const isTutorialPage = step === OnboardingStepsEnum.Channels && searchParams.get('channel') && searchParams.get('channel') !== 'telegram';
  const shouldDisplayBackground =
    step < OnboardingStepsEnum.Subscriptions || step === OnboardingSteps.CONGRATULATIONS;

  return isLoaded ? (
    <OnboardingProvider>
      <SEO
        title={'Onboarding'}
        description={'Expert onboarding page.'}
        name={'Anamata'}
        type={'onboarding'}
      />
      <div className='flex bg-secondary min-h-[100vh] pt-safe relative'>
        <div
          className={
            'w-[100%] xl:w-[50%] pt-[15px] px-[15px] pb-[46px] md:px-[20px] md:pb-[60px] bg-white'
          }
        >
          {step !== lastStep ? (
            <>
              <div className='flex items-baseline flex-row md:flex-col md:items-start gap-[16px] items-start'>
                <div className='grow text-[14px] font-bold leading-[22px] text-grey-900 min-w-fit'>
                  Anamata setup
                </div>
                <div className='shrink overflow-x-scroll no-scrollbar'>
                  <Trail activeStep={step} steps={steps} />
                </div>
              </div>
              <div className='flex flex-col md:justify-center justify-between gap-[0px] h-full'>
                <section
                  className={cn('md:px-[15px] flex flex-col items-center pt-[30px]', {
                    'p-0 md:px-0 mt-[37px]': isAiActive,
                  })}
                >
                  <div className='flex items-center justify-center gap-[8px]'>
                    <Switch
                      className={cn({
                        'md:mb-[11px]': isAiActive,
                      })}
                      isChecked={isAiActive}
                      onChange={e => setIsAiActive(e.target.checked)}
                    />
                    <div className='flex gap-[8px] items-center'>
                      <Trans i18nKey={'onboarding.common.ai'}>
                        Use <FaRobot className='w-[16px] h-[16px] text-info' /> AI helper
                      </Trans>
                    </div>
                  </div>
                  {!isAiActive ? (
                    <div className='mt-[16px]'>{currentPages.left}</div>
                  ) : (
                    <AiHelperChat
                      handleSkipWizzard={handleSkipWizzard}
                      setIsAiActive={setIsAiActive}
                    />
                  )}
                </section>
                {!isAiActive ? (
                  <div className={cn('md:mt-[44px] mt-[22px] mb-[28px] flex justify-center')}>
                    <Button variant='secondary' onClick={handleSkipWizzard} className='h-fit p-0'>
                      {t('onboarding.common.skipWizard')}
                      <FaArrowRight />
                    </Button>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <div className='mt-[16px] h-full'>{currentPages.left}</div>
          )}
          {previewMode && (
            <div className={cn('w-[100vw] absolute top-0 left-0 h-full bg-white py-[15px] z-10')}>
              <div
                className='absolute w-full h-full top-0 bg-cover bg-no-repeat'
                style={{
                  backgroundImage: user?.expert?.brandLogoUrl ? `url(${user.expert.brandLogoUrl})` : '',
                  filter: 'blur(50px)',
                  opacity: 0.5,
                }}
              ></div>
              <div className='w-fit m-auto overflow-hidden'>{currentPages.right}</div>
            </div>
          )}
        </div>
        <div
          onWheel={step === OnboardingSteps.CHANNELS ? () => clearTutorialInformation() : undefined}
          className={cn(
            `
            hidden xl:sticky
            bg-light
            w-[50%]
            inset-y-0
            h-screen
            overflow-auto
            mx-auto
            xl:flex xl:flex-col xl:items-center xl:justify-center text-center`,
            {
              // make it scrollable block on steps with tutorials
              'xl:block': isTutorialPage,
            },
          )}
        >
          {!isTutorialPage && shouldDisplayBackground ? <BackgroundImage /> : null}
          {currentPages.right}
        </div>
      </div>
    </OnboardingProvider>
  ) : (
    <div className='w-[100vw] h-[100vh] flex flex-col justify-center items-center'>
      <div className='m-auto'>
        <FaSpinner className='text-primary animate-spin w-[50px] h-[50px]' />
      </div>
    </div>
  );
};

export const BackgroundImage = () => {
  const { profilePreview } = useOnboardingContext();

  const user = useSelector(selectUser);

  const usedLogo = user?.expert?.brandLogoUrl || profilePreview?.brandLogoUrl;

  return (
    <div
      className='absolute w-full h-full bg-no-repeat bg-cover'
      style={{ backgroundImage: `url(${usedLogo})`, filter: 'blur(50px)', opacity: 0.5 }}
    ></div>
  );
};
