import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { ROUTES } from 'src/router/routes';
import { useSearchParams } from './useSearchParams';
import { useScreenDetect } from './useScreenDetect';

export const useTutorialNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setNewSearchParams } = useSearchParams();
  const { isTargetScreen: isTablet } = useScreenDetect('laptop-xs');

  const showTutorialInformation = useCallback(
    (tutorialPage: string, section: number | string) => {
      if (isTablet) {
        navigate({
          pathname: `/${ROUTES.ONBOARDING}/${ROUTES.TUTORIAL}/${tutorialPage}`,
          search: `?section=${section}`,
        });
      } else {
        const currentSearchParams = new URLSearchParams(location.search);
        currentSearchParams.set('section', `${section}`);

        navigate({
          pathname: `${location.pathname}`,
          search: `?${currentSearchParams.toString()}`,
        });
      }
    },
    [setNewSearchParams, navigate, isTablet],
  );

  const clearTutorialInformation = useCallback(() => {
    const currentSearchParams = new URLSearchParams(location.search);
    const section = currentSearchParams.get('section');
    if (section && !isTablet) {
      currentSearchParams.delete('section');
      navigate({
        pathname: location.pathname,
        search: `?${currentSearchParams.toString()}`,
      });
    }
  }, [setNewSearchParams, navigate, isTablet]);

  return {
    showTutorialInformation,
    clearTutorialInformation,
  };
};