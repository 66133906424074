import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/ui/Button/Button';
import { Card } from 'src/ui/Card/Card';
import { LuArrowDownToLine } from 'react-icons/lu';
import { useInstallPromptStore } from 'src/common/hooks/useInstallPrompt';
import { showIosInstallModal } from 'src/common/utils/showIosInstallModal';
import { useToast } from 'src/ui/Toast/use-toast';

const InstallPWACard = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { promptEvent } = useInstallPromptStore();

  const showIosInstall = showIosInstallModal();

  if (!promptEvent && !showIosInstall) return null;

  return (
    <Card className='rounded-[12px]'>
      <label className='text-[#181C32] font-semibold'>{t('profileSettings.pwa.title')}</label>
      <Button
        variant={'primary'}
        className='font-semibold mt-2 w-full'
        onClick={() => {
          if (showIosInstall) {
            toast({
              title: t('profileSettings.pwa.toast.title'),
              description: t('profileSettings.pwa.toast.description'),
            });
          } else if (promptEvent !== null) {
            promptEvent.prompt();
          }
        }}
      >
        <LuArrowDownToLine className='w-5 h-5' />
        {t('profileSettings.pwa.button')}
      </Button>
    </Card>
  );
};

export { InstallPWACard };
