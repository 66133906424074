import React, { useMemo, useRef } from 'react';
import { CommunicationChannelsHeader, CommunicationChannelsWrapper } from '../CommunicationChannelsWrappers';
import { MessengerType } from 'src/common/interfaces';
import { WhatsappTutorial } from '../../Onboarding/Subpages/Connection/Tutorials/WhatsappTutorial';
import { useScreenDetect, useSearchParams } from 'src/common/hooks';
import { Button } from 'src/ui/Button/Button';
import { cn } from 'src/common/utils/cn';
import { FaChevronLeft, FaSpinner } from 'react-icons/fa6';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  WhatsappSettingsInputs,
  whatsappSettingsSchema,
} from 'src/common/utils/schema/channels-settings.schema';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, updateUserLocaly } from 'src/store/user/user.slice';
import { useTranslation } from 'react-i18next';
import { WhatsappForm } from './WhatsappForm';
import { setWhatsappSettings } from 'src/common/api/channels-settings';
import { useMutation } from '@tanstack/react-query';
import { catchError } from 'src/common/utils/catchError';

export default function WhatsappSettingsPage() {
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const form = useForm<WhatsappSettingsInputs>({
    resolver: zodResolver(whatsappSettingsSchema),
  });

  const { mutate: saveWhatsappSettings, isLoading: isSaving } = useMutation({
    mutationFn: async (whatsappSettings: WhatsappSettingsInputs) => {
      return setWhatsappSettings(whatsappSettings);
    },
    onSuccess: data => {
      dispatch(updateUserLocaly({ expert: { settings: { whatsappSettings: data } } }));
    },
    onError: e => {
      catchError(e);
    },
  });

  const resetForm = () => {
    form.reset({
      phone: user?.expert?.settings?.whatsappSettings?.phone || '',
      phoneNumberId: user?.expert?.settings?.whatsappSettings?.phoneNumberId || '',
      accessToken: user?.expert?.settings?.whatsappSettings?.accessToken || '',
    });
  };

  const onSubmit = (vals: WhatsappSettingsInputs) => {
    saveWhatsappSettings(vals);
  };

  const isChanged = form.formState.isDirty;

  const watcher = useWatch({ control: form.control });

  const isFormDisabled = useMemo(() => {
    const { accessToken, phone, phoneNumberId } = watcher;
    return !accessToken || !phone || !phoneNumberId;
  }, [watcher]);

  return (
    <CommunicationChannelsWrapper
      title={t('communicationChannels.whatsapp.title')}
      isSaving={isSaving}
      isFormDisabled={isFormDisabled}
      messengerType={MessengerType.WHATSAPP}
      isChanged={isChanged}
      onSave={() => buttonRef.current?.click()}
      onDiscard={() => resetForm()}
      form={<WhatsappForm {...form} onSubmit={onSubmit} buttonRef={buttonRef} />}
      activeElements={
        <>
          <Button
            className='w-1/2 h-[40px]'
            variant='primary'
            onClick={() => buttonRef.current?.click()}
            disabled={!isChanged || isFormDisabled || isSaving}
          >
            {isSaving && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
          <Button
            variant='muted'
            className={cn('w-1/2 bg-secondary h-[40px]', { hidden: isFormDisabled || !isChanged })}
            onClick={resetForm}
          >
            {t('common.discard')}
          </Button>
        </>
      }
      tutorial={<WhatsappTutorial className='!px-0 py-0' />}
    />
  );
}
