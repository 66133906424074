import React, { useState, useRef, DragEvent, ChangeEvent } from 'react';
import { PdfIcon } from 'src/common/assets';
import { FaTrashAlt } from 'react-icons/fa';

export interface DragAndDropProps {
  file: File | null;
  onFileChange: (file: File | null) => void;
  children: React.ReactNode;
  validExtesnions: string[];
  setError: React.Dispatch<React.SetStateAction<string | null>>;
}

export const DragAndDrop: React.FC<DragAndDropProps> = ({
  file,
  onFileChange,
  children,
  validExtesnions,
  setError,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const MAX_SIZE = 5000000;

  const isValidExtension = (fileName: string) => {
    return new RegExp('(' + validExtesnions.join('|').replace(/\./g, '\\.') + ')$').test(fileName);
  };

  const isOptimalSize = (file: File) => file.size <= MAX_SIZE;

  const checkFile = (file: File) => {
    const hasValidExtension = isValidExtension(file.name);
    const hasOptimalSize = isOptimalSize(file);

    if (hasValidExtension && hasOptimalSize) {
      onFileChange(file);
      setError(null);
    } else {
      setError(
        !hasValidExtension
          ? `Uploaded file didn't have allowed extension(s): ${validExtesnions.join()}.`
          : 'File is too big (maximum 5MB)',
      );
    }
  };

  const handleDrag = (e: DragEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: ChangeEvent<any>) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      checkFile(e.target.files[0]);
    }
  };

  return (
    <form
      className={`
        px-[15px] py-[15px]
        rounded-md bg-light flex items-center justify-center
        ${!file ? 'min-h-[192px]' : 'h-fit'} cursor-pointer
      `}
      onDragEnter={handleDrag}
      onSubmit={e => e.preventDefault()}
    >
      {!file ? (
        <>
          <input
            id='input-file-upload'
            ref={inputRef}
            type='file'
            className='hidden'
            multiple
            onChange={handleChange}
            accept='application/pdf'
          />
          <label
            className='w-full cursor-pointer'
            htmlFor='input-file-upload'
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            {children}
          </label>
        </>
      ) : (
        <div className='flex justify-between items-center w-[100%] px-[15px] py-[10px] bg-white rounded-md'>
          <div className='flex items-center gap-[15px] grow overflow-x-hidden'>
            <div className='w-[30px] h-[30px]'>
              <PdfIcon />
            </div>
            <div className='min-w-0 mr-[10px] truncate'>{file.name}</div>
          </div>
          <div onClick={() => onFileChange(null)} className='ml-[15px]'>
            <FaTrashAlt className='text-grey-500 cursor-pointer' />
          </div>
        </div>
      )}
    </form>
  );
};
