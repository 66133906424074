import React from 'react';
import { Button, ButtonProps } from '../Button/Button';
import { FaRobot, FaSpinner } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

type ChatConnectAiButtonProps = ButtonProps & {
  isLoading?: boolean;
};

const ChatConnectAiButton = ({ isLoading, ...props }: ChatConnectAiButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button variant={'info-light'} {...props}>
      {isLoading ? <FaSpinner className='animate-spin w-6 h-6 text-info mr-2' /> : <FaRobot />}
      {t('chats.ai.connect')}
    </Button>
  );
};

type ChatControlAiButtonProps = ChatConnectAiButtonProps & {
  text: string;
};

const ChatControlAiButton = ({ isLoading, text, ...props }: ChatControlAiButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant={'info-light'}
      {...props}
      className='text-[14px] font-600 py-[12px] px-[23px] bg-white border-[#8950FC4D] border-[1px]'
    >
      {isLoading ? <FaSpinner className='animate-spin w-6 h-6 text-info mr-2' /> : null}
      {t(text)}
    </Button>
  );
};

export { ChatConnectAiButton, ChatControlAiButton };
