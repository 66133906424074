import React from 'react';
import { messengerButtonVariants } from '../MessengerButton/MessengerButton';
import { Button } from '../Button/Button';
import { CheckBox } from '../CheckBox/CheckBox';
import { messengerButtonIcons } from '../MessengerButton/MessengerButton.config';
import { VariantProps } from 'class-variance-authority';
import { cn } from 'src/common/utils';

export interface ConnectChannelButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof messengerButtonVariants> {
  isSelected: boolean;
  className?: string;
}

export const ConnectChannelButton: React.FC<ConnectChannelButtonProps> = ({
  app,
  isSelected,
  className,
  variant,
  onClick,
}) => {
  const appName = app ?? 'instagram';
  const Icon = messengerButtonIcons[appName];

  return (
    <Button
      onClick={onClick}
      className={cn(
        messengerButtonVariants({ variant, app, className }),
        'bg-transparent justify-between gap-0 px-[12px] py-[13px] h-[50px]',
        {
          'border-[1px] border-grey-300 hover:border-grey-400': !isSelected,
        },
      )}
    >
      <CheckBox
        color={app ?? 'instagram'}
        className={cn('disabled:opacity-100 disabled:cursor-pointer', { hidden: !isSelected })}
        disabled
        checked={isSelected}
      />
      <div
        className={cn('flex gap-[12px] items-center grow justify-center h-[24px]', {
          'pr-[24px]': isSelected,
        })}
      >
        <Icon className={`w-[21px] h-[21px]`} style={{ color: 'currentColor' }} />
        <div className='text-[14px] font-semibold'>
          {appName.charAt(0).toUpperCase() + appName.slice(1)}
        </div>
      </div>
    </Button>
  );
};
