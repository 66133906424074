import React from 'react';
import { cn } from 'src/common/utils';
import { useOnboardingStatus } from 'src/common/hooks';
import { ChatCard } from './ChatCard';
import { TransactionsCard } from './TransactionsCard';
import { AffiliateCard } from './AfilliateCard';
import { BusinessCard } from './BusinessCard';
import { PageWrapper } from '../Common/Page.wrapper';
import { selectUser } from 'src/store/user/user.slice';
import { useSelector } from 'react-redux';
import {
  BusinessCardEmpty,
  AffiliateCardEmpty,
  ChatsCardEmpty,
  TransactionsCardEmpty,
} from './DashboardCard.empty';
import { OnboardingMenuContent } from 'src/components/OnboardingMenu';
import { Card } from 'src/ui/Card/Card';

export const DashboardPage = () => {
  const { t, isCompleted, handleBackToOnboarding } = useOnboardingStatus();
  const user = useSelector(selectUser);

  return (
    <PageWrapper headerText={t('dashboard.title')} user={user} isMainPage isChanged={false}>
      <section
        className={cn('-mt-[6px] md:mt-[4px]', {
          'min-h-[calc(100vh-80px)]': !isCompleted,
        })}
      >
        {isCompleted ? (
          <>
            <div className='grid lg:grid-cols-3 grid-cols-auto-fill w-full gap-[15px] md:gap-[30px]'>
              <ChatCard />
              <TransactionsCard />
              <AffiliateCard />
            </div>
            <div className='mt-[15px] mb-[115px] md:mt-[30px] lg:mb-0'>
              <BusinessCard />
            </div>
          </>
        ) : (
          <>
            <div className='grid lg:grid-cols-2 grid-cols-auto-fill w-full gap-[15px] md:gap-[30px] min-h-6/12'>
              <Card className='px-[20px] md:px-[30px] py-[22px] rounded-[12px]'>
                <OnboardingMenuContent className='text-grey-900 text-[18px] leading-[23px] -ml-[30px]' />
              </Card>
              <BusinessCardEmpty handleBackToOnboarding={handleBackToOnboarding} />
            </div>
            <div className='grid lg:grid-cols-3 grid-cols-auto-fill w-full gap-[15px] md:gap-[30px] min-h-6/12 mt-[15px] mb-[115px] md:mt-[30px] lg:mb-0'>
              <ChatsCardEmpty handleBackToOnboarding={handleBackToOnboarding} />
              <TransactionsCardEmpty handleBackToOnboarding={handleBackToOnboarding} />
              <AffiliateCardEmpty handleBackToOnboarding={handleBackToOnboarding} />
            </div>
          </>
        )}
      </section>
    </PageWrapper>
  );
};
