import { fetchBuilder } from 'src/services/basicFetch';
import { MethodEnum } from '../enums';

export interface AnalyticsStats {
  visits: {
    labels: string[];
    data: number[];
  };
  stats: {
    url: string;
    amount: number;
    percents: number;
  }[];
}

const getAnalyticsStats = (
  domainName?: string | null,
  date?: string | null,
): Promise<AnalyticsStats> => {
  return fetchBuilder(
    {},
    `analytics?domain=${domainName}${date ? `&date=${date}` : ''}`,
    MethodEnum.GET,
  );
};

export { getAnalyticsStats };
