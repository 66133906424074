import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Providers } from 'src/components/Providers';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'src/styles/css-ios-pwa-viewport.css';
import { extendWindowProps } from './common/utils/extendWindowProps';
import 'react-photo-view/dist/react-photo-view.css';

dayjs.extend(LocalizedFormat);

extendWindowProps();

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

reportWebVitals();
