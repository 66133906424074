import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'src/common/utils/cn';
import { useTranslation } from 'react-i18next';
import { TransactionStatusEnum } from 'src/common/interfaces';

export const cardVariants = cva(
    'px-[8px] py-[4px] text-[11px] font-semibold rounded-md w-fit',
    {
        variants: {
            variant: {
                [TransactionStatusEnum.PAID]: 'bg-success-light text-success',
                [TransactionStatusEnum.WAITING]: 'bg-warning-light text-warning',
                [TransactionStatusEnum.REJECTED]: 'bg-danger-light text-danger'
            },
        },
        defaultVariants: {
            variant: TransactionStatusEnum.PAID,
        },
    },
);

export interface PayStatusCardProps extends VariantProps<typeof cardVariants> {
    className?: string;
}

export const PayStatusCard: React.FC<PayStatusCardProps> = (
    {
        variant,
        className
    }
) => {
    const { t } = useTranslation();

    return (
        <div
            className={cn(
                cardVariants({
                    variant,
                }),
                className,
            )}
        >
            {t(`transactions.status.${variant?.toLowerCase()}`)}
        </div>
    );
}