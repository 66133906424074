import { fetchBuilder } from "src/services/basicFetch";
import { MethodEnum } from "../enums";
import { PaymentSettingsInterface } from "src/store/user/user.slice";

export type ReducedPaymentSettingsInterface = Omit<PaymentSettingsInterface, 'stripeId' | 'bankToken'>

export const getPaymentSetting = async (id: number): Promise<PaymentSettingsInterface> => {
    return fetchBuilder({}, `payment-settings/${id}`, MethodEnum.GET);
};

export const updatePaymentSettings = async (settings: ReducedPaymentSettingsInterface) => {
    return fetchBuilder({ ...settings }, `user/payment`, MethodEnum.PATCH);
}

export const setDefaultPayments = async () => {
  return fetchBuilder({}, `user/payment-common`, MethodEnum.PATCH);
};