import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TutorialHeading } from './Heading';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { cn } from 'src/common/utils';
import { useSearchParams } from 'src/common/hooks';
import { renderTutorialSections } from 'src/common/utils/renderTutorialSections';
import { MessengerType } from 'src/common/interfaces';
import { usePublicContentStore } from 'src/store/public-content/usePublicContentStore';
import { TutorialWrapper } from './TutorialWrapper';

const InstagramTutorial: React.FC<{ className?: string }> = ({ children, className }) => {
  const { searchParams } = useSearchParams();

  const { data } = usePublicContentStore();

  const sections = data?.tutorials?.find(t => t.name === MessengerType.INSTAGRAM)?.sections ?? [];

  const section = searchParams.get('section');

  useEffect(() => {
    const element = document.getElementById(
      `${MessengerType.INSTAGRAM}-section-${section}`,
    ) as HTMLDivElement | null;

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [section, searchParams]);

  return (
    <PhotoProvider>
      <TutorialWrapper>
        <TutorialHeading>{children}</TutorialHeading>
        {renderTutorialSections(sections, MessengerType.INSTAGRAM)}
      </TutorialWrapper>
    </PhotoProvider>
  );
};

export { InstagramTutorial };
