import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaRobot } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { updateExpertSettings } from 'src/common/api/expert-settings';
import { useScreenDetect } from 'src/common/hooks';
import { cn } from 'src/common/utils';
import { selectUser, updateUserLocaly } from 'src/store/user/user.slice';
import { Card } from 'src/ui/Card/Card';
import { Switch } from 'src/ui/Switch/Switch';
import { PageWrapper } from '../Common/Page.wrapper';

const AIHelperSettingsPage = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const { isTargetScreen } = useScreenDetect('mobile');

  const { t } = useTranslation();

  const useAiHelper = user?.expert?.settings?.useAiHelper || false;

  const { mutate } = useMutation({
    mutationFn: updateExpertSettings,
    onSuccess: data => {
      dispatch(
        updateUserLocaly({
          expert: { settings: { useAiHelper: data.useAiHelper } },
        }),
      );
    },
  });

  return (
    <PageWrapper headerText={t('aiHelperSettings.title')}>
      <div className='md:mt-[4px] mt-[15px] flex flex-col md:flex-row w-full max-w-[1480px]'>
        <Card className='w-full rounded-xl min-h-[60%] p-[20px] sm:p-[30px] sm:text-[16px] text-[14px]'>
          <div className={cn('flex gap-2 items-center', { 'justify-between': isTargetScreen })}>
            {!isTargetScreen && (
              <Switch
                isChecked={useAiHelper}
                onChange={() => mutate({ useAiHelper: !useAiHelper })}
                className='md:-ml-[8px]'
              />
            )}
            <span>
              <p className='flex gap-2 items-center flex-wrap'>
                <Trans
                  i18nKey={'aiHelperSettings.description'}
                  components={{ 1: <FaRobot className='w-4 h-4 text-info' /> }}
                />
              </p>
            </span>
            {isTargetScreen && (
              <Switch
                isChecked={useAiHelper}
                onChange={() => mutate({ useAiHelper: !useAiHelper })}
                className='-ml-4 self-end min-w-[40px] grow'
              />
            )}
          </div>
          <hr className='my-[20px] sm:my-[30px] text-grey-2' />
          <Trans
            className='text-[14px] sm:text-[16px]'
            i18nKey={'aiHelperSettings.text'}
            components={{
              1: <div />,
              2: <ul className='list-decimal space-y-4 my-4 ml-4'></ul>,
              3: <li></li>,
              4: <b></b>,
            }}
          />
        </Card>
      </div>
      {/* </main> */}
    </PageWrapper>
  );
};

export default AIHelperSettingsPage;
