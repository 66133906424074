import { isNil, omitBy } from 'lodash';
import React, { FC, createContext, useContext, useState } from 'react';
import { BusinessCardProps } from 'src/ui/ProfilePreview/ProfilePreview';

type OnboardingContextState = {
  profilePreview: BusinessCardProps | null;
  updateProfilePreview: (state: Partial<BusinessCardProps>) => void;
};

const intialState: OnboardingContextState = {
  profilePreview: null,
  updateProfilePreview: () => {},
};

const OnboardingContext = createContext<typeof intialState>(intialState);

export const useOnboardingContext = () => useContext(OnboardingContext);

export const OnboardingProvider: FC = ({ children }) => {
  const [profilePreview, setPreview] = useState<null | BusinessCardProps>(null);

  return (
    <OnboardingContext.Provider
      value={{
        profilePreview: profilePreview,
        updateProfilePreview: state => {
          setPreview(prev => {
            return omitBy(
              {
                ...prev,
                ...state,
              },
              isNil,
            );
          });
        },
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
