import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscriptions } from 'src/common/hooks';
import { cn } from 'src/common/utils/cn';
import { FieldError } from 'src/components/FieldError';
import { Button } from 'src/ui/Button/Button';
import { SubscriptionCard } from 'src/ui/SubscriptionCard/SubscriptionCard';
import { SubscriptionsPreview } from '../Onboarding/Subpages/Subscriptions.subpage';
import { useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { FaSpinner } from 'react-icons/fa';
import { PageWrapper } from '../Common/Page.wrapper';

export const SubscriptionsPage = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: AppStateType) => state.user);

  const {
    error,
    onDescriptionChange,
    onPriceChange,
    onQuestionsAmountChange,
    onSubmit,
    setIsCardActive,
    showError,
    subscriptions,
    isChanged,
    uploadSubscriptions,
    isLoading,
    isValid
  } = useSubscriptions(true);

  return (
    <PageWrapper
      headerText={t('businessSettings.subscriptions.header')}
      isChanged={isChanged}
      mobileActiveElements={
        <div className='flex gap-2 w-full'>
          <Button
            className='w-1/2 h-[40px]'
            variant='primary'
            disabled={!isChanged || !isValid}
            onClick={() => (!error ? onSubmit() : null)}
          >
            {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
          <Button
            variant={'muted'}
            className={cn('w-1/2 bg-secondary h-[40px]', { hidden: !isChanged })}
            onClick={() => user && uploadSubscriptions(user?.id)}
          >
            {t('common.discard')}
          </Button>
        </div>
      }
      activeElemets={
        <>
          <Button
            variant={'muted'}
            className={cn('max-w-[100px] bg-secondary h-[40px]', { hidden: !isChanged })}
            onClick={() => user && uploadSubscriptions(user?.id)}
          >
            {t('common.discard')}
          </Button>
          <Button
            className='h-[40px]'
            variant={'primary'}
            onClick={() => (!error ? onSubmit() : null)}
            disabled={!isChanged || !isValid}
          >
            {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className='md:mt-0 mt-[15px] flex flex-col md:flex-row gap-20'>
        <div className='w-full lg:w-6/12'>
          <h2
            className={cn(
              'flex flex-row items-center gap-[0] flex-wrap w-full mb-[24px] mt-0 text-center md:text-left',
            )}
          >
            {t('businessSettings.subscriptions.subheader')}
          </h2>
          <div className='flex flex-col gap-[16px]'>
            {subscriptions.length ? (
              subscriptions.map((p, idx) => (
                <SubscriptionCard
                  className='border-none bg-white'
                  key={idx}
                  headerText={t(p.title)}
                  subscription={p}
                  setIsCardActive={e => setIsCardActive(idx, e.target.checked)}
                  onDescriptionChange={e => onDescriptionChange(idx, e.target.value)}
                  onPriceChange={e => onPriceChange(idx, +e.target.value)}
                  onQuestionsAmountChange={e => onQuestionsAmountChange(idx, +e)}
                  options={p.options}
                />
              ))
            ) : (
              <></>
            )}
            {showError && error && <FieldError error={error} />}
          </div>
        </div>
        <div className='space-y-12 w-6/12 hidden lg:block'>
          <div className='m-auto w-fit mt-[10vh]'>
            <SubscriptionsPreview />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
