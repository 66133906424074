import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReducedPaymentSettingsInterface, getPaymentSetting } from 'src/common/api/payments';
import { AppStateType } from 'src/store';
import { PaymentSettingsInterface } from 'src/store/user/user.slice';
import { Button } from 'src/ui/Button/Button';
import { FaCheck } from 'react-icons/fa';
import { useAiAssistantStore } from 'src/store/onboarding/ai-assistant.store';
import { DropDown } from 'src/ui/DropDownRadix/DropDown';
import { usStates } from 'src/common/data';

interface AiPaymentStateProps {}

export const AiPaymentState: React.FC<AiPaymentStateProps> = () => {
  const [settings, setSettings] = useState<ReducedPaymentSettingsInterface>();
  const { user } = useSelector((state: AppStateType) => state.user);

  const { updatePaySettingsViaAi, step } = useAiAssistantStore();
  const { mutate: update } = updatePaySettingsViaAi;

  const {} = useQuery(
    [user?.id],
    (): Promise<PaymentSettingsInterface | null> | null => {
      if (!user?.id) return null;
      return getPaymentSetting(user.id);
    },
    {
      onSuccess: data => (data !== null ? setSettings(data) : null),
    },
  );

  const onChange = (value: string) => {
    setSettings(prevState => {
      if (prevState) {
        return {
          ...prevState,
          state: value,
        };
      }
    });
  };

  const onClick = () => {
    if (settings) {
      update({
        paymentSettings: settings,
        message: '',
        step,
      });
    }
  };

  const state = useMemo(() => settings?.state || undefined, [settings]);

  return settings ? (
    <div className='flex w-full justify-center mt-[16px]'>
      <div className='flex gap-[16px]'>
        <DropDown
          options={usStates}
          onValueChange={onChange}
          value={state}
          name='state'
          placeholder='State'
        />
        <Button variant='primary' onClick={onClick}>
          <FaCheck />
        </Button>
      </div>
    </div>
  ) : null;
};
