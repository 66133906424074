import React from 'react';
import { cn } from 'src/common/utils';
import { Card } from 'src/ui/Card/Card';
export interface FormProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

export const Form: React.FC<FormProps> = ({ title, children, className }) => {
  return (
    <Card
      variant='default'
      className={cn('rounded-[12px] px-[42px] py-[50px] w-full max-w-[385px] mx-auto', className)}
    >
      <h1 className='text-center header-1'>{title}</h1>
      {children}
    </Card>
  );
};
