import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TutorialHeading } from './Heading';
import { Trans } from 'react-i18next';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { cn } from 'src/common/utils';
import { useSearchParams } from 'src/common/hooks/useSearchParams';
import { renderTutorialSections } from 'src/common/utils/renderTutorialSections';
import { MessengerType } from 'src/common/interfaces';
import { usePublicContentStore } from 'src/store/public-content/usePublicContentStore';
import { TutorialWrapper } from './TutorialWrapper';

const MessengerTutorial: React.FC<{ className?: string }> = ({ children, className }) => {
  const { searchParams } = useSearchParams();
  const { data } = usePublicContentStore();

  const section = searchParams.get('section');

  const sections = data?.tutorials?.find(t => t.name === MessengerType.MESSENGER)?.sections ?? [];

  useEffect(() => {
    const element = document.getElementById(
      `${MessengerType.MESSENGER}-section-${section}`,
    ) as HTMLDivElement | null;

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [section]);

  return (
    <PhotoProvider>
      <TutorialWrapper>
        <TutorialHeading>{children}</TutorialHeading>
        {renderTutorialSections(sections, MessengerType.MESSENGER)}
      </TutorialWrapper>
    </PhotoProvider>
  );
};

export { MessengerTutorial };
