import React from 'react';
import { UploadedFile } from 'src/common/interfaces';
import { FaFile } from 'react-icons/fa';
import { getFileNameAndExtension } from 'src/common/utils/getFileNameAndExtension';

interface FilePlaceholderProps {
  file: UploadedFile;
}

export const FilePlaceholder = ({ file }: FilePlaceholderProps) => {
  return (
    <a
      className='flex items-center gap-2 cursor-pointer'
      download={file.src}
      key={file.src}
      role='link'
      target='_blank'
      rel='nofollow noreferrer'
      href={file.src}
    >
      {file.src ? <FaFile className='w-4 g-4' /> : null}
      <div className='flex flex-col'>
        <p className='line-clamp-1 text-sm font-medium text-muted-foreground'>
          {file.src ? getFileNameAndExtension(file.src) ?? 'file' : 'Unsupported filetype'}
        </p>
      </div>
    </a>
  );
};
