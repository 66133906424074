import React from 'react';

export const PdfIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 55 55'
    fill='none'
  >
    <path
      d='M13.75 0C11.8594 0 10.3125 1.54688 10.3125 3.4375V51.5625C10.3125 53.4531 11.8594 55 13.75 55H48.125C50.0156 55 51.5625 53.4531 51.5625 51.5625V13.75L37.8125 0H13.75Z'
      fill='#E2E5E7'
    />
    <path
      d='M41.25 13.75H51.5625L37.8125 0V10.3125C37.8125 12.2031 39.3594 13.75 41.25 13.75Z'
      fill='#B0B7BD'
    />
    <path d='M51.5625 24.0625L41.25 13.75H51.5625V24.0625Z' fill='#CAD1D8' />
    <path
      d='M44.6875 44.6875C44.6875 45.6328 43.9141 46.4062 42.9688 46.4062H5.15625C4.21094 46.4062 3.4375 45.6328 3.4375 44.6875V27.5C3.4375 26.5547 4.21094 25.7812 5.15625 25.7812H42.9688C43.9141 25.7812 44.6875 26.5547 44.6875 27.5V44.6875Z'
      fill='#F15642'
    />
    <path
      d='M10.9297 32.5651C10.9297 32.1114 11.2872 31.6164 11.863 31.6164H15.0375C16.825 31.6164 18.4337 32.8126 18.4337 35.1054C18.4337 37.2779 16.825 38.488 15.0375 38.488H12.743V40.303C12.743 40.908 12.358 41.25 11.863 41.25C11.4092 41.25 10.9297 40.908 10.9297 40.303V32.5651ZM12.743 33.3472V36.7709H15.0375C15.9587 36.7709 16.6875 35.9579 16.6875 35.1054C16.6875 34.1447 15.9587 33.3472 15.0375 33.3472H12.743Z'
      fill='white'
    />
    <path
      d='M21.1255 41.2499C20.6718 41.2499 20.1768 41.0024 20.1768 40.3992V32.5926C20.1768 32.0993 20.6718 31.7401 21.1255 31.7401H24.2725C30.5528 31.7401 30.4153 41.2499 24.3963 41.2499H21.1255ZM21.9918 33.4176V39.5742H24.2725C27.9833 39.5742 28.1483 33.4176 24.2725 33.4176H21.9918Z'
      fill='white'
    />
    <path
      d='M32.6425 33.5276V35.7121H36.1471C36.6421 35.7121 37.1371 36.2071 37.1371 36.6867C37.1371 37.1404 36.6421 37.5117 36.1471 37.5117H32.6425V40.3975C32.6425 40.8787 32.3005 41.2482 31.8193 41.2482C31.2143 41.2482 30.8447 40.8787 30.8447 40.3975V32.5909C30.8447 32.0976 31.216 31.7384 31.8193 31.7384H36.6438C37.2488 31.7384 37.6063 32.0976 37.6063 32.5909C37.6063 33.0309 37.2488 33.5259 36.6438 33.5259H32.6425V33.5276Z'
      fill='white'
    />
    <path
      d='M42.9688 46.4062H10.3125V48.125H42.9688C43.9141 48.125 44.6875 47.3516 44.6875 46.4062V44.6875C44.6875 45.6328 43.9141 46.4062 42.9688 46.4062Z'
      fill='#CAD1D8'
    />
  </svg>
);

export const Logo = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='full' height='full' viewBox='0 0 102 60' fill='none'>
    <path
      d='M25.384 54.554V47.376H27.9022V60L19.2914 52.6554V59.7835H16.7731V47.1428L25.384 54.554ZM41.1296 58.118L41.9257 59.7835H44.6714L38.2701 47.1595L31.8851 59.7835H34.4846L35.2644 58.118H41.1296ZM36.353 55.7864L38.1726 51.9226L40.0248 55.7864H36.353ZM55.1083 52.1724L61.5583 47.1595V59.7835H59.04V52.3056L55.1083 55.2202L51.1765 52.3056V59.7835H48.6582V47.1595L55.1083 52.1724ZM74.7759 58.118L75.5721 59.7835H78.3176L71.9164 47.1595L65.5314 59.7835H68.1309L68.9108 58.118H74.7759ZM69.9993 55.7864L71.8186 51.9226L73.6709 55.7864H69.9993ZM99.2545 59.7835L98.4583 58.118H92.5933L91.8135 59.7835H89.2139L95.5988 47.1595L102 59.7835H99.2545ZM95.501 51.9226L93.6813 55.7864H97.3533L95.501 51.9226ZM84.8413 59.7835H82.3232V49.7076H79.3333V47.376H88.026V49.7076H84.8413V59.7835ZM9.24446 58.118L10.0406 59.7835H12.7863L6.38502 47.1595L0 59.7835H2.5995L3.37935 58.118H9.24446ZM4.46789 55.7864L6.28754 51.9226L8.13967 55.7864H4.46789Z'
      fill='#2D2C40'
    />
    <path d='M65.1663 0H53.833L76.4997 38.5714H87.833L65.1663 0Z' fill='#5212DB' />
    <path
      d='M53.833 28.9286L59.4997 38.5715H70.833L59.4997 19.2858L53.833 28.9286Z'
      fill='#9D1DC7'
    />
    <path
      d='M31.7966 8.57141L14.167 38.5714H25.5003L43.13 8.57141H31.7966Z'
      fill='#E890E8'
    />
    <path d='M53.8337 0H65.167L42.5003 38.5714H31.167L53.8337 0Z' fill='#68AEFF' />
  </svg>
);
