import React, { useState, ChangeEvent } from 'react';
import { IconType } from 'react-icons';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cn } from 'src/common/utils';

interface MenuBarItemProps {
  text?: string;
  counter?: number;
  icon: IconType;
  route: string;
  component?: React.ReactNode;
}

const MenuBarItem = ({
  text,
  icon: Icon,
  counter,
  component: Component,
  route,
}: MenuBarItemProps) => {
  const location = useLocation();

  const isActive = location.pathname.startsWith(`/${route}`);

  if (Component) return <>{Component}</>;

  return (
    <Link to={`/${route}`}>
      <div
        className={cn(
          `flex flex-col items-center text-grey-500 gap-[2px] hover:font-semibold hover:cursor-pointer hover:text-blue`,
          { 'text-blue': isActive },
        )}
      >
        <div className='relative'>
          {counter ? (
            <div className='absolute top-[-7px] right-[-7px] w-[22px] h-[22px] flex items-center justify-center font-semibold bg-[#F64E60] text-[16px] leading-[18px] rounded-full text-center text-white hover:text-white hover:font-thin z-10'>
              {counter > 9 ? '9+' : counter}
            </div>
          ) : null}
          <Icon className='relative w-8 h-8' />
        </div>
        {text ? <div className='text-center font-open-sans text-xs leading-4'>{text}</div> : null}
      </div>
    </Link>
  );
};

export default MenuBarItem;
