import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useActiveTabDetect } from 'src/common/hooks/useActiveTabDetect';
import { create } from 'zustand';

type NotificationsStore = {
  staleNotifications: Notification[];
  addNotification: (notification: Notification) => void;
  setNotifications: (notifications: Notification[]) => void;
};

const notificationsStore = create<NotificationsStore>(set => ({
  staleNotifications: [],
  addNotification: noti =>
    set(state => ({ staleNotifications: [...state.staleNotifications, noti] })),
  setNotifications: notifications => set(state => ({ staleNotifications: notifications })),
}));

const useBrowserNotificationsStore = () => {
  const { staleNotifications, addNotification, setNotifications } = notificationsStore();
  const { isTabActive } = useActiveTabDetect();

  const { data: isGranted, refetch } = useQuery(['notifications'], () => {
    if (!('Notification' in window)) {
      return false;
    }

    return Notification.permission === 'granted';
  });

  const { mutate: requestPermission } = useMutation({
    mutationFn: () => Notification.requestPermission(),
    onSuccess: data => {
      refetch();
    },
  });

  const spawnNotification = (title: string, options?: NotificationOptions) => {
    const notification = new Notification(title, options);
    addNotification(notification);
  };

  const resetNotifications = useCallback(() => {
    staleNotifications.forEach(n => n.close());
    setNotifications([]);
  }, [staleNotifications]);

  useEffect(() => {
    if (isTabActive) resetNotifications();
  }, [isTabActive]);

  return {
    isGranted,
    requestPermission,
    spawnNotification,
    staleNotifications,
    addNotification,
    isTabActive,
  };
};

export { useBrowserNotificationsStore };
