import React, { useEffect, useRef } from 'react';

export interface TextBarProps {
  children: React.ReactNode
}

export const TextBar: React.FC<TextBarProps> = ({ children }) => {
  return <div className='pt-[16px] px-[16px] bg-light rounded-md max-h-[440px] overflow-y-scroll text-left secondary scrollbar'>
    {children}
  </div>
};
