import React, { ReactNode } from 'react';
import { cn } from 'src/common/utils/cn';

interface Tab {
  label: string;
  value: string | number;
  className?: string;
  content?: ReactNode;
  count?: number;
  disabled?: boolean;
}

export interface TabSwitcherProps {
  tabs: Tab[];
  className?: string;
  value: string | number;
  onChange: (value: string | number) => void;
  commonBorder?: boolean;
}

const TabSwitcher: React.FC<TabSwitcherProps> = ({
  tabs,
  value,
  onChange,
  className,
  commonBorder,
}) => {
  const currentTab = tabs.find(t => t.value === value);

  return (
    <div className={cn('flex flex-col', className)}>
      <div className='w-full flex justify-between px-2 sm:px-[15px]'>
        {tabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => (!tab.disabled ? onChange(tab.value) : {})}
            className={cn(
              'font-normal pb-[19px] flex items-center gap-[0] grow justify-center text-blue border-b-[2px] border-blue cursor-pointer',
              tab.className,
              {
                'text-grey-500': tab.value !== value,
                'cursor-default text-[#dfdcdc]': tab.disabled,
                'border-none': tab.value !== value && !commonBorder,
                'border-b-[1px] border-[#dfdcdc]': commonBorder && tab.value !== value,
              },
            )}
          >
            {tab.label}
            {!!tab.count && (
              <div className='ml-[5px] text-xs bg-blue rounded-full  text-white min-w-[18px] min-h-[18px] flex items-center justify-center font-semibold'>
                {tab.count > 9 ? '9+' : tab.count}
              </div>
            )}
          </div>
        ))}
      </div>
      {currentTab?.content && <div>{currentTab.content}</div>}
    </div>
  );
};

export default TabSwitcher;
