import { fetchBuilder } from 'src/services/basicFetch';
import { MethodEnum } from '../enums';
import { ExpertiseInterface } from 'src/store/onboarding/onboarding.slice';

const getExpertise = async (): Promise<ExpertiseInterface[] | null> => {
  try {
    const data = await fetchBuilder({}, `expertise`, MethodEnum.GET);
    return data;
  } catch (err) {
    return null;
  }
};

export { getExpertise };
