import React, { useEffect } from 'react';
import { TutorialHeading } from './Heading';
import { PhotoProvider } from 'react-photo-view';
import { useSearchParams } from 'src/common/hooks';
import { renderTutorialSections } from 'src/common/utils/renderTutorialSections';
import { MessengerType } from 'src/common/interfaces';
import { usePublicContentStore } from 'src/store/public-content/usePublicContentStore';
import { TutorialWrapper } from './TutorialWrapper';

const WhatsappTutorial: React.FC<{ className?: string }> = ({ children, className }) => {
  const { searchParams } = useSearchParams();
  const { data } = usePublicContentStore();

  const section = searchParams.get('section');

  const sections = data?.tutorials?.find(t => t.name === MessengerType.WHATSAPP)?.sections ?? [];

  useEffect(() => {
    const element = document.getElementById(
      `${MessengerType.WHATSAPP}-section-${section}`,
    ) as HTMLDivElement | null;

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [section]);

  return (
    <PhotoProvider>
      <TutorialWrapper>
        <TutorialHeading>{children}</TutorialHeading>
        {sections.length && renderTutorialSections(sections, MessengerType.WHATSAPP)}
      </TutorialWrapper>
    </PhotoProvider>
  );
};

export { WhatsappTutorial };
