import { fetchBuilder } from 'src/services/basicFetch';
import { MethodEnum, StorageKeysEnum } from '../enums';
import { MessageInterface } from 'src/store/onboarding/onboarding.slice';
import {
  ExpertSubscription,
  PaymentSettingsInterface,
  UserInterface,
} from 'src/store/user/user.slice';
import { RecursivePartial } from '../interfaces';

interface AiAssistantResponse {
  user: UserInterface;
  subscriptions?: ExpertSubscription[];
  messages: MessageInterface[];
  step: number;
}

export const getHistory = async (): Promise<MessageInterface[]> => {
  return fetchBuilder({}, 'assistant/chat-history', MethodEnum.GET);
};

export const askAi = async (data: {
  step: number;
  message: string;
}): Promise<AiAssistantResponse> => {
  return fetchBuilder({ ...data, role: 'user' }, 'assistant/ask', MethodEnum.POST);
};

export const rerunStepAi = async (data: { step: number }): Promise<AiAssistantResponse> => {
  return fetchBuilder({ ...data, role: 'user' }, `assistant/rerun/${data.step}`, MethodEnum.PATCH);
};

export const uploadAiFiles = async (
  toUpload: {
    file: File | null | 'DELETED';
    key: string;
  }[],
  link: string,
  mode: RequestMode = 'cors',
) => {
  const formData = new FormData();
  for (const file of toUpload) {
    formData.append(file.key, file.file === null ? 'null' : file.file);
  }

  const access_token = localStorage.getItem(StorageKeysEnum.TOKEN);
  const apiUrl =
    process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : '/api';

  const response = await fetch(`${apiUrl}/${link}`, {
    method: MethodEnum.PATCH,
    headers: {
      ...(access_token && { Authorization: `Bearer ${access_token}` }),
    },
    mode,
    body: formData,
  });

  try {
    return await response.json();
  } catch (err) {
    console.log(err, response.url);
  }
};

export const updateAvatarsViaAi = async (
  toUpload: {
    file: File | null | 'DELETED';
    key: string;
  }[],
): Promise<AiAssistantResponse> => {
  return await uploadAiFiles(toUpload, 'assistant/avatars');
};

export const updateExpertise = async (data: {
  user: Partial<UserInterface>;
  step: number;
}): Promise<AiAssistantResponse> => {
  return await fetchBuilder({ ...data }, 'assistant/expertise', MethodEnum.PATCH);
};

export const updateThematicTemplateViaAssistant = async (data: {
  user: RecursivePartial<UserInterface>;
  step: number;
}): Promise<AiAssistantResponse> => {
  return await fetchBuilder({ ...data }, 'assistant/thematic-template', MethodEnum.PATCH);
};

export const updateSubscriptionsAi = async (
  data: ExpertSubscription[],
): Promise<AiAssistantResponse> => {
  return await fetchBuilder({ data }, 'assistant/activate-subscriptions', MethodEnum.PATCH);
};

export const updatePaymentSettingsAi = async (data: {
  paymentSettings: Partial<PaymentSettingsInterface>;
  step: number;
  message: string;
}): Promise<AiAssistantResponse> => {
  return await fetchBuilder({ ...data }, 'assistant/payment-settings', MethodEnum.PATCH);
};

export const connectStripeAi = async (): Promise<AiAssistantResponse> => {
  return await fetchBuilder({ ...{} }, 'assistant/stripe', MethodEnum.POST);
};

export const updatePaymentSettingsAiMessage = async (data: {
  message: string;
  step: number;
  key: string;
}): Promise<AiAssistantResponse> => {
  return await fetchBuilder({ ...data }, 'assistant/payment-settings-text', MethodEnum.PATCH);
};
