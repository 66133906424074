import { useEffect, useState } from 'react';

const useActiveTabDetect = () => {
  const [isTabActive, setIsTabActive] = useState(document.visibilityState === 'visible');

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsTabActive(true);
      } else {
        setIsTabActive(false);
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return { isTabActive };
};

export { useActiveTabDetect };
