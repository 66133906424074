import React from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { useLogout } from 'src/common/hooks/useLogout';

type LogoutButtonProps = {
  mirror?: boolean;
};

const LogoutButton = ({ mirror }: LogoutButtonProps) => {
  const { logout } = useLogout();

  return (
    <div
      role='button'
      className='flex justify-center hover:text-blue hover:cursor-pointer text-grey'
      onClick={logout}
    >
      {mirror ? (
        <FaSignOutAlt className='w-[22px] h-[22px] text-grey-500' />
      ) : (
        <FaSignOutAlt className='w-[22px] h-[22px] text-grey-500' />
      )}
    </div>
  );
};

export type { LogoutButtonProps };
export { LogoutButton };
