import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'src/ui/Button/Button';
import { useForm } from 'react-hook-form';
import { profileSchema } from 'src/common/utils/schema/profile.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { AppStateType } from 'src/store';
import { cn } from 'src/common/utils/cn';
import { AboutCard } from './AboutCard';
import { EmailAndSocialsCard } from './EmailAndSocialsCard';
import { DeleteAccountCard } from './DeleteAccountCard';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa6';
import { InstallPWACard } from './InstallPWACard';
import { PageWrapper } from '../Common/Page.wrapper';

type Inputs = z.infer<typeof profileSchema>;

export const ProfilePage = () => {
  const { t } = useTranslation();
  const { user, isSaving } = useSelector((state: AppStateType) => state.user);

  const [file, setFile] = useState<File | null | 'DELETED'>(null);
  const aboutButtonRef = useRef<HTMLButtonElement>(null);

  const form = useForm<Inputs>({
    resolver: zodResolver(profileSchema),
  });

  const resetForm = () => {
    setFile(null);
    form.reset({
      ...user,
      description: user?.expert?.description,
    });
  };

  const isChanged = form.formState.isDirty;

  return (
    <PageWrapper
      isChanged={isChanged}
      headerText={t('profileSettings.title')}
      activeElemets={
        <>
          <Button
            variant={'muted'}
            className={cn('max-w-[100px] bg-secondary h-[40px]', {
              hidden: !isChanged,
            })}
            onClick={resetForm}
          >
            {t('common.discard')}
          </Button>
          <Button
            variant={'primary'}
            onClick={() => aboutButtonRef.current?.click()}
            disabled={!isChanged || isSaving}
            className='h-[40px]'
          >
            {isSaving && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
        </>
      }
      mobileActiveElements={
        <div className='flex gap-2 w-full'>
          <Button
            className='w-1/2 h-[40px]'
            variant='primary'
            onClick={() => aboutButtonRef.current?.click()}
            disabled={!isChanged || isSaving}
          >
            {isSaving && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
          <Button
            variant='muted'
            className={cn('w-1/2 bg-secondary h-[40px]', { hidden: !isChanged })}
            onClick={resetForm}
          >
            {t('common.discard')}
          </Button>
        </div>
      }
    >
      <div className={cn('md:mt-0 mt-[15px] flex flex-col md:flex-row gap-[15px] md:gap-[30px]')}>
        <AboutCard aboutButtonRef={aboutButtonRef} {...form} file={file} setFile={setFile} />
        <div className='space-y-[15px] md:space-y-[30px]'>
          <EmailAndSocialsCard />
          <InstallPWACard />
          <DeleteAccountCard />
        </div>
      </div>
    </PageWrapper>
  );
};
