import { useEffect } from "react";
export const useAutosizeTextArea = (
    textAreaRef: HTMLTextAreaElement | null,
    value: string,
    maxHeight = 200,
    minHeight = 90
) => {
    useEffect(() => {
      const adjustHeight = () => {
        if (!textAreaRef) return;

        textAreaRef.style.height = 'auto';
        const scrollHeight = Math.min(textAreaRef.scrollHeight, maxHeight);
        textAreaRef.style.height = `${Math.max(scrollHeight, minHeight)}px`;
      };

      if (textAreaRef) {
        textAreaRef.addEventListener('input', adjustHeight);
        adjustHeight();
      }

      return () => {
        if (textAreaRef) {
          textAreaRef.removeEventListener('input', adjustHeight);
        }
      };
    }, [textAreaRef, value, maxHeight, minHeight]);
};