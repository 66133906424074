import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBuilder } from '../../services/basicFetch';
import { MethodEnum } from 'src/common/enums';

export const downloadFile = createAsyncThunk(
  'DOWNLOAD_FILE',
  async (fileName: string) => {
    try {
      const res = await fetchBuilder({}, `storage/download/${fileName}`, MethodEnum.GET);
      return res;
    } catch (error: any) {
      console.log(error);
    }
  },
);

