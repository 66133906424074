import { fetchBuilder } from 'src/services/basicFetch';
import { ExpertSettings } from 'src/store/user/user.slice';
import { MethodEnum } from '../enums';
import { NotificationSettings } from '../interfaces/notifications';

const getNotificationSettings = (): Promise<NotificationSettings> => {
  return fetchBuilder({}, 'notification-settings', MethodEnum.GET);
};

const updateNotificationSettings = (settings: Partial<NotificationSettings>) => {
  return fetchBuilder(settings, 'notification-settings', MethodEnum.PATCH);
};

export { getNotificationSettings, updateNotificationSettings };
