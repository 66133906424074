enum AiChatFrom {
  USER = 'user',
  AI = 'ai',
}

type AiChatMessage = {
  id: number;
  aiChatId: number;
  from: AiChatFrom;
  body: string;
  createdAt: string;
  updatedAt: string;
  type?: 'default' | 'summary'
};

type AiChat = {
  id: number;
  messages: AiChatMessage[];
  createdAt: string;
};

export type { AiChat, AiChatMessage };
export { AiChatFrom };
