import React from 'react';
import { FC, createContext, useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { type Messaging, getMessaging } from 'firebase/messaging';
import { firebaseDefaultConfig } from 'src/common/configs/firebaseConfig';

type FirebaseMessagingContext = {
  messaging: Messaging;
};

const FirebaseMessagingContext = createContext<FirebaseMessagingContext>(
  {} as FirebaseMessagingContext,
);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || firebaseDefaultConfig.apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || firebaseDefaultConfig.authDomain,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || firebaseDefaultConfig.projectId,
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || firebaseDefaultConfig.storageBucket,
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || firebaseDefaultConfig.messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_APP_ID || firebaseDefaultConfig.appId,
  measurementId:
    process.env.REACT_APP_FIREBASE_MEASUREMENET_ID || firebaseDefaultConfig.measurementId,
};

const useFirebaseMessaging = () => useContext(FirebaseMessagingContext);

const FirebaseMessagingProvider: FC = ({ children }) => {
  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging(app);

  return (
    <FirebaseMessagingContext.Provider value={{ messaging }}>
      {children}
    </FirebaseMessagingContext.Provider>
  );
};

export { useFirebaseMessaging, FirebaseMessagingProvider };
