import { fetchBuilder } from 'src/services/basicFetch';
import { MethodEnum } from '../enums';
import { ExpertInterface, UserInterface } from 'src/store/user/user.slice';

const getExpertCardByDomainName = async (
  domainName: string,
): Promise<(ExpertInterface & { user: UserInterface }) | null> => {
  try {
    const data = await fetchBuilder({}, `expert/business-card/${domainName}`, MethodEnum.GET);
    return data;
  } catch (err) {
    return null;
  }
};

const updateExpertDomainName = async (
  token: string,
  userId: number,
  domainName: string,
): Promise<Partial<ExpertInterface> & { domainName: string }> => {
  return await fetchBuilder(
    { token, domainName },
    `expert/domain-name/${userId}`,
    MethodEnum.PATCH,
  );
};

export interface ExpertClient {
  id: string;
  name: string;
  avatar: null | string;
}

const getExpertClients = async (): Promise<ExpertClient[]> => {
  return fetchBuilder({}, 'chats/expert-clients', MethodEnum.GET);
};

export { getExpertCardByDomainName, updateExpertDomainName, getExpertClients };
