import {
  BASE_MESSENGER_LINK,
  BASE_WHATSAPP_LINK,
  BASE_INSTAGRAM_LINK,
} from '../configs/messenger-links';

const getExpertWhatsappChatLink = (phone: string, queries?: string | null) => {
  return BASE_WHATSAPP_LINK + '/?phone=' + phone + (queries || '');
};

const getExpertMessengerChatLink = (pageId: string) => {
  return BASE_MESSENGER_LINK + '/' + pageId;
};

const getExpertInstagramProfileLink = (username: string) => {
  return BASE_INSTAGRAM_LINK + '/' + username;
};

export { getExpertWhatsappChatLink, getExpertMessengerChatLink, getExpertInstagramProfileLink };
