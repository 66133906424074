import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "src/store";
import { useExpertiseStore } from "src/store/expertise/useExpertiseStore";
import { ExpertiseInterface } from "src/store/onboarding/onboarding.slice";
import { useAiAssistantStore } from "src/store/onboarding/ai-assistant.store";
import { CardPicker } from "src/ui/CardPicker/CardPicker";

interface AiExpertiseProps {
}

export const AiExpertise: React.FC<AiExpertiseProps> = () => {
    const { data: expertises } = useExpertiseStore();
    const { updateExpertiseAi, step } = useAiAssistantStore();
    const { mutate: updateUserViaAi } = updateExpertiseAi;

    const { user } = useSelector((state: AppStateType) => state.user);

    const [expertise, setExpertise] = useState<ExpertiseInterface | null | undefined>(user?.expert?.expertise);

    useEffect(() => {
        setExpertise(user?.expert?.expertise)
    }, [user]);

    const cards = useMemo(() => {
        return (
            expertises?.map(({ expertiseId, translation }) => ({
                value: expertiseId,
                label: translation,
            })) ?? []
        );
    }, [expertises]);

    const onChange = (value: number) => {
        if (user && user.expert?.userId) {
            const expertise = expertises?.find(ex => ex.expertiseId === value);
            updateUserViaAi({
                step,
                user: {
                    ...user,
                    expert: {
                        ...user.expert,
                        expertise
                    }
                }
            });
        }
    }

    return (
        <div className="flex justify-end">
            <div className="max-w-[80%]">
                <CardPicker
                    headerText=''
                    cards={cards}
                    value={expertise?.expertiseId}
                    onChange={(value) => onChange(+value)}
                />
            </div>
        </div>
    )
}