import { StorageKeysEnum } from '../enums';
import { isIos } from './isIos';
import { isInStandaloneMode } from './isStandalone';

function showIosInstallModal(): boolean {
  const shouldShowModalResponse = isIos() && !isInStandaloneMode();

  return shouldShowModalResponse;
}

export { showIosInstallModal };
