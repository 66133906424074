import React, { ReactNode, useState } from 'react';
import './WritingPlaceholder.css';
import { cn } from 'src/common/utils';

export interface WritingPlaceholderProps extends React.HTMLAttributes<HTMLDivElement> {textClassName?: string}

export const WritingPlaceholder: React.FC<WritingPlaceholderProps> = ({
  className,
  textClassName,
  ...props
}: WritingPlaceholderProps) => {
  return (
    <div
      className={cn(
        'bg-info px-[24px] py-[16px] rounded-right-md rounded-bl-md inline-block chat-bubble',
        className,
      )}
      {...props}
    >
      <div className='typing flex items-center h-[17px]'>
        <div className={cn('dot', textClassName)}></div>
        <div className={cn('dot', textClassName)}></div>
        <div className={cn('dot', textClassName)}></div>
      </div>
    </div>
  );
};
