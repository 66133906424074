import React from 'react';
import { FaImage } from 'react-icons/fa';
import { Trans } from 'react-i18next';

const BrandLogoPlaceholder = () => {
  return (
    <div className='flex flex-col gap-[10px] items-center justify-center h-full cursor-pointer'>
      <Trans
        i18nKey={'onboarding.branding.brandLogo'}
        components={{
          1: (
            <div className='block text-[12px] leading-[18px] text-center text-grey-400 w-[109px]' />
          ),
          2: <FaImage className='w-[60px] h-[60px] text-grey-300' />,
          3: <div />,
          4: <span className='text-[14px] leading-[22px] text-grey-900' />,
          5: <div />,
          6: (
            <span className='block text-[12px] leading-[18px] text-center text-grey-400 w-[109px]' />
          ),
        }}
      />
    </div>
  );
};

export { BrandLogoPlaceholder };
