import * as z from 'zod';
import { APP_SUBDOMAINS } from '../getApp';

const domainNameSchema = z.object({
  domainName: z
    .string()
    .regex(
      /^([a-zA-Z0-9]+)$/,
      'Domain name must not have spaces and should only contain letters and numbers',
    )
    .min(3, 'Domain name must be at least 3 characters long')
    .max(63, 'Domain name must be at most 63 characters long')
    .refine(
      value => !APP_SUBDOMAINS.includes(value),
      "Domain name shouldn't be equal to the actual app subdomain",
    ),
});

export { domainNameSchema };
