export const datePresets = [
  {
    text: 'Today',
    value: 'day',
  },
  {
    text: 'Yesterday',
    value: 'yesterday',
  },
  {
    text: 'This week',
    value: 'week',
  },
  {
    text: 'This month',
    value: 'month',
  },
  {
    text: 'Last week',
    value: 'last_week',
  },
  {
    text: 'Last 7 days',
    value: 'last7',
  },
  {
    text: 'Last 14 days',
    value: 'last14',
  },
  {
    text: 'Last 28 days',
    value: 'last28',
  },
  {
    text: 'Last 30 days',
    value: 'last30',
  },
  {
    text: 'Last 60 days',
    value: 'last60',
  },
  {
    text: 'Last 90 days',
    value: 'last90',
  },
];
