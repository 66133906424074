import { useQuery } from '@tanstack/react-query';
import { getPublicContent } from 'src/common/api/public-content';

const TWO_HOURS = 1000 * 60 * 60 * 2;

const usePublicContentStore = () => {
  return useQuery(['public-content'], {
    queryFn: getPublicContent,
    cacheTime: TWO_HOURS,
    retry: false,
  });
};

export { usePublicContentStore };
