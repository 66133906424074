import React from 'react';
import { Logo } from 'src/ui/Logo/Logo';

export interface AuthWrapperProps {
  form: React.ReactNode;
  footer: React.ReactNode;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ form, footer }) => {
  return (
    <div className='pt-[30px] pb-[50px] px-[15px] md:px-[20px] min-h-[100vh] gap-12 flex justify-between flex-col bg-light'>
      <div className='mx-auto'>
        <Logo />
      </div>
      {form}
      <div className='max-w-[719px] text-center mx-auto text-sm text-[14px] leading-[22px] text-grey-500'>
        {footer}
      </div>
    </div>
  );
};
