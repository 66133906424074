import React, { InputHTMLAttributes } from 'react';
import { FaSearch } from 'react-icons/fa';
import { cn } from 'src/common/utils/cn';

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  className?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ inputClassName, className, ...props }) => {
  return (
    <div
      className={cn(
        `flex w-full gap-[15px] items-center focus-within:shadow-[0_0_4px_0_#009EF7_inset] px-[15px] py-[10px] bg-[#F3F6F9] max-w-[345px] rounded-[6px] text-[14px]`,
        className,
      )}
    >
      <div>
        <FaSearch className='text-grey' />
      </div>
      <input
        className={cn('focus:outline-none bg-[#F3F6F9]', inputClassName)}
        type='text'
        name='search'
        id='search'
        placeholder='Search'
        {...props}
      />
    </div>
  );
};

export default SearchInput;
