import React, { useEffect } from 'react';
import i18n from 'src/common/configs/i18n';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from 'src/store';

import { SocketContext, socket } from 'src/context/socket.context';
import { MutationCache, QueryCache, QueryClient, type QueryClientConfig, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'src/ui/Toast/Toaster';
import { HelmetProvider } from 'react-helmet-async';
import { catchError } from 'src/common/utils/catchError';
import { I18nextProvider } from 'react-i18next';
import { usePublicContentStore } from 'src/store/public-content/usePublicContentStore';
import { ResourceLanguage } from 'i18next';
import { FirebaseMessagingProvider } from 'src/context/firebase-messaging.context';

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};

if (process.env.NODE_ENV === 'development') {
  queryClientConfig.queryCache = new QueryCache({
    onError: (e, query) => {
      catchError(e, query.queryHash);
    },
  });

  queryClientConfig.mutationCache = new MutationCache({
    onError: (e, _, __, mutation) => {
      catchError(e, mutation.mutationId);
    },
  });
}

const queryClient = new QueryClient(queryClientConfig);

const TranslationProvider: React.FC<{}> = ({ children }) => {
  const { data: publicContent } = usePublicContentStore();

  useEffect(() => {
    if (publicContent?.translations.length) {
      const resources = publicContent?.translations.reduce((acc, { name, data }) => {
        acc[name] = { translation: data };
        return acc;
      }, {} as Record<string, ResourceLanguage>);

      i18n.init({
        resources,
      });
    }
  }, [publicContent?.translations]);

  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      {children}
    </I18nextProvider>
  );
};

const Providers = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <SocketContext.Provider value={socket}>
            <HelmetProvider>
              <TranslationProvider>
                <FirebaseMessagingProvider>
                  <Toaster />
                  {children}
                </FirebaseMessagingProvider>
              </TranslationProvider>
            </HelmetProvider>
          </SocketContext.Provider>
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  );
};

export { Providers };
