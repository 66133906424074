import * as z from 'zod';

const paymentSchema = z.object({
  accountNumber: z
    .string({
      errorMap: () => ({
        message: 'Account number is required',
      }),
    })
    .min(8, {
      message: 'Account number has at least 8 symbols',
    })
    .max(34, { message: "Account number can't have more than 34 symbols" })
    .refine(
      value => {
        return /^(?!\s)(.*\S)$/.test(value);
      },
      {
        message: 'Account number must not have leading or trailing spaces',
      },
    ),
  routingNumber: z
    .string({
      errorMap: () => ({
        message: 'Routing number is required',
      }),
    })
    .length(9, {
      message: 'Routing number has 9 digits',
    })
    .refine(
      value => {
        return /^(?!\s)(.*\S)$/.test(value);
      },
      {
        message: 'Routing number must not have leading or trailing spaces',
      },
    ),
  phoneNumber: z
    .string({
      errorMap: () => ({
        message: 'Phone number is required',
      }),
    })
    .regex(/\d{10}/, 'Phone number must have only digits and have the format XXXXXXXXXX')
    .length(10, {
      message: 'Phone number has 10 digits',
    }),
  taxID: z
    .string({
      errorMap: () => ({
        message: 'Tax ID is required',
      }),
    })
    .min(6, 'Tax ID has at least 6 symbols!')
    .refine(
      value => {
        return /^(?!\s)(.*\S)$/.test(value);
      },
      {
        message: 'Tax ID must not have leading or trailing spaces',
      },
    ),
  birthday: z.object(
    {
      year: z.number({
        errorMap: () => ({
          message: 'Birthday is required',
        }),
      }),
      date: z.number({
        errorMap: () => ({
          message: 'Birthday is required',
        }),
      }),
      month: z.number({
        errorMap: () => ({
          message: 'Birthday is required',
        }),
      }),
    },
    {
      errorMap: () => ({
        message: 'Birthday is required',
      }),
    },
  ),
  city: z
    .string({
      errorMap: () => ({
        message: 'City is required',
      }),
    })
    .min(2, { message: 'City has to include at least 2 symbols' })
    .refine(
      value => {
        return /^(?!\s)(.*\S)$/.test(value);
      },
      {
        message: 'City must not have leading or trailing spaces',
      },
    ),
  addrLine1: z
    .string({
      errorMap: () => ({
        message: 'Address line is required',
      }),
    })
    .min(1, { message: 'Address line is required' })
    .refine(
      value => {
        return /^(?!\s)(.*\S)$/.test(value);
      },
      {
        message: 'Address line must not have leading or trailing spaces',
      },
    ),
  addrLine2: z
    .string()
    .optional()
    .nullable()
    .refine(
      value => {
        return value ? /^(?!\s)(.*\S)$/.test(value) : true;
      },
      {
        message: 'Address line must not have leading or trailing spaces',
      },
    ),
  postCode: z
    .string({
      errorMap: () => ({
        message: 'Post code is required',
      }),
    })
    .min(5, 'At least 5 symbols required')
    .regex(/^\d{5}$/, 'Postal code has to include 5 digits without any spaces'),
  state: z.string({
    errorMap: () => ({
      message: 'State is required',
    }),
  }),
});

export { paymentSchema };
