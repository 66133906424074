import React, { PropsWithChildren } from 'react';
import { Button } from '../Button/Button';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'src/common/utils/cn';

const cardVariants = cva('rounded-md', {
  variants: {
    variant: {
      default: 'p-[30px] bg-white',
      dashed: 'border border-grey-400 p-[20px] border-dashed',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface CardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardVariants> {
  children?: React.ReactNode;
}

const Card = ({ children, variant, className, onClick }: CardProps) => {
  return (
    <div
      className={cn(
        cardVariants({
          variant,
          className,
        }),
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export { Card };
