import React, { useEffect, useMemo, useState } from 'react';
import { ExpertSubscription, updateSubscriptionsLocaly } from 'src/store/user/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { extendPlans } from '../utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getSubscriptionsApi, updateSubscriptionsApi } from '../api/subscriptions';
import { useToast } from 'src/ui/Toast/use-toast';

export const useSubscriptions = (showToast: boolean = false) => {
  const { toast } = useToast();

  const { mutate: updateSubscriptions, isLoading } = useMutation({
    mutationFn: (data: { sub: ExpertSubscription[] }) => updateSubscriptionsApi(data.sub),
    onSuccess: data => {
      dispatch(updateSubscriptionsLocaly(data));
      showToast && toast({ title: 'Subscriptions succesfully updated', variant: 'success' });
    },
  });

  const { refetch: getSubscriptions } = useQuery(['expert-subscriptions'], {
    queryFn: getSubscriptionsApi,
    onSuccess: data => {
      dispatch(updateSubscriptionsLocaly(data));
    },
  });

  const dispatch = useDispatch();
  const { user, expertSubscriptions } = useSelector((state: AppStateType) => state.user);
  const subscriptions = useMemo(() => {
    const sub = extendPlans(expertSubscriptions);
    return [...sub];
  }, [expertSubscriptions]);

  const [error, setError] = useState<string | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    !expertSubscriptions.find(e => e.isEnabled && e.price && e.price > 0)
      ? setError('At least a one subscription with a valid price should be selected!')
      : setError(null);
  }, [expertSubscriptions]);

  const onSubmit = () => {
    if (!error) {
      setIsChanged(false);
      if (user) {
        updateSubscriptions({
          sub: expertSubscriptions,
        });
      }
    } else {
      setShowError(true);
    }
  };

  function isAllDataValid() {
    let hasEnabledWithPrice = false;
    let hasEnabledWithoutPrice = false;

    for (const subscription of subscriptions) {
      const { isEnabled, price } = subscription;
      if (isEnabled) {
        if (price && price > 0) {
          hasEnabledWithPrice = true;
        } else {
          hasEnabledWithoutPrice = true;
        }
      }
    }

    if (!hasEnabledWithPrice || hasEnabledWithoutPrice) {
      return false;
    }

    return true;
  }

  const isValid = useMemo(() => {
    return isAllDataValid();
  }, [subscriptions]);

  const updateSubscriptionPlan = (idx: number, newData: Partial<ExpertSubscription>) => {
    const updatedPlans = [...expertSubscriptions];
    if (JSON.stringify(newData) !== JSON.stringify(updatedPlans[idx])) {
      updatedPlans[idx] = { ...updatedPlans[idx], ...newData };
      setIsChanged(updatedPlans.some(p => p.isEnabled) ? true : false);
      dispatch(updateSubscriptionsLocaly(updatedPlans));
    }
  };

  const setIsCardActive = (idx: number, isEnabled: boolean) => {
    updateSubscriptionPlan(idx, { isEnabled });
  };

  const onDescriptionChange = (idx: number, description: string) => {
    updateSubscriptionPlan(idx, { description });
  };

  const onPriceChange = (idx: number, price: number) => {
    updateSubscriptionPlan(idx, { price });
  };

  const onQuestionsAmountChange = (idx: number, amount: number) => {
    updateSubscriptionPlan(idx, { amount });
  };

  const uploadSubscriptions = (id: number) => {
    getSubscriptions();
    setIsChanged(false);
  };

  return {
    error,
    showError,
    setIsCardActive,
    onSubmit,
    onPriceChange,
    onDescriptionChange,
    onQuestionsAmountChange,
    subscriptions,
    isChanged,
    uploadSubscriptions,
    isLoading,
    isValid,
  };
};
