import React, { useContext, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectUser } from 'src/store/user/user.slice';
import { cn } from 'src/common/utils/cn';

import ChatHeader from 'src/ui/ChatHeader/ChatHeader';
import Seo from 'src/components/Seo';

import { useChatStore } from 'src/store/chat/chatStore';
import TabSwitcher from 'src/ui/TabSwitch/TabSwitch';
import { ROUTES } from 'src/router/routes';
import { useActiveChat } from 'src/store/chat/useActiveChat';

const ChatPage = () => {
  const { chatId } = useParams();

  useActiveChat(chatId);

  const { activeChatInfo } = useChatStore();

  const user = useSelector(selectUser);

  if (!user) {
    return <Navigate to='/' />;
  }

  if (!chatId || !activeChatInfo) return <></>;

  const hasAiChat = !!activeChatInfo.aiChatId;

  return (
    <>
      <Seo
        title={activeChatInfo?.client?.name || 'Chat'}
        description={`Chat with ${activeChatInfo?.client?.name}.`}
        name={'Anamata'}
      />
      <div
        className={cn(
          'w-full flex flex-col justify-between bg-chat-bg md:h-[calc(100vh-100px)] lg:h-screen sm:h-screen fixed sm:static h-full',
        )}
      >
        <ChatHeader
          username={activeChatInfo.client?.name || ''}
          userImage={activeChatInfo.client?.avatar || ''}
          messengerType={activeChatInfo.messengerType}
          withAiHelper={hasAiChat}
        />
        {hasAiChat && <ChatTabs chatId={chatId} />}
        <Outlet />
      </div>
    </>
  );
};

const ChatTabs = ({ chatId }: { chatId: string }) => {
  const { activeChatInfo } = useChatStore();

  const location = useLocation();
  const navigate = useNavigate();

  const value = location.pathname.endsWith(ROUTES.AI_CHAT) ? 'ai-chat' : 'client-chat';

  return (
    <TabSwitcher
      value={value}
      onChange={value => {
        if (value === 'ai-chat') {
          navigate({
            pathname: ROUTES.AI_CHAT,
            search: location.search,
          });
        } else {
          navigate({ pathname: `/messages/${chatId}`, search: location.search });
        }
      }}
      className='-mt-[10px] sm:-mt-0 px-2 pt-2 sm:p-4 bg-white !pb-[0] fixed top-[74px] inset-x-0 sm:static z-20'
      tabs={[
        {
          className: 'text-[14px]',
          count: activeChatInfo?.unreadCount,
          label: 'Client chat',
          value: 'client-chat',
        },
        {
          label: 'AI Helper chat',
          value: 'ai-chat',
          className: 'text-info border-info text-[14px]',
        },
      ]}
    />
  );
};

export default ChatPage;
