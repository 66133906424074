const firebaseDefaultConfig = {
  apiKey: 'AIzaSyDPHME9Zw1b5umvRbzA9Y9ySj5Geeibqws',
  authDomain: 'anamata-dev-7a211.firebaseapp.com',
  projectId: 'anamata-dev-7a211',
  storageBucket: 'anamata-dev-7a211.appspot.com',
  messagingSenderId: '547371228198',
  appId: '1:547371228198:web:3fcdb413f4fa1ab1951ae7',
  measurementId: 'G-KQVHMH5TJX',
};

export { firebaseDefaultConfig };
