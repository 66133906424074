import React, { useState } from 'react';
import { useInstallPromptStore } from 'src/common/hooks/useInstallPrompt';
import { Button } from 'src/ui/Button/Button';
import { FaTimes } from 'react-icons/fa';
import { StorageKeysEnum } from 'src/common/enums';
import { showIosInstallModal } from 'src/common/utils/showIosInstallModal';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/ui/Toast/use-toast';

const InstallPWABar = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [hidden, setIsHidden] = useState(
    !!localStorage.getItem(StorageKeysEnum.HIDE_INSTALLATION_BAR),
  );

  const { promptEvent } = useInstallPromptStore();
  const showIosInstall = showIosInstallModal();

  if ((!promptEvent && !showIosInstall) || hidden) return <></>;

  return (
    <div
      className='p-4 bg-warning-light flex items-center cursor-pointer'
      onClick={() => {
        if (showIosInstall) {
          toast({
            title: t('profileSettings.pwa.toast.title'),
            description: t('profileSettings.pwa.toast.description'),
          });
          localStorage.setItem(StorageKeysEnum.HIDE_INSTALLATION_BAR, 'true');
          setIsHidden(true);
        } else if (promptEvent !== null) {
          promptEvent.prompt().then(value => {
            if (value.outcome === 'accepted') setIsHidden(true);
            localStorage.setItem(StorageKeysEnum.HIDE_INSTALLATION_BAR, 'true');
          });
        }
      }}
    >
      <img
        src='https://anamata-bucket-stg.s3.amazonaws.com/anamata-bucket-stg/Anamata-logo.png'
        alt='Anamata'
        className='w-10 h-6'
      />
      <span className='ml-2'>{t('auth.installBar')}</span>
      <Button
        className='ml-auto'
        onClick={e => {
          e.stopPropagation();
          setIsHidden(true);
        }}
      >
        <FaTimes className='w-6 h-6 text-grey-400' />
      </Button>
    </div>
  );
};

export { InstallPWABar };
