import { useMutation, useQuery } from '@tanstack/react-query';
import { useBrowserNotificationsStore } from './useBrowserNotificationsStore';
import { getToken } from 'firebase/messaging';
import { useFirebaseMessaging } from 'src/context/firebase-messaging.context';
import { saveRegistationToken } from 'src/common/api/registration-token';

const vapidKey = process.env.REACT_APP_FCM_VAPID_KEY;

const usePushNotificationsStore = () => {
  const { isGranted } = useBrowserNotificationsStore();
  const { messaging } = useFirebaseMessaging();

  const { mutate: saveToken } = useMutation({
    mutationFn: saveRegistationToken,
  });

  const { data: token } = useQuery(['fcm-token', isGranted], {
    queryFn: () => {
      if (!isGranted || !messaging) return null;
      return getToken(messaging, { vapidKey });
    },
    onSuccess: token => {
      if (token) saveToken(token);
    },
  });
};

export { usePushNotificationsStore };
