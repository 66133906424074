import React, { Component, useContext, useEffect, useRef } from 'react';

import { UserInterface } from 'src/store/user/user.slice';

import { ROUTES } from 'src/router/routes';
import { UserMenu } from 'src/components/UserMenu';
import {
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from 'src/ui/DropdownMenu/DropdownMenu';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SharedMenuGroup } from 'src/ui/MenuBar/MenuBar';

const TabletMenu = ({ user, className }: { user: UserInterface; className?: string }) => {
  const { t } = useTranslation();
  const route = useLocation().pathname.split('/')[0];

  return (
    <UserMenu
      avatarClassName={'w-14 h-14'}
      userImage={user.avatarUrl ?? ''}
      username={`${user.firstName ?? ''} ${user.lastName ?? ''}`}
      email={user.email}
    >
      <SharedMenuGroup />
      <DropdownMenuSeparator />
      <DropdownMenuGroup className='px-2'>
        <DropdownMenuLabel>{t('tabletMenu.businessSettings')}</DropdownMenuLabel>
        <DropdownMenuItem
          active={route === ROUTES.BUSINESS_CARD}
          className='body !pt-[7px] !pb-[9px] !text-[14px]'
          asChild
        >
          <Link to={`/${ROUTES.BUSINESS_CARD}`}>{t('tabletMenu.businessCard')}</Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          active={route === ROUTES.PAYMENTS}
          className='body !pt-[7px] !pb-[9px] !text-[14px]'
          asChild
        >
          <Link to={`/${ROUTES.PAYMENTS}`}>{t('tabletMenu.payments')}</Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          active={route === ROUTES.SUBSCRIPTIONS}
          className='body !pt-[7px] !pb-[9px] !text-[14px]'
          asChild
        >
          <Link to={`/${ROUTES.SUBSCRIPTIONS}`}>{t('tabletMenu.subscriptions')}</Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          active={route === ROUTES.AI_HELPER_SETTINGS}
          className='body !pt-[7px] !pb-[9px] !text-[14px]'
          asChild
        >
          <Link to={`/${ROUTES.AI_HELPER_SETTINGS}`}>{t('tabletMenu.aiHelper')}</Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          active={route === ROUTES.COMMUNICATION_CHANNELS}
          className='body !pt-[7px] !pb-[9px] !text-[14px]'
          asChild
        >
          <Link to={`/${ROUTES.COMMUNICATION_CHANNELS}`}>{t('tabletMenu.communication')}</Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          active={route === ROUTES.OFFER}
          className='body !pt-[7px] !pb-[9px] !text-[14px]'
        >
          <Link to={`/${ROUTES.OFFER}`}>{t('tabletMenu.offerDocument')}</Link>
        </DropdownMenuItem>
      </DropdownMenuGroup>
    </UserMenu>
  );
};

export { TabletMenu };
