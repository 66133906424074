import React from 'react';
import { OnboardingWrapper, PreviewMobileWrapper } from './Common';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import ChatClientRequest from 'src/ui/ChatClientRequest/ChatClientRequest';
import { Card } from 'src/ui/Card/Card';
import MessageInput from 'src/ui/MessageInput/MessageInput';
import { useOffer } from 'src/common/hooks';
import { OfferTabs } from 'src/components/OfferTabs';
import { ExpertSubscriptionTypeEnum } from 'src/common/enums/expert-plan-type.enum';
import { cn } from 'src/common/utils';
import { MessageVariant, MessengerType } from 'src/common/interfaces';
import PotentialClient from 'src/common/assets/potential-client.png';
import PaymentText from 'src/common//assets/TXT-payment@x2.png';
import OfferText from 'src/common//assets/TXT-public-offer@x2.png';

interface OfferLeftProps {}

export const OfferLeft: React.FC<OfferLeftProps> = () => {
  const { t } = useTranslation();
  const { error, setError, offer, onOfferChange, uploadOffer } = useOffer();

  return (
    <>
      <OnboardingWrapper
        headerText='onboarding.offer.header'
        subheaderText='onboarding.offer.subheader'
        customSubmitText={
          !offer ? t('onboarding.offer.saveButton') : t('onboarding.offer.uploadButton')
        }
        onSubmit={() => (!offer ? uploadOffer(null, true) : uploadOffer(offer))}
      >
        <OfferTabs error={error} onOfferChange={onOfferChange} offer={offer} setError={setError} />
        <div className='secondary text-center mt-[30px]'>{t('onboarding.offer.tabs.doc.tip')}</div>
      </OnboardingWrapper>
    </>
  );
};

export interface OfferPreviewProps {
  showPdf?: boolean;
}

export const OfferPreview: React.FC<OfferPreviewProps> = ({ showPdf }) => {
  const { user } = useSelector((state: AppStateType) => state.user);
  const { t } = useTranslation();

  return user ? (
    <div className='relative'>
      <PreviewMobileWrapper>
        <div>
          <div className='bg-light rounded-lg overflow-hidden'>
            <ChatClientRequest
              chatId=''
              message={{
                body: t('onboarding.offer.messagePlaceholder'),
                createdAt: '',
                variant: MessageVariant.CLIENT_DESCRIPTION,
              }}
              username={'Leslie Alexander'}
              subscriptionType={ExpertSubscriptionTypeEnum.CONSULTATION}
              userImage={PotentialClient}
              messengerType={MessengerType.WHATSAPP}
              isDemo
            />
            <div className='flex justify-center -mt-[16px] mb-[16px]'>
              <div className='text-center text-[14px] px-[12px] py-[10px] rounded-md bg-white max-w-[245px]'>
                {t('onboarding.offer.messagePlaceholder')}
              </div>
            </div>
          </div>
          <Card variant={'dashed'} className='border-primary bg-primary-light mt-[15px]'>
            <div className='flex flex-col gap-2 text-left relative'>
              <Trans
                i18nKey={
                  !showPdf ? 'systemMessage.clientApprovedReduced' : 'systemMessage.clientApproved'
                }
                components={{
                  1: <span />,
                  2: <span className='text-primary w-fit' />,
                  3: <span className='text-primary' />,
                }}
              />
              <div
                className={cn('absolute  left-[50%] w-[244px]', {
                  'top-8': !showPdf,
                  'top-16': showPdf,
                })}
              >
                <img src={!showPdf ? PaymentText : OfferText}></img>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <div className='h-[1px] w-full bg-secondary mt-[10px]'></div>
          <MessageInput
            placeholder={'Write your answer'}
            activeClient='bot'
            userId='test'
            onChange={() => {}}
            dispatch={null}
            isDemonstration={true}
          />
        </div>
      </PreviewMobileWrapper>
    </div>
  ) : (
    <></>
  );
};
