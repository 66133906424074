import * as React from 'react';
import { Socket, io } from 'socket.io-client';

const SERVER = process.env.REACT_APP_WSS_URL || '';

const socket: Socket = io(SERVER, {
  transports: ['websocket'],
});

const SocketContext = React.createContext(socket);

const useSocketContext = () => React.useContext(SocketContext);

export { SocketContext, socket, useSocketContext };
