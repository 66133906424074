import { useCallback } from 'react';
import { useLocation, useSearchParams as useRRSearchParams } from 'react-router-dom';

const useSearchParams = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useRRSearchParams();

  const setNewSearchParams = useCallback(
    (newSearchParams: Record<string, string>) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        ...newSearchParams,
      });
    },
    [location, searchParams],
  );

  return { searchParams, setSearchParams, setNewSearchParams };
};

export { useSearchParams };
