import React from 'react';
import { Button } from 'src/ui/Button/Button';
import { useDispatch } from 'react-redux';
import { setStep } from 'src/store/onboarding/onboarding.slice';
import { OnboardingSteps } from 'src/common/enums/onboardingSteps.enum';

interface AiStripeConnectProps {
  setIsAiActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AiStripeError: React.FC<AiStripeConnectProps> = ({ setIsAiActive }) => {
  const dispatch = useDispatch();

  return (
    <div className='flex justify-center'>
      <Button
        className='mt-[16px]'
        onClick={() => {
          dispatch(setStep(OnboardingSteps.PAYMENTS));
          setIsAiActive(false);
        }}
        variant='primary'
      >
        Check data
      </Button>
    </div>
  );
};
