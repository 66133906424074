import React, { ReactElement, useEffect } from 'react';
import { Navigate, Route, Routes, useSearchParams as useRRSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppStateType } from './store';
import { ROUTES } from './router/routes';

import { AuthPage } from './pages/Auth/Auth.page';

import './App.css';
import { getUserViaToken } from './store/user/user.actions';
import { OnboardingPage } from './pages/Onboarding/Onboarding.page';
import ExpertCardPage from './pages/Expert-card/ExpertCard.page';
import Seo, { SeoProps } from './components/Seo';
import PaymentSuccess from './pages/Payment-success/PaymentSuccess';
import PaymentFail from './pages/Payment-fail/PaymentFail';
import { MainLayout } from './MainLayout';
import { isPublicApp } from './common/utils';
import { useInstallPromptDetect } from './common/hooks/useInstallPrompt';
import MessengerTutorialPage from './pages/Tutorials/MessengerTutorial.page';
import WhatsappTutorialPage from './pages/Tutorials/WhatsappTutorial.page';
import InstagramTutorialPage from './pages/Tutorials/InstagramTutorial.page';

export const PublicAppContent = () => {
  return (
    <Routes>
      <Route path='/*' element={<ExpertCardPage />} />
    </Routes>
  );
};

export const AppContent = () => {
  const dispatch = useDispatch();
  const [searchParams] = useRRSearchParams();
  const accessToken = searchParams.get('access-token');

  useInstallPromptDetect();

  useEffect(() => {
    const token = accessToken ?? localStorage.getItem('userToken');

    if (token) {
      dispatch(getUserViaToken({ token }));
    }
  }, []);

  return (
    <Routes>
      <Route path={ROUTES.LOGIN} element={<AuthPage />} />
      <Route
        path='/*'
        element={
          <RequireAuth>
            <MainLayout />
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.ONBOARDING}
        element={
          <RequireAuth>
            <SeoProvider
              title='Onboarding'
              description={'Expert onboarding page.'}
              name={'Anamata'}
            >
              <OnboardingPage />
            </SeoProvider>
          </RequireAuth>
        }
      />
      <Route path={`${ROUTES.ONBOARDING}/${ROUTES.TUTORIAL}`}>
        <Route
          path={ROUTES.MESSENGER_TUTORIAL}
          element={
            <SeoProvider
              title='Messenger connection guide'
              description={'Facebook messenger connection guide.'}
              name={'Anamata'}
            >
              <MessengerTutorialPage />
            </SeoProvider>
          }
        ></Route>
        <Route
          path={ROUTES.WHATSAPP_TUTORIAL}
          element={
            <SeoProvider
              title='Whatsapp connection guide'
              description={'Whatsapp connection guide.'}
              name={'Anamata'}
            >
              <WhatsappTutorialPage />
            </SeoProvider>
          }
        ></Route>
        <Route
          path={ROUTES.INSTAGRAM_TUTORIAL}
          element={
            <SeoProvider
              title='Instagram connection guide'
              description={'Instagram connection guide.'}
              name={'Anamata'}
            >
              <InstagramTutorialPage />
            </SeoProvider>
          }
        ></Route>
      </Route>
      <Route
        path={`${ROUTES.PAYMENT_SUCCESS}`}
        element={
          <SeoProvider title='Payment success 🎉' description={'Payment status'} name={'Anamata'}>
            <PaymentSuccess />
          </SeoProvider>
        }
      />
      <Route
        path={`${ROUTES.PAYMENT_DENIED}`}
        element={
          <SeoProvider title='Payment denied' description={'Payment status'} name={'Anamata'}>
            <PaymentFail />
          </SeoProvider>
        }
      />
    </Routes>
  );
};

const RequireAuth = ({ children }: { children: ReactElement }) => {
  const { userToken } = useSelector((state: AppStateType) => state.user);
  return userToken ? children : <Navigate to='login' />;
};

type SeoProviderProps = SeoProps & { children: React.ReactNode };

const SeoProvider = ({ children, ...props }: SeoProviderProps) => {
  return (
    <>
      <Seo {...props} />
      {children}
    </>
  );
};

function App() {
  return !isPublicApp() ? <AppContent /> : <PublicAppContent />;
}

export { SeoProvider };

export default App;
