import { useQuery } from '@tanstack/react-query';
import { getChatInfo } from 'src/common/api/chat';
import { useChatStore } from './chatStore';

const useActiveChat = (chatId: string | undefined) => {
  const { setActiveChat, resetUnreadCount } = useChatStore();

  return useQuery(['chat-info', chatId], () => getChatInfo(chatId), {
    onSuccess: data => {
      setActiveChat(data);
      chatId && resetUnreadCount(chatId);
    },
  });
};

export { useActiveChat };
