import React from 'react';
import { FaSpinner, FaRobot } from 'react-icons/fa6';
import { Button } from 'src/ui/Button/Button';
import {
  ChatConnectAiButton,
  ChatControlAiButton,
} from 'src/ui/ChatConnectAiButton/ChatConnectAiButton';

const SuggestAiButton = ({
  chatId,
  connect,
  isLoading,
  text,
  disabled
}: {
  chatId: string;
  connect: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  text: string;
}) => {
  if (!chatId) return <></>;

  return (
    <ChatControlAiButton
      className='w-full max-w-[800px] mt-6'
      text={text}
      onClick={connect}
      isLoading={isLoading}
      disabled={disabled}
    />
  );
};

const ConnectAiButton = ({
  variant = 'big',
  chatId,
  connect,
  isLoading,
}: {
  chatId: string;
  connect: (chatId: string) => void;
  isLoading?: boolean;
  variant?: 'small' | 'big';
}) => {
  if (!chatId) return <></>;

  if (variant === 'small') {
    return (
      <Button onClick={() => connect(chatId)} disabled={isLoading} className='text-2xl text-info'>
        {isLoading ? <FaSpinner className='animate-spin w-6 h-6 text-info' /> : <FaRobot />}
      </Button>
    );
  }

  return (
    <div className='flex justify-center w-full'>
      <ChatConnectAiButton
        className='w-full max-w-[800px] mt-6'
        onClick={() => connect(chatId)}
        isLoading={isLoading}
        disabled={isLoading}
      />
    </div>
  );
};

export { ConnectAiButton, SuggestAiButton };
