import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getExpertCardByDomainName } from 'src/common/api/expert';
import { ExpertInterface, UserInterface } from 'src/store/user/user.slice';
import { ProfilePreview } from 'src/ui/ProfilePreview/ProfilePreview';
import NotFoundPage from '../NotFound/NotFound.page';
import Seo from 'src/components/Seo';

const ExpertCardPage = () => {
  const expertDomain = window.location.hostname.split('.')[0];

  const { data, isLoading } = useQuery(
    ['expert', expertDomain],
    (): Promise<(ExpertInterface & { user: UserInterface }) | null> | null => {
      if (!expertDomain) return null;
      return getExpertCardByDomainName(expertDomain);
    },
  );

  if (!data?.user && !isLoading) return <NotFoundPage />;

  if (!data?.user) return <></>;

  const brandLogoUrl = data.brandLogoUrl;

  return (
    <>
      <Seo
        title={`${data.user.firstName ?? ''} ${data.user.lastName ?? ''}`}
        description={`${data.user.firstName ?? ''} ${data.user.lastName ?? ''} business card`}
        name={'Anamata'}
      />
      <div className='relative min-w-screen min-h-screen flex items-center justify-center sm:px-6 overflow-hidden'>
        <div
          className='absolute w-screen h-screen after:backdrop-blur-md overflow-hidden bg-no-repeat bg-cover'
          style={{
            backgroundImage: `url(${brandLogoUrl})`,
            filter: 'blur(50px)',
            transform: 'scale(1.25)',
          }}
        ></div>
        <ProfilePreview {...{ ...data, ...data.user, brandLogoUrl }} withCreds clientPreview/>
      </div>
    </>
  );
};

export default ExpertCardPage;
