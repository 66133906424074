import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'src/ui/Button/Button';
import { Card } from 'src/ui/Card/Card';
import { OfferPreview } from '../Onboarding/Subpages/Offer.subpage';
import { FaSpinner, FaStripe } from 'react-icons/fa';
import { PaymentsForm } from '../Onboarding/Subpages/Payments/PaymentsForm';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { paymentSchema } from 'src/common/utils/schema/payments.schema';
import { z } from 'zod';
import { cn } from 'src/common/utils';
import { PageWrapper } from '../Common/Page.wrapper';

type Inputs = z.infer<typeof paymentSchema>;

export const PaymentPage = () => {
  const { t } = useTranslation();

  const paymentButtonRef = useRef<HTMLButtonElement>(null);
  const resetButtonRef = useRef<HTMLButtonElement>(null);

  const form = useForm<Inputs>({
    resolver: zodResolver(paymentSchema),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isChanged = form.formState.isDirty;

  return (
    <PageWrapper
      headerText={t('businessSettings.payments.header')}
      isChanged={isChanged}
      activeElemets={
        <>
          <Button
            variant={'muted'}
            onClick={() => resetButtonRef.current?.click()}
            className={cn('max-w-[100px] bg-secondary h-[40px]', { hidden: !isChanged })}
          >
            {t('common.discard')}
          </Button>
          <Button
            className='h-[40px]'
            variant={'primary'}
            onClick={() => paymentButtonRef.current?.click()}
            disabled={!isChanged || isLoading}
          >
            {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
        </>
      }
      mobileActiveElements={
        <div className='flex gap-2 w-full'>
          <Button
            className='w-1/2 h-[40px]'
            variant='primary'
            disabled={!isChanged || isLoading}
            onClick={() => paymentButtonRef.current?.click()}
          >
            {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
          <Button
            variant={'muted'}
            className={cn('w-1/2 bg-secondary h-[40px]', { hidden: !isChanged })}
            onClick={() => resetButtonRef.current?.click()}
          >
            {t('common.discard')}
          </Button>
        </div>
      }
    >
      <div className='md:mt-0 mt-[17px] flex flex-col md:flex-row gap-20 '>
        <div className='w-full lg:w-6/12'>
          <h2
            className={cn(
              'justify-center md:justify-start mt-[4px] mb-[24px] md:-mt-[8px] flex flex-row items-center gap-[0] flex-wrap w-full md:-mt-[8px]',
            )}
          >
            <Trans
              i18nKey={'businessSettings.payments.subheader'}
              components={{
                1: (
                  <FaStripe
                    className='text-[#635BFF] text-[38px] shrink inline mt-[2px] mx-[5px]'
                    style={{
                      maxHeight: 'fit-content',
                    }}
                  />
                ),
                2: <></>,
              }}
            />
          </h2>
          <Card variant={'default'}>
            <PaymentsForm
              showToast
              paymentButtonRef={paymentButtonRef}
              resetButtonref={resetButtonRef}
              setIsLoading={setIsLoading}
              {...form}
            />
          </Card>
        </div>
        <div className='space-y-12 w-6/12 hidden lg:block'>
          <div className='m-auto w-fit mt-[10vh]'>
            <OfferPreview />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
