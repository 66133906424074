import React from "react";
import { useSelector } from "react-redux";
import { useImagesUpload } from "src/common/hooks";
import { AvatarPlaceholder } from "src/components/AvatarPlaceholder";
import { BrandLogoPlaceholder } from "src/components/BrandLogoPlaceholder";
import { AppStateType } from "src/store";
import { useAiAssistantStore } from "src/store/onboarding/ai-assistant.store";
import { Button } from "src/ui/Button/Button";
import { FileInput } from "src/ui/FileInput/FileInput";

interface AiPhotoUploadingProps {
}

export const AiPhotoUploading: React.FC<AiPhotoUploadingProps> = () => {
    const { user } = useSelector((state: AppStateType) => state.user);

    const {
        images,
        handleOnChange,
        handleOnDelete,
        handleCropperApply
    } = useImagesUpload(user);

    const { updateAvatars } = useAiAssistantStore();
    const { mutate: uploadImages } = updateAvatars;

    const onSubmit = () => {
        const { avatar, logo } = images;
        uploadImages([
            {
                file: avatar,
                key: 'avatar'
            },
            {
                file: logo,
                key: 'logo'
            }
        ]);
    }

    return (
      <div className='flex flex-col gap-[16px] items-center'>
        <div className='flex gap-[16px] flex-col items-center md:flex-row justify-center mt-2'>
          <div className='sm:max-w-[50%]'>
            <FileInput
              defaultUrl={user?.avatarUrl ?? ''}
              onChange={(e, url) => handleOnChange(e, url, 'avatar')}
              onDelete={url => handleOnDelete('DELETED', 'avatar')}
              onCropperApply={(file, url) => handleCropperApply?.(url, file, 'avatar')}
            >
              <AvatarPlaceholder />
            </FileInput>
          </div>
          <div className='sm:max-w-[50%]'>
            <FileInput
              key={user?.expert?.brandLogoUrl}
              defaultUrl={user?.expert?.brandLogoUrl ?? undefined}
              onChange={(e, url) => handleOnChange(e, url, 'logo')}
              onDelete={url => handleOnDelete('DELETED', 'logo')}
              onCropperApply={(file, url) => handleCropperApply?.(url, file, 'logo')}
            >
              <BrandLogoPlaceholder />
            </FileInput>
          </div>
        </div>
        <Button
          variant='primary'
          className='w-fit'
          onClick={() => onSubmit()}
        >
          Send
        </Button>
      </div>
    );
};