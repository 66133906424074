import React, { useEffect, useState } from 'react';

const screens = {
  phone: 0,
  mobile: 767,
  laptop: 1024,
  'laptop-xs': 1279,
} as const;

export const useScreenDetect = (screen: keyof typeof screens) => {
  const [isTargetScreen, setIsTargetScreen] = useState<boolean>(false);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia(`(max-width: ${screens[screen]}px)`);

    const handleMobileChange = (event: any) => {
      setIsTargetScreen(event.matches);
    };

    mobileMediaQuery.addEventListener('change', handleMobileChange);
    setIsTargetScreen(mobileMediaQuery.matches);

    return () => {
      mobileMediaQuery.removeEventListener('change', handleMobileChange);
    };
  }, []);

  return { isTargetScreen };
};
