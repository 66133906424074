import React from 'react';
import { IconType } from 'react-icons';

export interface FileInputButtonProps {
  onClickHandle: () => void;
  icon: IconType;
}

export const FileInputButton: React.FC<FileInputButtonProps> = ({ onClickHandle, icon: Icon }) => {
  return (
    <div
      className='hover:cursor-pointer w-[40px] h-[40px] rounded-[6px] bg-white rounded-[6px] 
        flex flex-col justify-center items-center
        shadow-[0_0_10px_0_rgba(74,80,102,0.14)]'
      onClick={onClickHandle} >
      <Icon className='w-[16px] h-[16px] text-grey-500' />
    </div>
  )
};