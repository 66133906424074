import { fetchBuilder } from 'src/services/basicFetch';
import { MethodEnum } from '../enums';
import { ExpertSubscription } from 'src/store/user/user.slice';

export const getSubscriptionsApi = async (): Promise<ExpertSubscription[]> => {
  return await fetchBuilder({}, `expert-subscription`, MethodEnum.GET);
};

export const updateSubscriptionsApi = async (
  subscriptions: ExpertSubscription[],
): Promise<ExpertSubscription[]> => {
  return await fetchBuilder({ subscriptions }, `expert-subscription`, MethodEnum.PATCH);
};
