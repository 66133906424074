import React from 'react';
import { InputHTMLAttributes } from 'react';
import { Switch } from 'src/ui/Switch/Switch';

const EnableNotificationSwitch = ({
  checked,
  onChange,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Switch
      isChecked={!!checked}
      onChange={e => onChange?.(e)}
      {...props}
      className='text-xl'
      labelClassName='medium'
    />
  );
};

export { EnableNotificationSwitch };
