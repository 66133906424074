import React, { useMemo } from 'react';
import { OnboardingWrapper, PreviewMobileWrapper } from './Common';
import { SubscriptionCard } from 'src/ui/SubscriptionCard/SubscriptionCard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { Button } from 'src/ui/Button/Button';
import { useSubscriptions } from 'src/common/hooks/useSubscriptions';
import { extendPlans, subscriptionTypeToString } from 'src/common/utils';
import { FieldError } from 'src/components/FieldError';
import { ExpertSubscription } from 'src/store/user/user.slice';
import SubscriptionsText from 'src/common//assets/TXT-subscriptions@x2.png';

interface ConnectionLeftProps {}

export const SubscriptionsLeft: React.FC<ConnectionLeftProps> = () => {
  const { t } = useTranslation();

  const {
    error,
    onDescriptionChange,
    onPriceChange,
    onQuestionsAmountChange,
    onSubmit,
    setIsCardActive,
    showError,
    subscriptions,
    isValid,
    isLoading,
  } = useSubscriptions();

  return (
    <>
      <OnboardingWrapper
        headerText='onboarding.subscriptions.header'
        subheaderText='onboarding.subscriptions.subheader'
        onSubmit={onSubmit}
        isSubmitDisabled={!isValid || isLoading}
        isLoading={isLoading}
        blockNextStep={error ? true : false}
      >
        <div className='flex flex-col gap-[16px]'>
          {subscriptions.length ? (
            subscriptions.map((p, idx) => (
              <SubscriptionCard
                key={idx}
                headerText={t(p.title)}
                subscription={p}
                setIsCardActive={e => setIsCardActive(idx, e.target.checked)}
                onDescriptionChange={e => onDescriptionChange(idx, e.target.value)}
                onPriceChange={e => onPriceChange(idx, +e.target.value)}
                onQuestionsAmountChange={e => onQuestionsAmountChange(idx, +e)}
                options={p.options}
              />
            ))
          ) : (
            <></>
          )}
          {showError && error && <FieldError error={error} />}
        </div>
      </OnboardingWrapper>
    </>
  );
};

interface ExampleMessageProps {
  children: React.ReactNode;
}

const ExampleMessage: React.FC<ExampleMessageProps> = ({ children }) => {
  return (
    <div className='w-10/12 bg-light mt-[10px] px-[10px] py-[10px] text-left rounded-md'>
      {children}
    </div>
  );
};

export const SubscriptionsPreview: React.FC = () => {
  const { expertSubscriptions } = useSelector((state: AppStateType) => state.user);
  const subscriptions = useMemo(() => extendPlans(expertSubscriptions), [expertSubscriptions]);

  const { t } = useTranslation();

  return (
    <PreviewMobileWrapper>
      <div className='flex items-end gap-[8px] absolute -top-8 left-[15%] md:left-[50%] w-[244px]'>
        <img src={SubscriptionsText} />
      </div>
      <div className='overflow-scroll break-words'>
        <ExampleMessage>{t('onboarding.subscriptions.preview.greetings')}</ExampleMessage>
        {subscriptions.every(s => !s.isEnabled) && <MockedOptions subscriptions={subscriptions} />}
        {subscriptions.map(
          ({ description, price, title, isEnabled }, idx) =>
            isEnabled && (
              <ExampleMessage key={idx}>
                <span className='font-bold'>
                  {t(title)} - {price || 'XX'} USD
                </span>
                <br />
                <span>{description || t('onboarding.subscriptions.preview.description')}</span>
              </ExampleMessage>
            ),
        )}
        <ExampleMessage>{t('onboarding.subscriptions.preview.select')}</ExampleMessage>
      </div>
      <div className='flex flex-col justify-end grow'>
        <div className='h-[1px] w-full bg-secondary mt-[10px]'></div>
        <div>
          {subscriptions.map(({ title, isEnabled }, idx) =>
            isEnabled ? (
              <Button
                key={idx}
                variant='secondary'
                className='w-full border-[1px] mt-[10px] py-[5px]'
              >
                {t(title)}
              </Button>
            ) : null,
          )}
        </div>
      </div>
    </PreviewMobileWrapper>
  );
};

const MockedOptions = ({ subscriptions }: { subscriptions: ExpertSubscription[] }) => {
  const { t } = useTranslation();

  return (
    <>
      {subscriptions.map(({ description, price, type, isEnabled }, idx) => (
        <ExampleMessage key={idx}>
          <span className='font-bold text-grey-500'>
            {subscriptionTypeToString[type]} - {price || 'XX'} USD
          </span>
          <br />
          <span className=' text-grey-500'>
            {description || t('onboarding.subscriptions.preview.description')}
          </span>
        </ExampleMessage>
      ))}
    </>
  );
};
