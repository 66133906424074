import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { BackToChatButton } from 'src/components/BackToChatButton';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const redirectUrl = searchParams.get('redirectUrl');

  return (
    <div className='min-h-screen w-screen flex items-center justify-center flex-col gap-8 bg-light'>
      <h1 className='text-grey-900 text-3xl'>{t('paymentStatus.success')}</h1>
      {redirectUrl && <BackToChatButton redirectUrl={redirectUrl} />}
    </div>
  );
};

export default PaymentSuccess;
