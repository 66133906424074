import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { downloadFile } from './files.actions';

interface InitialState {
  loading: boolean;
  buffer: any;
  error: string | null;
  success: boolean;
}

const initialState: InitialState = {
  loading: false,
  buffer: null,
  error: null,
  success: false,
};

export const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    updateBuffer: (state, action: PayloadAction<any>) => {
      state.buffer = action.payload
    },
  },
  extraReducers: builder => {
    // --------------- DOWNLOAD --------------
    builder.addCase(downloadFile.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(downloadFile.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.buffer = action.payload
    });
    builder.addCase(downloadFile.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Request failed...';
    });
  },
});

export const { updateBuffer } = fileSlice.actions;
export default fileSlice.reducer;
