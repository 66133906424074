import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { askAi, getHistory, updateAvatars } from './onboarding.actions';

export interface MessageInterface {
  role: 'user' | 'assistant';
  message: string;
  renderKey: string | null;
  senseKey: string | null;
  step: number;
  link?: string;
}

export interface ExpertiseInterface {
  expertiseId: number;
  value: string;
  translation?: string | null;
}

interface InitialState {
  loading: boolean;
  error: string | null;
  success: boolean;
  messages: MessageInterface[];
  expertises: ExpertiseInterface[];
  step: number;
  lastStep: number;
  previewMode: boolean;
}

const initialState: InitialState = {
  step: 0,
  lastStep: 6,
  loading: false,
  error: null,
  success: false,
  messages: [],
  expertises: [],
  previewMode: false,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updateLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    nextStep: (state, action: PayloadAction<number>) => {
      if (state.step !== state.lastStep) {
        const step = state.step + action.payload;
        state.step = step;
      }
    },
    previousStep: (state, action: PayloadAction<number>) => {
      if (state.step !== 0) {
        const step = state.step - action.payload;
        state.step = step;
      }
    },
    addMessage: (state, action: PayloadAction<MessageInterface[]>): InitialState => {
      if ((action.payload.length && action.payload[0].message !== '') || action.payload[0].link) {
        return {
          ...state,
          messages: [...state.messages, ...action.payload],
        };
      }
      return state;
    },
    setHistory: (state, action: PayloadAction<MessageInterface[]>) => {
      return {
        ...state,
        messages: [...action.payload],
      };
    },
    updateStatus: (state, action: PayloadAction<Partial<InitialState>>): InitialState => {
      if (action.payload.loading) {
        return {
          ...state,
          loading: action.payload.loading,
        };
      }

      if (action.payload.error) {
        return {
          ...state,
          error: action.payload.error,
        };
      }

      return state;
    },
    setPreviewMode: (state, action: PayloadAction<boolean>) => {
      state.previewMode = action.payload;
    },
  },
  extraReducers: builder => {
    // ------------------- ASK ASSISTANT -------------------

    builder.addCase(askAi.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(askAi.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      if (action.payload) {
        state.messages = [...state.messages, ...action.payload?.messages];
      }
    });
    builder.addCase(askAi.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Request failed...';
    });

    // ------------------- GET HISTORY ----------------------

    builder.addCase(getHistory.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      if (action.payload) {
        state.messages = action.payload?.messages;
      }
    });
    builder.addCase(getHistory.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Request failed...';
    });

    // ----------------- UPDATE AVATARS ----------------------

    builder.addCase(updateAvatars.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateAvatars.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      if (action.payload) {
        state.messages = [...state.messages, action.payload?.messages];
      }
    });
    builder.addCase(updateAvatars.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Request failed...';
    });
  },
});

export const {
  nextStep,
  previousStep,
  addMessage,
  setHistory,
  setStep,
  setPreviewMode,
  updateLoadingStatus,
} = onboardingSlice.actions;
export const selectStep = (state: InitialState) => state.step;

export default onboardingSlice.reducer;
