import React from 'react';
import { Trans } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';

const AvatarPlaceholder = () => {
  return (
    <div className='flex flex-col gap-[10px] items-center justify-center h-full cursor-pointer'>
      <FaUserCircle className='w-[55px] h-[55px] text-grey-300' />
      <Trans
        i18nKey={'onboarding.branding.avatarPhoto'}
        components={{
          1: <div />,
          2: <span className='text-[14px] leading-[22px] text-grey-900' />,
          3: <div />,
          4: (
            <span className='block text-[12px] leading-[18px] text-center text-grey-400 w-[109px]' />
          ),
        }}
      />
    </div>
  );
};

export { AvatarPlaceholder };
