import { fetchBuilder } from 'src/services/basicFetch';
import { MethodEnum } from '../enums';
import { ChatStatus, FiltersInterface } from '../interfaces';
import { TransactionStatusEnum } from '../interfaces/transaction-status.interface';

export interface TransactionsDataInterface {
  statistic: {
    amount: number;
    consultation: number;
    pack: 0;
    single: number;
    payments: number;
  };
  data: {
    [key: string]: {
      id: string;
      avatar: string | null;
      chatId: string;
      price: number;
      subscriptionType: string;
      username: string;
      status: TransactionStatusEnum;
      chatStatus: ChatStatus;
      isCommon: boolean | null;
    }[];
  };
}

export const getTransactions = async (
  filters?: FiltersInterface,
  page?: number,
): Promise<TransactionsDataInterface> => {
  return await fetchBuilder({ ...filters }, `transactions?page=${page}`, MethodEnum.POST);
};

export const generateInvoice = async (id: string): Promise<{ url: string }> => {
  return await fetchBuilder({ id }, `pdf-generator/transaction`, MethodEnum.POST);
};

export const getQuickbooksInvoice = async (filters?: FiltersInterface): Promise<Blob> => {
  return await fetchBuilder(
    { ...filters },
    'transactions/quickbooks',
    MethodEnum.POST,
    undefined,
    undefined,
    true,
  );
};

export const getTransactionsForDashboard = async (dateRange: string | null): Promise<any> => {
  return await fetchBuilder({}, `transactions/statistic?date=${dateRange}`, MethodEnum.GET);
};
