import React, { RefObject, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/store/user/user.slice';
import { TextInput } from 'src/ui/TextInput/TextInput';
import { Switch } from 'src/ui/Switch/Switch';
import { FaGoogle, FaLinkedinIn, FaTrashAlt, FaUser } from 'react-icons/fa';
import { Card } from 'src/ui/Card/Card';
import { useTranslation } from 'react-i18next';

const EmailAndSocialsCard = () => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  if (!user) return <></>;

  return (
    <Card className='rounded-[12px]'>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col gap-4'>
          <label className='text-[#181C32] font-semibold'>Email</label>
          <div className='flex w-full'>
            <TextInput
              placeholder='Email'
              className='w-full h-[50px] grow hover:shadow-none'
              type='input'
              value={user.email}
              disabled
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export { EmailAndSocialsCard };
