import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import './Switch.css';
import { cn } from 'src/common/utils';

export interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  labelClassName?: string;
}

export const Switch: React.FC<SwitchProps> = ({
  isChecked,
  onChange,
  className,
  labelClassName,
  ...props
}) => {
  const id = uuidv4();

  return (
    <>
      <input
        type='checkbox'
        name='switch'
        id={id}
        checked={isChecked}
        onChange={onChange}
        className={cn('switch hidden', className)}
        {...props}
      />
      <label className={cn('switchLabel', { 'opacity-60': props.disabled }, labelClassName)} htmlFor={id}></label>
    </>
  );
};
