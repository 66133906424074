/* eslint-disable react/prop-types */

import React from 'react';
import { cn } from 'src/common/utils';

function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={cn('animate-pulse rounded-md bg-light', className)} {...props} />;
}

export { Skeleton };
