import * as z from 'zod';

enum AIChatPromptType {
  RESPONSE = 'response',
  SUMMARIZE = 'summarize',
  FIND_INFO = 'find info',
}

const aiMessageSchema = z.object({
  message: z.string().max(1000, 'Message to helper has to be at most 1000 characters long'),
  promptType: z.nativeEnum(AIChatPromptType).optional(),
});

export { aiMessageSchema, AIChatPromptType };
