import React, { useMemo } from 'react';
import { DashboardCard, DashboardCardStat } from './DashboardCard';
import { ROUTES } from 'src/router/routes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useChatStore } from 'src/store/chat/chatStore';

export const ChatCard = () => {
  const navigate = useNavigate();
  const { inboxCount, requestsCount, activeChats, requestedChats, closedChats } = useChatStore();
  const { t } = useTranslation();

  const stats: DashboardCardStat[] = useMemo(
    () => [
      {
        amount: inboxCount,
        text: t('dashboard.chats.newMessage'),
        link:
          inboxCount
            ? `${ROUTES.CHATS}/${activeChats[0].id}?chatStatus=active`
            : undefined,
      },
      {
        amount: requestsCount,
        text: t('dashboard.chats.requests'),
        link:
          requestedChats && requestedChats.length
            ? `${ROUTES.CHATS}/${requestedChats[0].id}?chatStatus=requested`
            : undefined,
      },
      {
        amount: activeChats.length,
        text: t('dashboard.chats.openSessions'),
      },
    ],
    [inboxCount, requestsCount, activeChats],
  );

  const params = useMemo(() => {
    return {
      btnText: t('dashboard.chats.buttonText'),
      onClick: () => navigate(`../${ROUTES.CHATS}`),
      stats,
      title: t('dashboard.chats.title'),
    };
  }, [stats]);

  //@ts-ignore
  return params.stats ? <DashboardCard {...params} /> : null;
};
