import { useEffect } from 'react';
import { create } from 'zustand';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: ReadonlyArray<string>;
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
}

const useInstallPromptStore = create<{
  promptEvent: BeforeInstallPromptEvent | null;
  setPromptEvent: (e: BeforeInstallPromptEvent) => void;
}>(set => ({
  promptEvent: null,
  setPromptEvent: e => set(state => ({ promptEvent: e })),
}));

const useInstallPromptDetect = () => {
  const { setPromptEvent } = useInstallPromptStore();

  useEffect(() => {
    const listener = (e: Event) => {
      e.preventDefault();
      setPromptEvent(e as BeforeInstallPromptEvent);
    };

    window.addEventListener('beforeinstallprompt', listener);

    return () => {
      window.removeEventListener('beforeinstallprompt', listener);
    };
  }, []);
};

export { useInstallPromptDetect, useInstallPromptStore };
