export const ROUTES = {
  ONBOARDING: 'onboarding',
  LOGIN: 'login',
  VERIFY: 'verify-otp',
  DASHBOARD: 'dashboard',
  SETTINGS: 'settings',
  TRANSACTIONS: 'transactions',
  PAYMENTS: 'payments',
  PROFILE: 'profile',
  CHATS: 'messages',
  LINKS: 'my-links',
  BUSINESS_CARD: 'business-card',
  SUBSCRIPTIONS: 'subscriptions',
  OFFER: 'offer',
  EXPERT_CARD: 'link',
  PAYMENT_SUCCESS: 'payment-success',
  PAYMENT_DENIED: 'payment-denied',
  AI_HELPER_SETTINGS: 'ai-helper-settings',
  AI_CHAT: 'ai-chat',
  WHATSAPP_SETTINGS: 'whatsapp',
  MESSENGER_SETTINGS: 'messenger',
  INSTAGRAM_SETTINGS: 'instagram',
  TELEGRAM_SETTINSG: 'telegram',
  TUTORIAL: 'tutorial',
  INSTAGRAM_TUTORIAL: 'instagram',
  WHATSAPP_TUTORIAL: 'whatsapp',
  MESSENGER_TUTORIAL: 'messenger',
  NOTIFICATIONS: 'notifications',
  COMMUNICATION_CHANNELS: 'communication-channels',
};
