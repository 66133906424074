import React from 'react';
import { Button, ButtonProps } from '../Button/Button';
import { FaMoneyBill, FaSpinner } from 'react-icons/fa6';

type ChatPaymentButtonProps = ButtonProps & {
  isLoading?: boolean;
};

const ChatPaymentButton = ({ isLoading, ...props }: ChatPaymentButtonProps) => {
  return (
    <Button variant={'muted'} {...props}>
      {isLoading ? (
        <FaSpinner className='animate-spin w-6 h-6 text-primary mr-2' />
      ) : (
        <FaMoneyBill />
      )}
      Send new payment link
    </Button>
  );
};

export { ChatPaymentButton };
