/* eslint-disable react/prop-types */

import * as React from "react"
import { FaChevronLeft, FaChevronRight} from "react-icons/fa"
import { DayPicker } from "react-day-picker"
import { cn } from "src/common/utils"
import { buttonVariants } from "./CalendarButton"
 
export type CalendarProps = React.ComponentProps<typeof DayPicker>
 
function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-grey-100 p-0 hover:opacity-100 rounded-none border-none'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'text-grey-500 font-semibold rounded-md w-8 text-[0.8rem]',
        row: 'flex w-full mt-2 rounded-lg overflow-hidden',
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-8 w-8 p-0 font-normal aria-selected:opacity-100'
        ),
        day_selected:
          'bg-primary hover:bg-primary focus:bg-primary text-white rounded-md',
        day_today: 'bg-accent text-accent-foreground border-primary-light border-[1px]',
        day_outside: 'text-grey-400 aria-selected:bg-transparent aria-selected:text-grey-400',
        day_disabled: 'text-muted opacity-40',
        day_range_middle:
          'aria-selected:bg-primary-light aria-selected:text-primary rounded-none',
        day_hidden: 'hidden',
        day_range_start: 'rounded-l-md rounded-r-none',
        day_range_end: 'rounded-r-md rounded-l-none',
        ...classNames
      }}
      components={{
        IconLeft: ({ ...props }) => <FaChevronLeft className="h-3 w-3 text-grey-500" />,
        IconRight: ({ ...props }) => <FaChevronRight className="h-3 w-3 text-grey-500" />
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"
 
export { Calendar }