import { useMemo } from 'react';
import { useSubscriptions } from './useSubscriptions';
import { useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { useTranslation } from 'react-i18next';
import { useOnboardingStore } from 'src/store/onboarding/onboarding.store';
import { OnboardingSteps } from '../enums/onboardingSteps.enum';
import { MessengerType } from '../interfaces';

type OnboardingStepStatus = {
  title: string;
  completed: boolean;
  targetStep: number;
};

type OnboardingStatus = Record<string, OnboardingStepStatus>;

export const useOnboardingStatus = () => {
  const { user, expertSubscriptions } = useSelector((state: AppStateType) => state.user);
  const { t } = useTranslation();
  const { subscriptions } = useSubscriptions();

  const {
    backToOnboarding: { mutate: handleBackToOnboarding },
  } = useOnboardingStore();

  const messengerStatus = useMemo(() => {
    return [
      !!user?.expert?.settings?.whatsappSettings?.phone,
      !!user?.expert?.settings?.messengerSettings?.pageId,
      !!user?.expert?.settings?.instagramSettings?.pageId,
      !!user?.expert?.settings?.telegramSettings?.botToken,
    ];
  }, [user?.expert?.settings]);

  const status: OnboardingStatus = useMemo(() => {
    return {
      nameStatus: {
        title: t('common.onboardingStatus.desktop.nameStatus'),
        completed: !!(user?.firstName && user.lastName),
        targetStep: OnboardingSteps.ABOUT_YOU,
      },
      descriptionStatus: {
        title: t('common.onboardingStatus.desktop.descriptionStatus'),
        completed: !!user?.expert?.description,
        targetStep: OnboardingSteps.ABOUT_YOU,
      },
      photoStatus: {
        title: t('common.onboardingStatus.desktop.photoStatus'),
        completed: !!user?.avatarUrl,
        targetStep: OnboardingSteps.BRANDING,
      },
      messengerStatus: {
        title: t('common.onboardingStatus.desktop.messengerStatus'),
        completed: messengerStatus.some(val => val),
        targetStep: OnboardingSteps.CHANNELS,
      },
      stripeStatus: {
        title: t('common.onboardingStatus.desktop.stripeStatus'),
        completed:
          (!!user?.expert?.paymentSettings?.stripeId &&
            !!user?.expert?.paymentSettings?.bankToken) ||
          !!user?.expert?.paymentSettings?.useCommonAccount,
        targetStep: OnboardingSteps.PAYMENTS,
      },
      consultationStatus: {
        title: t('common.onboardingStatus.desktop.consultationStatus'),
        completed: !!subscriptions.find(s => s.isEnabled && s.price),
        targetStep: OnboardingSteps.SUBSCRIPTIONS,
      },
      offerStatus: {
        title: t('common.onboardingStatus.desktop.offerStatus'),
        completed: !!user?.expert?.offer?.url,
        targetStep: OnboardingSteps.OFFER,
      },
    };
  }, [user, expertSubscriptions, messengerStatus]);

  const getLastEmptyStep = () => {
    if (!status.nameStatus.completed || !status.descriptionStatus.completed) {
      return status.nameStatus.targetStep;
    }
    if (!status.photoStatus.completed) {
      return status.photoStatus.targetStep;
    }
    if (!status.consultationStatus.completed) {
      return status.consultationStatus.targetStep;
    }
    if (!status.stripeStatus.completed) {
      return status.stripeStatus.targetStep;
    }
    if (!status.messengerStatus.completed) {
      return status.messengerStatus.targetStep;
    }
    if (!status.offerStatus.completed) {
      return status.offerStatus.targetStep;
    }
  };

  const { isCompleted, completedAmount, lastEmptyStep } = useMemo(
    () => ({
      isCompleted: !Object.values(status).some(s => s.completed === false),
      completedAmount: Object.values(status).filter(s => s.completed).length,
      lastEmptyStep: getLastEmptyStep() || 0,
    }),
    [status],
  );

  return {
    status,
    t,
    handleBackToOnboarding,
    isCompleted,
    completedAmount,
    messengerStatus,
    subscriptions,
    lastEmptyStep,
  };
};
