import React from 'react';
import { Helmet } from 'react-helmet-async';
import favicon from '../icons/app.png';

type SeoProps = {
  title?: string;
  description?: string;
  name?: string;
  type?: string;
};

export default function SEO({ title, description, name, type }: SeoProps) {
  return (
    <Helmet>
      <link rel='icon' href={favicon} sizes='64x64' />
      <title>{title}</title>
      <meta name='description' content={description} />
      {/* Facebook tags */}
      <meta property='og:type' content={type} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name='twitter:creator' content={name} />
      <meta name='twitter:card' content={type} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      {/* End Twitter tags */}
    </Helmet>
  );
}

export type { SeoProps };
