import React, { ReactNode } from 'react';
import dayjs from 'dayjs';
import { cn } from 'src/common/utils';

type DatePlaceholderProps = {
  date: string | number | Date;
  className?: string;
  template?: string;
  variant?: 'default' | 'date-no-today';
};

const DatePlaceholder = ({
  date,
  className,
  template,
  variant = 'default',
}: DatePlaceholderProps) => {
  if (variant === 'date-no-today') {
    const currentDate = dayjs();
    const inputDate = dayjs(date);

    let formattedDate = '';

    if (variant === 'date-no-today') {
      if (currentDate.isSame(inputDate, 'day')) {
        formattedDate = inputDate.format('HH:mm');
      } else if (currentDate.subtract(1, 'day').isSame(inputDate, 'day')) {
        formattedDate = `Yesterday, ${inputDate.format(template || 'h:mm A')}`;
      } else {
        formattedDate = inputDate.format(template || 'MMM D, h:mm A');
      }
    }

    return (
      <span className={cn('flex w-full justify-end text-muted', className)}>{formattedDate}</span>
    );
  }

  return (
    <span className={cn('flex w-full justify-end text-muted text-[14px]', className)}>
      {dayjs(new Date(date)).format(template || 'h:mm A')}
    </span>
  );
};

export { DatePlaceholder, type DatePlaceholderProps };
