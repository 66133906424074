import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@radix-ui/react-alert-dialog';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from 'src/ui/AlertDialog/AlertDialog';
import { Button } from 'src/ui/Button/Button';
import { FaStripe } from 'react-icons/fa';
import { useAiAssistantStore } from 'src/store/onboarding/ai-assistant.store';
import { OnboardingSteps } from 'src/common/enums/onboardingSteps.enum';

interface AiStripeConnectProps {
}

export const AiStripeConnect: React.FC<AiStripeConnectProps> = () => {
  const { t } = useTranslation();
  const { rerunStep, connectStripeViaAi, user } = useAiAssistantStore();
  const { mutate: rerun } = rerunStep;
  const { mutate: connectStripe } = connectStripeViaAi;

  return (
    <div className='flex justify-center'>
      <AlertDialog>
        <AlertDialogTrigger className='w-full' asChild>
          <Button
            disabled={!!user?.expert?.paymentSettings?.stripeId}
            variant='primary'
            className='w-fit border-primary mt-[16px]'
          >
            Connect stripe
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent className='max-w-[800px] text-grey-900 sm:py-20 sm:rounded-xl'>
          <AlertDialogHeader className='sm:text-center'>
            <div className='flex justify-center'>
              <FaStripe className='text-[64px] text-center text-[#635BFF]' />
            </div>
            <AlertDialogDescription className='text-[14px]'>
              <Trans
                i18nKey={'onboarding.payments.agreement'}
                components={{
                  1: <a href='https://stripe.com/legal/ssa' className='text-primary' />,
                }}
              />
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className='flex-col sm:justify-center gap-2 sm:mt-6'>
            <AlertDialogAction asChild className='font-semibold'>
              <Button variant={'primary'} onClick={() => connectStripe()}>
                {t('onboarding.payments.ai.agree')}
              </Button>
            </AlertDialogAction>
            <AlertDialogAction asChild className='font-semibold'>
              <Button
                variant={'outlined'}
                className='border-primary text-primary'
                onClick={() =>
                  rerun({
                    step: OnboardingSteps.PAYMENTS,
                  })
                }
              >
                {t('onboarding.payments.ai.rerun')}
              </Button>
            </AlertDialogAction>
            <AlertDialogCancel asChild className='font-semibold'>
              <Button variant={'danger'}>{t('onboarding.payments.ai.cancel')}</Button>
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
