import React from 'react';
import { Button } from '../../ui/Button/Button';
import { FaArrowRight } from 'react-icons/fa';
import { Card } from 'src/ui/Card/Card';
import { useNavigate } from 'react-router-dom';
import { cn } from 'src/common/utils';
import { DropDown } from 'src/ui/DropDownRadix/DropDown';
import { datePresets } from 'src/common/data';
import { v4 as uuidv4 } from 'uuid';

export interface DashboardCardStat {
  text: string;
  amount: number | string;
  link?: string;
}

export interface DashboardCardProps {
  title: string;
  stats: DashboardCardStat[];
  btnText: string;
  onClick: () => void;
  showDatePicker?: boolean;
  dateRange?: string | null;
  setDateRange?: React.Dispatch<React.SetStateAction<string | null>>;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  btnText,
  onClick,
  stats,
  title,
  showDatePicker,
  dateRange,
  setDateRange,
}) => {
  const navigate = useNavigate();
  const id = uuidv4();

  return (
    <Card
      variant='default'
      className='grow px-[20px] md:px-[30px] pt-[22px] pb-[17px] rounded-[12px]'
    >
      <div
        className={cn('flex justify-between items-baseline w-full', {
          '-mt-[5px]': showDatePicker,
        })}
      >
        <h3 className='text-[18px] font-[700] p-0 m-0 grow w-fit block'>{title}</h3>
        {showDatePicker ? (
          <div className='sm:w-[150px] lg:w-[130px] lg:w-[150px]'>
            <DropDown
              key={id}
              placeholder='Date range'
              className='bg-transparent justify-end gap-[5px]'
              contentClassName='text-[14px]'
              value={dateRange || 'last30'}
              onValueChange={v => setDateRange?.(v)}
              name={`${id}`}
              options={datePresets}
              resetClassName='top-[10px] right-8'
            />
          </div>
        ) : null}
      </div>
      <div
        className={cn('flex flex-col gap-[15px]', {
          'mt-[26px]': !showDatePicker,
          'mt-[18px]': showDatePicker,
        })}
      >
        {stats.map(({ amount, text, link }, idx) => (
          <Card
            variant='dashed'
            className={cn(
              'flex justify-between items-center px-[20px] py-[15px] border-[#D8D8E5]',
              {
                'cursor-pointer': link,
              },
            )}
            key={idx}
            onClick={
              link
                ? () => {
                    navigate(`../${link}`);
                  }
                : undefined
            }
          >
            <div
              className={cn('uppercase text-[11px] tracking-widest', {
                'text-primary': link,
                'text-grey-500': !link,
              })}
            >
              {text}
            </div>
            <div className='font-semibold'>{amount}</div>
          </Card>
        ))}
      </div>
      <div className='mt-[16px] mx-auto w-fit text-primary text-[14px]'>
        <Button onClick={onClick}>
          {btnText}
          <FaArrowRight />
        </Button>
      </div>
    </Card>
  );
};
