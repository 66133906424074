import dayjs from 'dayjs';
import React from 'react';
import { ChatDateIndicator } from './common/ChatDateIndicator';

function MessagesWithDateIndicator<T extends { id: React.Key; createdAt: string | Date }, P>({
  messages,
  chatCreatedAt,
  MessageComponent,
  props,
}: {
  messages: T[];
  props: P;
  chatCreatedAt: string;
  MessageComponent: React.ComponentType<T & P>;
}) {
  return (
    <>
      {[...messages].reverse().map((message, idx, arr) => {
        const prevMessage = arr[idx + 1];
        const currentDate = new Date(message?.createdAt as string);
        const prevDate = new Date(prevMessage?.createdAt as string);

        if (!prevMessage && currentDate.getDate() !== new Date(chatCreatedAt).getDate()) {
          return (
            <React.Fragment key={message.id}>
              <MessageComponent {...message} {...props} />
              <ChatDateIndicator date={currentDate} />
            </React.Fragment>
          );
        } else if (prevMessage && currentDate.getDate() !== prevDate.getDate()) {
          return (
            <React.Fragment key={message.id}>
              <MessageComponent {...message} {...props} />
              <ChatDateIndicator date={currentDate} />
            </React.Fragment>
          );
        }

        return <MessageComponent {...message} key={message.id} {...props} />;
      })}
    </>
  );
}

export { MessagesWithDateIndicator };
