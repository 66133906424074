import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PdfIcon } from 'src/common/assets';
import { DragAndDrop } from 'src/ui/DragAndDrop/DragAndDrop';
import TabSwitcher from 'src/ui/TabSwitch/TabSwitch';
import { TextBar } from 'src/ui/TextBar/TextBar';
import { FieldError } from './FieldError';
import { useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { usePublicContentStore } from 'src/store/public-content/usePublicContentStore';
import Markdown from 'src/components/Markdown/Markdown';

interface OfferTabsProps {
  onOfferChange: (file: File | null) => void;
  offer: File | null;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  setIsTemplateTab?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OfferTabs: React.FC<OfferTabsProps> = ({
  error,
  offer,
  onOfferChange,
  setError,
  setIsTemplateTab,
}) => {
  const { t } = useTranslation();

  const { data: publicContent } = usePublicContentStore();

  const { user } = useSelector((state: AppStateType) => state.user);

  const userName = useMemo(() => `${user?.firstName || ''} ${user?.lastName || ''}`, [user]);

  const [tab, setTab] = useState<string | number>(0);

  const markdownContent = (publicContent?.offerTemplate ?? '').replace(
    '%USERNAME%',
    userName ?? '',
  );

  const onTabChange = (value: string | number) => {
    setTab(value);
    setIsTemplateTab?.(value == 0);
  };

  return (
    <TabSwitcher
      value={tab}
      onChange={onTabChange}
      tabs={[
        {
          label: t('onboarding.offer.tabs.template'),
          content: (
            <TextBar>
              <Markdown>{markdownContent}</Markdown>
            </TextBar>
          ),
          value: 0,
          className: 'text-[14px]',
        },
        {
          label: t('onboarding.offer.tabs.doc.label'),
          content: (
            <>
              <DragAndDrop
                onFileChange={onOfferChange}
                file={offer}
                setError={setError}
                validExtesnions={['.pdf']}
              >
                <div
                  className='
                                flex flex-col gap-[10px] items-center justify-center 
                                bg-light rounded-md min-h-[190px]
                            '
                >
                  <div className='w-[55px] h-[55px]'>
                    <PdfIcon />
                  </div>
                  <div className='text-center'>
                    <p className='secondary text-grey-900'>{t('common.files.uploadPdf')}</p>
                    <p className='subsecondary text-grey-500'>{t('common.files.dragAndDrop')}</p>
                  </div>
                </div>
              </DragAndDrop>
              {error ? <FieldError error={error} /> : null}
            </>
          ),
          value: 1,
          className: 'text-[14px]',
        },
      ]}
    />
  );
};
