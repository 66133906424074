import React from 'react';
import { FaWhatsapp, FaFacebookMessenger, FaInstagram, FaTelegramPlane } from 'react-icons/fa';
import { MessengerType } from 'src/common/interfaces';
import { cn } from 'src/common/utils';

interface MessengerIconProps extends React.HTMLAttributes<HTMLDivElement> {
  messengerType: MessengerType;
}

const MessengerIcon = ({
  messengerType,
  className,
  ...props
}: MessengerIconProps) => {
  switch (messengerType) {
    case MessengerType.WHATSAPP: {
      return <FaWhatsapp className={cn('w-4 h-4 text-whatsapp', className)} />;
    }
    case MessengerType.MESSENGER: {
      return <FaFacebookMessenger className={cn('w-4 h-4 text-messenger', className)} />;
    }
    case MessengerType.INSTAGRAM: {
      return <FaInstagram className={cn('w-4 h-4 text-instagram', className)} />;
    }
    case MessengerType.TELEGRAM: {
      return <FaTelegramPlane className={cn('w-4 h-4 text-telegram', className)} />;
    }
  }
};

export { MessengerIcon };
