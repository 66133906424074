import * as z from 'zod';

const thematicTemplateConfigShema = z.object({
  thematicTemplate: z.object(
    {
      thematicTemplateId: z.number(),
      translation: z.string().optional(),
      color: z.string().optional(),
      logoUrl: z.string().nullable().optional(),
    },
    {
      errorMap: () => ({
        message: 'Thematic template is required',
      }),
    },
  ),
  whiteBackground: z.boolean(),
  color: z.string().nullable(),
});

export { thematicTemplateConfigShema };
