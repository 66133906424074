import React from 'react';
import { Logo as LogoImage } from 'src/common/assets';

export const Logo = () => {
  return (
    <a href='https://anamata.io'>
      <div className='w-[120px] h-[60px]'>
        <LogoImage />
      </div>
    </a>
  );
};
