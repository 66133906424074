import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './user/user.slice';
import onboardingReducer from './onboarding/onboarding.slice';
import fileReducer from './files/files.slice';

const rootReducer = combineReducers({
  user: userReducer,
  onboarding: onboardingReducer,
  file: fileReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppStateType = ReturnType<typeof rootReducer>;
export default store;
