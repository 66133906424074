import { cn } from 'src/common/utils';
import React from 'react';

interface TutorialWrapperProps {
  className?: string;
  children?: React.ReactNode;
}

export const TutorialWrapper: React.FC<TutorialWrapperProps> = ({ children, className }) => {
  return (
    <div className={cn('w-full px-0 xl:px-20 pb-12 z-20 mx-auto h-full text-left', className)}>
      {children}
    </div>
  );
};
