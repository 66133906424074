import React, { ReactNode, useState } from 'react';
import { IconType } from 'react-icons';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'src/common/utils/cn';
import { Slot } from '@radix-ui/react-slot';

export const buttonVariants = cva(
  'flex items-center justify-center gap-[10px] text-[14px] leading-6 rounded-md hover:cursor font-medium',
  {
    variants: {
      variant: {
        default: '',
        primary_light: 'bg-[#009EF71A] text-primary py-[13px] px-[35px]',
        primary: 'bg-blue text-white py-[13px] px-[35px]',
        secondary: 'bg-white text-blue py-[16px] px-[32px]',
        category:
          'bg-white border-[1px] border-solid border-grey-300 py-[5px] px-[18px] font-semibold',
        outlined: 'bg-white border-[1px] border-solid border-grey-300 py-[13px] px-[18px]',
        muted:
          'bg-light py-[13px] px-[18px] text-grey-600 hover:text-primary hover:bg-[#009EF7] hover:bg-opacity-10',
        danger: 'bg-danger text-white py-[13px] px-[35px]',
        warning: 'bg-warning text-white py-[13px] px-[35px]',
        info: 'bg-info text-white py-[13px] px-[35px]',
        dark: 'bg-dark text-white py-[13px] px-[35px] hover:bg-dark-light hover:text-white',
        success: 'bg-success text-white py-[13px] px-[35px]',
        'info-light': 'bg-info-light text-info py-[13px] px-[35px]',
        'info-outline': 'border border-info-light text-info py-[13px] px-[35px]',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, disabled, className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        disabled={disabled}
        className={cn(
          buttonVariants({
            variant,
          }),
          { 'hover:opacity-90': !disabled, 'opacity-50 cursor-not-allowed': disabled },
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = 'Button';
