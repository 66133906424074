import React from 'react';
import { SpecialComponents } from 'react-markdown/lib/ast-to-react';
import { NormalComponents } from 'react-markdown/lib/complex-types';

const whatsappMarkdownSettings: Partial<
  Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
> = {
  em(props) {
    const { node, ...rest } = props;
    return <b {...rest} />;
  },
  ol(props) {
    const { node, ...rest } = props;
    return <ol className='list-decimal ml-4 whitespace-normal' {...rest} />;
  },
  code(props) {
    const { node, ...rest } = props;
    return <code className='whitespace-normal break-words' {...rest} />;
  },
};

export { whatsappMarkdownSettings };
