import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExpertSubscription,
  UserInterface,
  selectUser,
  updateSubscriptionsLocaly,
  updateUserLocaly,
} from '../user/user.slice';
import { addMessage, setHistory, setStep, updateLoadingStatus } from './onboarding.slice';
import {
  askAi,
  getHistory,
  updateAvatarsViaAi,
  updateExpertise,
  updateSubscriptionsAi,
  updateThematicTemplateViaAssistant,
  updatePaymentSettingsAi,
  connectStripeAi,
  rerunStepAi,
} from 'src/common/api/ai';
import { AppStateType } from '..';
import { ThematicTemplateConfig } from 'src/common/interfaces/thematicTemplate';
import { ReducedPaymentSettingsInterface } from 'src/common/api/payments';

const useAiAssistantStore = () => {
  const user = useSelector(selectUser);
  const { step } = useSelector((state: AppStateType) => state.onboarding);
  const dispatch = useDispatch();

  const loadAiChatHistory = useQuery(['ai-history'], () => getHistory(), {
    onSuccess: data => {
      dispatch(setHistory(data));
    },
  });

  const rerunStep = useMutation({
    mutationFn: (data: { step: number }) => rerunStepAi(data),
    onMutate: () => {
      dispatch(updateLoadingStatus(true));
    },
    onSuccess: data => {
      dispatch(updateLoadingStatus(false));
      const { messages, user } = data;
      dispatch(addMessage(messages));

      if (user) {
        dispatch(updateUserLocaly(user));
      }
      if (step) {
        dispatch(setStep(step));
      }
    },
  });

  const askQuestion = useMutation({
    mutationFn: (data: { step: number; message: string; renderKey: null | string }) => askAi(data),
    onMutate: () => {
      dispatch(updateLoadingStatus(true));
    },
    onSuccess: data => {
      const { messages, step, user } = data;
      dispatch(updateLoadingStatus(false));
      dispatch(addMessage(messages));

      if (user) {
        dispatch(updateUserLocaly(user));
      }
      if (step) {
        dispatch(setStep(step));
      }
    },
  });

  const updateAvatars = useMutation({
    mutationFn: (
      toUpload: {
        file: File | null | 'DELETED';
        key: string;
      }[],
    ) => updateAvatarsViaAi(toUpload),
    onMutate: () => {
      dispatch(updateLoadingStatus(true));
    },
    onSuccess: data => {
      const { messages, step, user } = data;
      dispatch(updateLoadingStatus(false));
      dispatch(addMessage(messages));

      if (user) {
        dispatch(updateUserLocaly(user));
      }
      if (step) {
        dispatch(setStep(step));
      }
    },
  });

  const updateExpertiseAi = useMutation({
    mutationFn: (data: { user: Partial<UserInterface>; step: number }) => updateExpertise(data),
    onMutate: () => {
      dispatch(updateLoadingStatus(true));
    },
    onSuccess: data => {
      const { messages, step, user } = data;
      dispatch(updateLoadingStatus(false));
      dispatch(addMessage(messages));

      if (user) {
        dispatch(updateUserLocaly(user));
      }
      if (step) {
        dispatch(setStep(step));
      }
    },
  });

  const updateTemplateViaAI = useMutation({
    mutationFn: async (thematicTemplateConfig: Partial<ThematicTemplateConfig>) => {
      return updateThematicTemplateViaAssistant({
        user: {
          id: user?.id!,
          expert: {
            userId: user?.id!,
            brandLogoUrl: user?.expert?.brandLogoUrl,
            thematicTemplateConfig: {
              ...thematicTemplateConfig,
              wasChangedByUser: true,
              wasIntentionallyChangedByUser: true,
            },
          },
        },
        step: step,
      });
    },
    onMutate: () => {
      dispatch(updateLoadingStatus(true));
    },
    onSuccess: data => {
      const { messages, step, user } = data;
      dispatch(updateLoadingStatus(false));
      dispatch(addMessage(messages));

      if (user) {
        dispatch(updateUserLocaly(user));
      }
      if (step) {
        dispatch(setStep(step));
      }
    },
  });

  const updateSubscriptionsViaAI = useMutation({
    mutationFn: async (data: ExpertSubscription[]) => {
      return updateSubscriptionsAi(data);
    },
    onMutate: () => {
      dispatch(updateLoadingStatus(true));
    },
    onSuccess: data => {
      const { messages, step, user, subscriptions } = data;
      dispatch(updateLoadingStatus(false));
      dispatch(addMessage(messages));
      if (user) {
        dispatch(updateUserLocaly(user));
      }
      if (step) {
        dispatch(setStep(step));
      }
      if (subscriptions) {
        dispatch(updateSubscriptionsLocaly(subscriptions));
      }
    },
  });

  const connectStripeViaAi = useMutation({
    mutationFn: async () => {
      return connectStripeAi();
    },
    onMutate: () => {
      dispatch(updateLoadingStatus(true));
    },
    onSuccess: data => {
      const { messages, step, user } = data;
      dispatch(updateLoadingStatus(false));
      dispatch(addMessage(messages));
      if (user) {
        dispatch(updateUserLocaly(user));
      }
      if (step) {
        dispatch(setStep(step));
      }
    },
  });

  const updatePaySettingsViaAi = useMutation({
    mutationFn: async (data: {
      paymentSettings: ReducedPaymentSettingsInterface;
      step: number;
      message: string;
    }) => {
      return updatePaymentSettingsAi(data);
    },
    onMutate: () => {
      dispatch(updateLoadingStatus(true));
    },
    onSuccess: data => {
      const { messages, step, user } = data;
      dispatch(updateLoadingStatus(false));
      dispatch(addMessage(messages));
      if (user) {
        dispatch(updateUserLocaly(user));
      }
      if (step) {
        dispatch(setStep(step));
      }
    },
  });

  return {
    step,
    loadAiChatHistory,
    askQuestion,
    updateAvatars,
    updateExpertiseAi,
    updateTemplateViaAI,
    updateSubscriptionsViaAI,
    updatePaySettingsViaAi,
    rerunStep,
    connectStripeViaAi,
    user,
  };
};

export { useAiAssistantStore };
