import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from 'src/ui/DropdownMenu/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { FaCog } from 'react-icons/fa';
import { ROUTES } from 'src/router/routes';
import { Link, useLocation } from 'react-router-dom';

const BusinessSettings = () => {
  const { t } = useTranslation();
  const path = useLocation().pathname.split('/')[1];

  const color =
    path === ROUTES.BUSINESS_CARD ||
    path === ROUTES.PAYMENTS ||
    path === ROUTES.SUBSCRIPTIONS ||
    path === ROUTES.AI_HELPER_SETTINGS ||
    path === ROUTES.COMMUNICATION_CHANNELS ||
    path === ROUTES.OFFER
      ? 'blue'
      : 'grey-500';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div
          className={`flex flex-col text-${color} items-center gap-[2px] hover:font-semibold hover:cursor-pointer hover:text-blue`}
        >
          <div className='relative'>
            <FaCog className='relative w-8 h-8' />
          </div>
          <div className='text-center font-open-sans text-xs leading-4'>
            {t('menuBar.businessSettings')}
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent side='right' className='max-w-[300px] pb-[15px]' align='start'>
        <DropdownMenuGroup className='px-2'>
          <DropdownMenuLabel>{t('businessSettings.menu.title')}</DropdownMenuLabel>
          <DropdownMenuItem active={path === ROUTES.BUSINESS_CARD} asChild>
            <Link to={`/${ROUTES.BUSINESS_CARD}`}>{t('businessSettings.menu.card')}</Link>
          </DropdownMenuItem>
          <DropdownMenuItem active={path === ROUTES.PAYMENTS} asChild>
            <Link to={`/${ROUTES.PAYMENTS}`}>{t('businessSettings.menu.payments')}</Link>
          </DropdownMenuItem>
          <DropdownMenuItem active={path === ROUTES.SUBSCRIPTIONS} asChild>
            <Link to={`/${ROUTES.SUBSCRIPTIONS}`}>{t('businessSettings.menu.subscriptions')}</Link>
          </DropdownMenuItem>
          <DropdownMenuItem active={path === ROUTES.AI_HELPER_SETTINGS} asChild>
            <Link to={`/${ROUTES.AI_HELPER_SETTINGS}`}>{t('businessSettings.menu.ai')}</Link>
          </DropdownMenuItem>
          <DropdownMenuItem active={path === ROUTES.COMMUNICATION_CHANNELS} asChild>
            <Link to={`/${ROUTES.COMMUNICATION_CHANNELS}`}>
              {t('businessSettings.menu.communication')}
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem active={path === ROUTES.OFFER} asChild>
            <Link to={`/${ROUTES.OFFER}`}>{t('businessSettings.menu.offer')}</Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { BusinessSettings };
