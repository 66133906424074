import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReducedPaymentSettingsInterface, getPaymentSetting } from 'src/common/api/payments';
import { AppStateType } from 'src/store';
import { PaymentSettingsInterface } from 'src/store/user/user.slice';
import { Button } from 'src/ui/Button/Button';
import { FaCheck } from 'react-icons/fa';
import { useAiAssistantStore } from 'src/store/onboarding/ai-assistant.store';
import { DatePicker } from 'src/ui/DatePicker/DatePicker';

interface AiPaymentDateProps {}

export const AiPaymentDate: React.FC<AiPaymentDateProps> = () => {
  const [settings, setSettings] = useState<ReducedPaymentSettingsInterface>();
  const { user } = useSelector((state: AppStateType) => state.user);

  const { updatePaySettingsViaAi, step } = useAiAssistantStore();
  const { mutate: update } = updatePaySettingsViaAi;

  const { } = useQuery(
    [user?.id],
    (): Promise<PaymentSettingsInterface | null> | null => {
      if (!user?.id) return null;
      return getPaymentSetting(user.id);
    },
    {
      onSuccess: data => (data !== null ? setSettings((s) => ({
        ...data,
        birthday: data.birthday || new Date(new Date().getFullYear() - 18, 0, 0).toUTCString(),
      })) : null),
    },
  );

  const onChange = (value: Date) => {
    setSettings(prevState => {
      if (prevState) {
        value.setUTCHours(0, 0, 0, 0);
        return {
          ...prevState,
          birthday: value.toUTCString(),
        };
      }
    });
  };

  const onClick = () => {
    if (settings) {
      update({
        paymentSettings: settings,
        message: '',
        step,
      });
    }
  };

  const birthday = useMemo(() => {
    const tempDate = settings?.birthday
      ? new Date(settings?.birthday)
      : new Date(new Date().getFullYear() - 18, 0, 0);

    return {
      date: tempDate?.getDate(),
      month: tempDate?.getMonth(),
      year: tempDate?.getFullYear(),
    };
  }, [settings]);

  return settings ? (
    <div className='flex w-full justify-center mt-[16px]'>
      <div className='flex gap-[16px]'>
        <DatePicker
          date={birthday.date}
          maxAge={100}
          minAge={18}
          month={birthday.month}
          year={birthday.year}
          setDate={value => {
            const { date, month, year } = value;
            onChange((new Date(Date.UTC(year, month, date, 0, 0, 0, 0))));
          }}
        />
        <Button
          variant='primary'
          onClick={onClick}
        >
          <FaCheck />
        </Button>
      </div>
    </div>
  ) : null;
};
