import React from 'react';
import { Trans } from 'react-i18next';
import { PhotoView } from 'react-photo-view';
import { cn } from 'src/common/utils';

import { MessengerType, TutorialSection } from '../interfaces';
import { ReactElement } from 'react-markdown/lib/react-markdown';

const renderTutorialSections = (sections: TutorialSection[], messengerType: MessengerType) => {
  return sections.map((section, i) => {
    let components: Record<string, ReactElement> = {};
    section.links?.forEach((link, i) => {
      components[i + 1] = (
        <a className='text-primary' target='_blank' rel='noreferrer' href={link ?? ''} />
      );
    });

    return (
      <React.Fragment key={section.key}>
        <div
          className={cn('flex items-baseline gap-4', { 'mt-2': i + 1 > 1 })}
          id={`${messengerType}-section-${section.key}`}
        >
          <span className='bg-white rounded-full min-w-[25px] min-h-[25px] flex items-center justify-center subsecondary'>
            {i + 1}
          </span>
          <h2 className='font-400 text-[16px] text-grey-900 text-start font-bold'>
            <Trans i18nKey={section.text} components={components} />
          </h2>
        </div>
        {section.steps?.map((step, j) => (
          <div key={j}>
            <ul className="list-disc pl-12">
              <li>
                <Trans i18nKey={step.text} components={components} />
              </li>
            </ul>
            {step.images?.map(({ src, alt }, k) => (
              <PhotoView key={src} src={src}>
                <img
                  src={src}
                  alt={alt || `Photo instruction section ${i + 1}`}
                  className='px-12 mt-4 cursor-zoom-in'
                />
              </PhotoView>
            ))}
          </div>
        ))}
        {section.images?.map(({ src, alt }, k) => (
          <PhotoView key={src} src={src}>
            <img
              src={src}
              alt={alt || `Photo instruction section ${i + 1}`}
              className='px-12 mt-4 cursor-zoom-in'
            />
          </PhotoView>
        ))}
      </React.Fragment>
    );
  });
};

export { renderTutorialSections };
