import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserInterface, updateUserLocaly } from 'src/store/user/user.slice';
import { deleteImages, uploadFiles } from '../api/file';

export const useImagesUpload = (user: UserInterface | null) => {
  const dispatch = useDispatch();
  const [imagesToDelete, setImagesToDelete] = useState<
    {
      key: string;
      url: string;
    }[]
  >([]);

  const [imagesToUpload, setImagesToUpload] = useState<
    {
      key: string;
      file: File;
    }[]
  >([]);

  const [images, setImages] = useState<{
    avatar: File | null;
    logo: File | null;
  }>({
    avatar: null,
    logo: null,
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    url: string,
    type: 'logo' | 'avatar',
  ) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setImagesToUpload(s => [...s.filter(s => s.key !== type), { file, key: type }]);
    }
    dispatch(updateUserLocaly(formObject(url, type, file) as UserInterface));
  };

  const handleCropperApply = (url: string | null, file: File, type: 'logo' | 'avatar') => {
    setImagesToUpload(s => [...s.filter(s => s.key !== type), { file, key: type }]);
    dispatch(updateUserLocaly(formObject(url, type, file)));
  };

  const handleOnDelete = (url: string, type: 'logo' | 'avatar') => {
    const typeSubsctring = url.substring(0, 4);
    setImages(s => ({ ...s, [type]: null }));
    dispatch(updateUserLocaly(formObject(null, type, null)));
    if (typeSubsctring !== 'blob' && typeSubsctring !== 'data') {
      setImagesToDelete(s => [...s.filter(s => s.key !== type), { key: type, url }]);
    }
  };

  const formObject = (
    url: string | null,
    type: 'logo' | 'avatar',
    file: File | null,
  ) => {
    setImages(state => ({
      ...state,
      [type]: file,
    }));

    return type === 'avatar'
      ? {
          avatarUrl: url,
        }
      : {
          expert: {
            brandLogoUrl: url,
          },
        };
  };

  return {
    images,
    imagesToUpload,
    imagesToDelete,
    handleOnChange,
    handleOnDelete,
    handleCropperApply,
  };
};
