import React from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
} from '@tanstack/react-query';
import { sendPaymentLink } from 'src/common/api/chat';
import { useChatStore } from 'src/store/chat/chatStore';
import { ChatPaymentButton } from 'src/ui/ChatPaymentButton/ChatPaymentButton';
import { Chat } from 'src/common/interfaces';
import { Button, ButtonProps } from 'src/ui/Button/Button';
import { useParams } from 'react-router-dom';
import { useActiveChat } from 'src/store/chat/useActiveChat';
import { useToast } from 'src/ui/Toast/use-toast';

interface SendPaymentButtonProps extends ButtonProps {
  ButtonComp: React.ComponentType<{ isLoading?: boolean }>;
}

function SendPaymentButton({ ButtonComp, ...props }: SendPaymentButtonProps) {
  const { chatId } = useParams();

  const { refetch } = useActiveChat(chatId);

  const { activeChatInfo, addNewMessage } = useChatStore();
  const { toast } = useToast();

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: (chatId: string) => sendPaymentLink(chatId),
    onSuccess: message => {
      addNewMessage(message);
      refetch();
    },
    onError: (e: any) => {
      toast({
        title: 'Error!',
        description: e.message,
        variant: 'destructive',
      });
    },
  });

  if (!activeChatInfo?.id) return <></>;

  return (
    <Button asChild disabled={isLoading} onClick={() => mutate(activeChatInfo?.id)} {...props}>
      <ButtonComp isLoading={isLoading} />
    </Button>
  );
}

export { SendPaymentButton };
