import { fetchBuilder } from 'src/services/basicFetch';
import { MethodEnum } from '../enums';
import { AiChat, AiChatMessage } from '../interfaces/ai-chat';
import { AIChatPromptType } from '../utils/schema/ai-message.schema';

const getAiChat = (chatId?: string): Promise<AiChat> | null => {
  if (!chatId) return null;
  return fetchBuilder({}, `ai-chat/${chatId}`, MethodEnum.GET);
};

const connectAiHelperToChat = ({
  chatId,
  language,
}: {
  chatId: string;
  language: string;
}): Promise<any> => {
  return fetchBuilder({}, `ai-chat/connect?chatId=${chatId}&language=${language}`, MethodEnum.POST);
};

const suggestAiHelperMessage = ({
  chatId,
  clientId,
  language,
}: {
  chatId: string;
  clientId: string;
  language: string;
}): Promise<any> => {
  return fetchBuilder(
    {},
    `ai-chat/suggest?chatId=${chatId}&clientId=${clientId}&language=${language}`,
    MethodEnum.POST,
  );
};

const summariseViaAi = ({
  chatId,
  language,
  clientId,
}: {
  chatId: string;
  language: string;
  clientId: string;
}): Promise<any> => {
  return fetchBuilder(
    {},
    `ai-chat/summarize?chatId=${chatId}&clientId=${clientId}&language=${language}`,
    MethodEnum.POST,
  );
};

const sendMessageToAi = ({
  chatId,
  message,
  promptType,
}: {
  chatId: string;
  message: string;
  promptType?: AIChatPromptType;
}): Promise<AiChatMessage> => {
  return fetchBuilder({ message, promptType }, `ai-chat/${chatId}/message`, MethodEnum.POST);
};

const regenerateAiResponse = (messageId: number, clientId?: string): Promise<AiChatMessage> => {
  return fetchBuilder(
    {},
    `ai-chat/regenerate/${messageId}${clientId ? `?clientId=${clientId}` : ''}`,
    MethodEnum.POST,
  );
};

export {
  getAiChat,
  connectAiHelperToChat,
  sendMessageToAi,
  regenerateAiResponse,
  suggestAiHelperMessage,
  summariseViaAi,
};
