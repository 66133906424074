import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { cn } from 'src/common/utils/cn';
import { Button } from '../Button/Button';
import { FaTimes as CloseIcon } from 'react-icons/fa';
import { Avatar, AvatarFallback, AvatarImage } from '../Avatar/Avatar';

interface Props<T> {
  results?: T[];
  inputClassName?: string;
  className?: string;
  renderItem(item: T): JSX.Element;
  onChange?: React.ChangeEventHandler;
  onSelect?: (item: T | null) => void;
  onEnter?:(value: string) => void;
  value?: string;
}

export const LiveSearch = <T extends object>({
  results = [],
  inputClassName,
  className,
  renderItem,
  value,
  onChange,
  onSelect,
  onEnter
}: Props<T>): JSX.Element => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const resultContainer = useRef<HTMLDivElement>(null);
  const [showResults, setShowResults] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');

  const handleSelection = (selectedIndex: number) => {
    const selectedItem = results[selectedIndex];
    if (!selectedItem) return resetSearchComplete();
    onSelect && onSelect(selectedItem);
    resetSearchComplete();
  };

  const resetSearchComplete = useCallback(() => {
    setFocusedIndex(-1);
    setShowResults(false);
    onEnter?.('');
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    const { key } = e;
    let nextIndexCount = 0;

    if (key === 'ArrowDown') nextIndexCount = (focusedIndex + 1) % results.length;

    if (key === 'ArrowUp') nextIndexCount = (focusedIndex + results.length - 1) % results.length;

    if (key === 'Escape') {
      resetSearchComplete();
    }

    if (key === 'Enter') {
      e.preventDefault();
      handleSelection(focusedIndex);


      if (!focusedIndex && !results.length){
        {/*@ts-ignore */}
        onEnter?.(e.target.value);
      }
    }

    setFocusedIndex(nextIndexCount);
  };

  type changeHandler = React.ChangeEventHandler<HTMLInputElement>;
  const handleChange: changeHandler = e => {
    setDefaultValue(e.target.value);
    setShowResults(!!e.target.value);
    onChange && onChange(e);
  };

  useEffect(() => {
    if (!resultContainer.current) return;

    resultContainer.current.scrollIntoView({
      block: 'center',
    });
  }, [focusedIndex]);

  useEffect(() => {
    if (value) setDefaultValue(value);
  }, [value]);

  return (
    <div className='h-fit flex items-center justify-center'>
      <div
        tabIndex={1}
        onBlur={resetSearchComplete}
        onKeyDown={handleKeyDown}
        className={cn(
          `focus-within:shadow-[0_0_4px_0_#009EF7_inset] flex w-full gap-[15px] items-center px-[15px] py-[9px] bg-[#F3F6F9] rounded-[6px] relative py-[9px] px-[15px]`,
          className,
        )}
      >
        <div>
          <FaUserCircle className='text-grey w-4 h-4' />
        </div>
        <input
          value={defaultValue}
          onChange={handleChange}
          type='text'
          className={cn('focus:outline-none bg-[#F3F6F9] text-[14px] w-full', inputClassName)}
          placeholder='Payer'
        />
        {(value || defaultValue) && (
          <Button
            onClick={e => {
              e.preventDefault();
              handleChange?.({
                target: { value: '' },
              } as unknown as React.ChangeEvent<HTMLInputElement>);
              onSelect?.(null);
              setDefaultValue('');
            }}
            className='text-grey-500 absolute top-[10px] right-4 cursor-pointer hover:text-grey-400'
          >
            <CloseIcon className='w-5 h-5' />
          </Button>
        )}
        {showResults && (
          <div className='z-50 max-h-[200px] top-[100%] left-0 absolute mt-1 w-full bg-white rounded-md max-h-56 overflow-y-auto shadow-md'>
            {results.length > 0 ? (
              results.map((item, index) => {
                return (
                  <div
                    key={index}
                    onMouseDown={() => handleSelection(index)}
                    ref={index === focusedIndex ? resultContainer : null}
                    className='cursor-pointer hover:text-primary hover:bg-primary-light text-[14px] px-[15px] py-[9px]'
                  >
                    {renderItem(item)}
                  </div>
                );
              })
            ) : (
              <div className='text-[14px] px-[15px] py-[9px] text-grey-400'>No results</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
