import React from 'react';
import { Button, buttonVariants } from 'src/ui/Button/Button';
import { useLocation, Link } from 'react-router-dom';
import { Logo } from 'src/ui/Logo/Logo';
import { cn } from 'src/common/utils/cn';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
  const { t } = useTranslation();

  const redirectToMainDomain = () => {
    window.location.href = `${process.env.REACT_APP_ANAMATA_HOME}`;
  };

  return (
    <main
      className='min-w-screen min-h-screen flex items-center justify-center flex-col text-center relative px-6'
      style={{ fontFamily: 'Unbounded' }}
    >
      <h1 className='absolute top-10 left-[50%] translate-x-[-50%] text-grey-900 text-2xl md:text-4xl font-bold'>
        <Logo />
      </h1>
      <section className='flex flex-col gap-12 items-center '>
        <img
          src='https://anamata-bucket-stg.s3.amazonaws.com/anamata-bucket-stg/404.png'
          alt='404 Not found'
        />
        <h2 className='text-3xl md:text-5xl text-center mt-4 font-bold text-grey-900 tracking-wide'>
          {t('notFound.title')}
        </h2>
        <p className='text-grey-900 text-xl md:text-2xl tracking-wide font-sans'>
          {t('notFound.description')}
        </p>
        <Button
          variant='primary'
          className='text-xl font-medium w-full max-w-[300px]'
          onClick={redirectToMainDomain}
        >
          {t('notFound.back')}
        </Button>
      </section>
    </main>
  );
}
