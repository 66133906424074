import React, { useEffect, useState } from 'react';
import { cn } from 'src/common/utils';
import { copyToClipboard } from 'src/common/utils/copyToClipboard';

import { Button } from 'src/ui/Button/Button';

interface CopyButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  value: string;
  className?: string;
}

export default function CopyButton({ value, className }: CopyButtonProps) {
  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setHasCopied(false), 2000);

    return () => clearTimeout(timeout);
  }, [hasCopied]);

  return (
    <Button
      type='button'
      className={cn(
        'w-full px-[18px] py-[13px] rounded-[6px] bg-primary-light text-primary h-[40px]',
        className,
      )}
      onClick={() => {
        copyToClipboard(value);
        setHasCopied(true);
      }}
      disabled={hasCopied}
    >
      {hasCopied ? 'Copied' : 'Copy link'}
    </Button>
  );
}
