import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { downloadFileBuffer, updateUserOffer } from '../api/file';
import { updateBuffer } from 'src/store/files/files.slice';
import { updateUserLocaly } from 'src/store/user/user.slice';
import { bufferToFile } from '../utils';

export const useOffer = () => {
  const dispatch = useDispatch();
  const [offer, setOffer] = useState<File | null>(null);
  const { user } = useSelector((state: AppStateType) => state.user);
  const { buffer } = useSelector((state: AppStateType) => state.file);
  const [error, setError] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const { mutate: download } = useMutation({
    mutationFn: (data: { fileName: string }) => downloadFileBuffer(data.fileName),
    onSuccess: data => {
      dispatch(updateBuffer(data));
      setError(null);
    },
  });

  const { mutate: updateOffer, isLoading } = useMutation({
    mutationFn: (data: { id: number; file: File; isTemplate?: boolean }) =>
      updateUserOffer(data.id, data.file, data.isTemplate),
    onSuccess: data => {
      dispatch(updateUserLocaly(data));
      setError(null);
      setIsChanged(false);
    },
  });

  const fileName = useMemo(
    () =>
      user?.expert?.offer?.url && !user.expert.offer.isTemplate
        ? user.expert.offer.url.split('/').reverse()[0]
        : null,
    [user],
  );

  useEffect(() => {
    loadFile();
  }, [fileName]);

  useEffect(() => {
    if (fileName) {
      setOffer(bufferToFile(buffer, fileName, 'application/pdf'));
    }
  }, [buffer]);

  const loadFile = () => {
    if (fileName) {
      download({ fileName });
    }
  };

  const reset = () => {
    if (fileName) {
      download({ fileName });
    } else {
      setOffer(null);
    }
    setIsChanged(false);
  };

  const uploadOffer = (file: File | null, isTemplate?: boolean) => {
    if (user) {
      updateOffer({
        id: user?.id,
        file: file as File,
        isTemplate,
      });
    }

    if (!file) {
      setOffer(null);
    }
  };

  const onOfferChange = (file: File | null) => {
    if (offer && !file) {
      uploadOffer(null);
    }
    setOffer(file);
    setIsChanged(true);
  };

  const onLocalOfferChange = (file: File | null) => {
    setOffer(file);
    setIsChanged(true);
  };

  return {
    offer,
    uploadOffer,
    onOfferChange,
    onLocalOfferChange,
    error,
    setError,
    isChanged,
    reset,
    isLoading,
  };
};
