import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOffer } from 'src/common/hooks';
import { cn } from 'src/common/utils/cn';
import { Button } from 'src/ui/Button/Button';
import { Card } from 'src/ui/Card/Card';
import { OfferTabs } from 'src/components/OfferTabs';
import { OfferPreview } from '../Onboarding/Subpages/Offer.subpage';
import { FaSpinner } from 'react-icons/fa';
import { PageWrapper } from '../Common/Page.wrapper';
import { useSelector } from 'react-redux';
import { AppStateType } from 'src/store';

export const OfferPage = () => {
  const { t } = useTranslation();

  const { user } = useSelector((state: AppStateType) => state.user);
  const { error, offer, onLocalOfferChange, setError, uploadOffer, isChanged, isLoading, reset } =
    useOffer();

  const [isTemplateTab, setIsTemplateTab] = useState<boolean>(true);
  const isSumbitDisabled = useMemo(
    () => (user?.expert?.offer?.isTemplate ? !isChanged : !isTemplateTab && !isChanged),
    [user, isChanged, isTemplateTab],
  );

  return (
    <PageWrapper
      headerText={t('businessSettings.offer.header')}
      isChanged={isChanged}
      mobileActiveElements={
        <div className='flex gap-2 w-full'>
          <Button
            className='w-1/2 h-[40px]'
            variant='primary'
            disabled={isSumbitDisabled}
            onClick={() =>
              !error ? (isTemplateTab ? uploadOffer(null, true) : uploadOffer(offer)) : null
            }
          >
            {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
          <Button
            variant={'muted'}
            className={cn('w-1/2 bg-secondary h-[40px]', { hidden: !isChanged })}
            onClick={() => reset()}
          >
            {t('common.discard')}
          </Button>
        </div>
      }
      activeElemets={
        <>
          <Button
            variant={'muted'}
            className={cn('max-w-[100px] bg-secondary h-[40px]', { hidden: !isChanged })}
            onClick={() => reset()}
          >
            {t('common.discard')}
          </Button>
          <Button
            className='h-[40px]'
            variant={'primary'}
            onClick={() =>
              !error ? (isTemplateTab ? uploadOffer(null, true) : uploadOffer(offer)) : null
            }
            disabled={isSumbitDisabled}
          >
            {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className='md:mt-0 mt-[21px] flex flex-col md:flex-row gap-20'>
        <div className='w-full lg:w-6/12'>
          <h2 className='mb-[23px] text-center md:text-left'>
            {t('businessSettings.offer.subheader')}
          </h2>
          <Card variant={'default'}>
            <OfferTabs
              error={error}
              onOfferChange={onLocalOfferChange}
              offer={offer}
              setError={setError}
              setIsTemplateTab={setIsTemplateTab}
            />
          </Card>
        </div>
        <div className='space-y-12 w-6/12 hidden lg:block'>
          <div className='m-auto w-fit mt-[10vh]'>
            <OfferPreview showPdf />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
