import { ExpertSubscription } from "src/store/user/user.slice";
import { ExpertSubscriptionTypeEnum } from "../enums/expert-plan-type.enum";

const config = {
    [ExpertSubscriptionTypeEnum.SINGLE]: 'onboarding.subscriptions.options.single',
    [ExpertSubscriptionTypeEnum.PACK]: 'onboarding.subscriptions.options.pack',
    [ExpertSubscriptionTypeEnum.CONSULTATION]: 'onboarding.subscriptions.options.consultation',
}

const generateOptions = (maxAmount: number = 5) => {
    const options = Array.from(Array(maxAmount)).map((_v, idx) => ({
        text: `up to ${idx + 1} questions`,
        value: `${idx + 1}`,
    }))

    const [extra, ...data] = options;
    return data;
}

export const extendPlans = (plans: ExpertSubscription[]) => {
    if (plans.length) {
        return plans.map(p => ({
            ...p,
            title: config[p.type],
            ...(p.type === ExpertSubscriptionTypeEnum.PACK && { options: generateOptions(5) })
        }))
    }

    return [];
}