import React from "react";
import { steps } from "../../Onboarding.constants";
import { Button } from "src/ui/Button/Button";
import { OnboardingSteps } from "src/common/enums/onboardingSteps.enum";
import { setStep } from "src/store/onboarding/onboarding.slice";
import { useDispatch } from "react-redux";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "src/ui/AlertDialog/AlertDialog";
import { useTranslation } from "react-i18next";
import { useAiAssistantStore } from "src/store/onboarding/ai-assistant.store";

export const AiMenu = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { rerunStep } = useAiAssistantStore();
    const { mutate: rerun } = rerunStep;

    return <div className='flex gap-[16px] justify-center mt-[16px] flex-wrap'>
        {steps.map((s, idx) => idx < OnboardingSteps.CHANNELS ?
            <AlertDialog>
                <AlertDialogTrigger className='w-full' asChild>
                    <Button
                        variant='outlined'
                        key={s.name}
                        className='w-full max-w-[120px] border-primary'
                        onClick={() => dispatch(setStep(idx))}
                    >
                        {s.name}
                    </Button>
                </AlertDialogTrigger>
                <AlertDialogContent className='max-w-[800px] text-grey-900 sm:py-20 sm:rounded-xl'>
                    <AlertDialogHeader className='sm:text-center'>
                        <h1 className='text-[64px]'>⚠️</h1>
                        <AlertDialogTitle className='font-bold text-2xl'>
                            {t('ai.alert.title')}
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                            {t('ai.alert.description')}
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter className='flex-col sm:justify-center gap-2 sm:mt-6'>
                        <AlertDialogAction asChild className='font-semibold'>
                            <Button variant={'danger'} onClick={() => rerun({ step: idx })}>
                                {t('ai.alert.action')}
                            </Button>
                        </AlertDialogAction>
                        <AlertDialogCancel asChild className='font-semibold'>
                            <Button variant={'primary'}>
                                {t('ai.alert.cancel')}
                            </Button>
                        </AlertDialogCancel>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog> : null)}
    </div>
}