import React from 'react';
import { cn } from 'src/common/utils';
import { DatePlaceholder, DatePlaceholderProps } from 'src/components/DatePlaceholder';

const ChatDateIndicator = ({ ...props }: Omit<DatePlaceholderProps, 'date'> & { date: Date | string}) => {
  if (new Date(props.date).getDate() === new Date().getDate()) {
    return (
      <span
        className={cn(
          'flex w-full uppercase text-[11px] tracking-wider text-grey-900 justify-center tracking-widest my-2',
          props.className,
        )}
      >
        Today
      </span>
    );
  }

  return (
    <DatePlaceholder
      {...props}
      template='D MMMM'
      className={cn(
        'justify-center text-grey-900 uppercase tracking-widest text-[11px] mb-[3px]',
        props.className,
      )}
    />
  );
};

export { ChatDateIndicator };
