import React, { useState, ChangeEvent } from 'react';
import { FaRobot, FaPaperclip, FaArrowCircleUp } from 'react-icons/fa';
import { cn } from 'src/common/utils/cn';
import { Textarea } from '../TextInput/TextInput';

interface MessageInputProps {
  dispatch: any;
  activeClient: string;
  userId: string;
  isChatbot?: boolean;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  isDemonstration?: boolean;
  disabled?: boolean;
}

const MessageInput = React.forwardRef<HTMLTextAreaElement, MessageInputProps>(
  (
    { dispatch, activeClient, userId, isChatbot, placeholder, onChange, isDemonstration, disabled },
    ref,
  ) => {
    const [message, setMessage] = useState('');
    const [showSendButton, setShowSendButton] = useState(false);

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(event.target.value);

      const inputValue = event.target.value.trim();
      setShowSendButton(inputValue.length > 0);

      if (onChange) {
        onChange(event);
      }
    };

    const handleSendMessage = () => {
      dispatch();

      setMessage('');
      setShowSendButton(false);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (event.ctrlKey) {
          handleSendMessage();
        } else {
          setMessage(message + '\n');
        }
      }
    };

    return (
      <div
        className={cn('secondary w-full flex flex-col rounded-md bg-white', {
          'focus-within:shadow-[0_0_4px_0_#009EF7_inset] hover:shadow-[0_0_4px_#3F42544D_inset] bg-light':
            isChatbot,
          'bg-transparent': isDemonstration,
        })}
      >
        <div
          className={cn('w-full flex items-center p-[5px] pr-[20px]', {
            'opacity-50': isDemonstration,
          })}
        >
          <Textarea
            minHeight={40}
            maxHeight={200}
            autoFocus={!window.isMobile}
            placeholder={placeholder || 'Your message to client...'}
            value={message}
            rows={1}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            disabled={disabled || isDemonstration}
            className={cn(
              'mr-2 w-full focus-within:bg-transparent focus-within:shadow-none focus:outline-none focus:bg-transparent focus:shadow-none bg-inherit hover:shadow-none group-hover:shadow-none',
              {
                'bg-light': isChatbot,
                'bg-transparent': isDemonstration,
              },
            )}
            ref={ref}
          />
          <div className='flex items-center gap-5'>
            {!isChatbot ? (
              <>
                {!isDemonstration ? (
                  <FaRobot className='text-3xl text-purple mr-2 cursor-pointer w-[22px] h-[22px]' />
                ) : null}
                <FaPaperclip
                  className={cn('text-3xl text-grey mr-2 w-[22px] h-[22px]', {
                    'cursor-pointer': !isDemonstration,
                  })}
                />
              </>
            ) : null}

            {showSendButton ? (
              <FaArrowCircleUp
                className='text-3xl text-blue cursor-pointer w-[22px] h-[22px]'
                onClick={handleSendMessage}
              />
            ) : (
              <FaArrowCircleUp className='text-3xl text-grey cursor-default w-[22px] h-[22px]' />
            )}
          </div>
        </div>
      </div>
    );
  },
);

MessageInput.displayName = 'MessageInput';

export default MessageInput;
