import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBuilder } from '../../services/basicFetch'
import { MethodEnum } from 'src/common/enums';

export const askAi = createAsyncThunk(
    'ASK', async (
        data: {
            role: string;
            message: string;
            step: number;
        }) => {
    try {
        const answer = await fetchBuilder(data, 'assistant/ask', MethodEnum.POST);
        return {
            messages: [
                answer
            ]
        };
    } catch (error) {
        console.error(error)
    }
})

export const getHistory = createAsyncThunk(
    'HISTORY', async (data: {
        token: string
    }) => {
    try {
        const answer = await fetchBuilder(data, 'assistant/chat-history', MethodEnum.GET);
        return {
            messages: answer
        };
    } catch (error) {
        console.error(error)
    }
})

export const updateAvatars = createAsyncThunk(
    'AVATARS', async ({
        token,
        userAvatar,
        brandLogo
    }: {
        token: string,
        userAvatar?: File | null,
        brandLogo?: File | null
    }) => {

    try {
        const answer = await fetchBuilder({ token }, 'assistant/avatars', MethodEnum.PATCH, [userAvatar, brandLogo] as File[]);
        return {
            messages: answer
        };
    } catch (error) {
        console.error(error);
    }
})