import { fetchBuilder } from 'src/services/basicFetch';
import {
  InstagramSettings,
  MessengerSettings,
  TelegramSettings,
  WhatsappSettings,
} from 'src/store/user/user.slice';
import { MethodEnum } from '../enums';

const setWhatsappSettings = (
  settings: Pick<WhatsappSettings, 'accessToken' | 'phoneNumberId' | 'phone'>,
): Promise<WhatsappSettings> => {
  return fetchBuilder(settings, 'channels-settings/whatsapp', MethodEnum.POST);
};

const setMessengerSettings = (
  settings: Pick<MessengerSettings, 'pageId' | 'pageAccessToken'>,
): Promise<MessengerSettings> => {
  return fetchBuilder(settings, 'channels-settings/messenger', MethodEnum.POST);
};

const setInstagramSettings = (
  settings: Pick<InstagramSettings, 'pageId' | 'pageAccessToken' | 'username'>,
): Promise<InstagramSettings> => {
  return fetchBuilder(settings, 'channels-settings/instagram', MethodEnum.POST);
};

const setTelegramSettings = (): Promise<TelegramSettings> => {
  return fetchBuilder({}, 'channels-settings/telegram', MethodEnum.POST);
};

const removeWhatsappSettings = (): Promise<WhatsappSettings> => {
  return fetchBuilder({}, 'channels-settings/whatsapp/reset', MethodEnum.PATCH);
};

const removeMessengerSettings = (): Promise<MessengerSettings> => {
  return fetchBuilder({}, 'channels-settings/messenger/reset', MethodEnum.PATCH);
};

const removeInstagramSettings = (): Promise<InstagramSettings> => {
  return fetchBuilder({}, 'channels-settings/instagram/reset', MethodEnum.PATCH);
};

const removeTelegramSettings = (): Promise<InstagramSettings> => {
  return fetchBuilder({}, 'channels-settings/telegram/reset', MethodEnum.PATCH);
};

export {
  setWhatsappSettings,
  setMessengerSettings,
  setInstagramSettings,
  setTelegramSettings,
  removeInstagramSettings,
  removeMessengerSettings,
  removeWhatsappSettings,
  removeTelegramSettings,
};
