/* eslint-disable react/prop-types */

import React, { FC, useEffect, useRef, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { FaCalendarAlt } from "react-icons/fa";
import { Calendar } from "./Calendar";
import { CalendarButton } from "./CalendarButton";
import { DateRange } from "react-day-picker";
import { Button } from "../Button/Button";
import { cn } from "src/common/utils";
import { FaTimes as CloseIcon } from 'react-icons/fa';


interface Preset {
    name: string
    label: string
}

const PRESETS: Preset[] = [
    { name: 'today', label: 'Today' },
    { name: 'yesterday', label: 'Yesterday' },
    { name: 'last7', label: 'Last 7 days' },
    { name: 'last30', label: 'Last 30 days' },
]

const formatDate = (date: Date, locale: string = 'en-us'): string => {
    return date.toLocaleDateString(locale, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    })
}

const getPresetRange = (presetName: string): DateRange => {
    const preset = PRESETS.find(({ name }) => name === presetName)
    if (!preset) throw new Error(`Unknown date range preset: ${presetName}`)
    const from = new Date()
    const to = new Date()

    switch (preset.name) {
        case 'today':
            from.setHours(0, 0, 0, 0)
            to.setHours(0, 0, 0, 0)
            break
        case 'yesterday':
            from.setDate(from.getDate() - 1)
            from.setHours(0, 0, 0, 0)
            to.setDate(to.getDate() - 1)
            to.setHours(0, 0, 0, 0)
            break
        case 'last7':
            from.setDate(from.getDate() - 6)
            from.setHours(0, 0, 0, 0)
            to.setHours(0, 0, 0, 0)
            break
        case 'last30':
            from.setDate(from.getDate() - 29)
            from.setHours(0, 0, 0, 0)
            to.setHours(0, 0, 0, 0)
            break
    }

    return { from, to }
}

export interface CalendarPickerProps {
    onUpdate?: (values: { range: DateRange, rangeCompare?: DateRange }) => void
    initialDateFrom?: Date | string
    initialDateTo?: Date | string
    initialCompareFrom?: Date | string
    initialCompareTo?: Date | string
    align?: 'start' | 'center' | 'end'
    locale?: string
}

export const CalendarPicker: FC<CalendarPickerProps> = ({
    initialDateFrom,
    initialDateTo,
    onUpdate,
    align = 'end',
    locale = 'en-US',
}): JSX.Element => {

    const [isOpen, setIsOpen] = useState(false);
    const [range, setRange] = useState<DateRange>({
        from: initialDateFrom ? new Date((new Date(initialDateFrom)).setHours(0, 0, 0, 0)) : undefined,
        to: initialDateTo ?
            new Date((new Date(initialDateTo)).setHours(0, 0, 0, 0)) :
            initialDateFrom ?
                new Date((new Date(initialDateFrom)).setHours(0, 0, 0, 0)) :
                undefined
    });
    const [selectedPreset, setSelectedPreset] = useState<string | undefined>(undefined)
    const openedRangeRef = useRef<DateRange | undefined>();

    const [isSmallScreen, setIsSmallScreen] = useState(
        typeof window !== 'undefined' ? window.innerWidth < 960 : false
    );

    const resetValues = (): void => {
        setRange({
            from: typeof initialDateFrom === 'string' ? new Date(initialDateFrom) : initialDateFrom,
            to: initialDateTo ? (typeof initialDateTo === 'string' ? new Date(initialDateTo) : initialDateTo) : (typeof initialDateFrom === 'string' ? new Date(initialDateFrom) : initialDateFrom)
        })
    }

    const setPreset = (preset: string): void => {
        const range = getPresetRange(preset)
        setRange(range)
    }

    const checkPreset = (): void => {
        for (const preset of PRESETS) {
            const presetRange = getPresetRange(preset.name)

            const normalizedRangeFrom = new Date(range.from?.setHours(0, 0, 0, 0) ?? 0)
            const normalizedPresetFrom = new Date(
                presetRange.from?.setHours(0, 0, 0, 0) ?? 0
            )

            const normalizedRangeTo = new Date(range.to?.setHours(0, 0, 0, 0) ?? 0)
            const normalizedPresetTo = new Date(
                presetRange.to?.setHours(0, 0, 0, 0) ?? 0
            )

            if (
                normalizedRangeFrom.getTime() === normalizedPresetFrom.getTime() &&
                normalizedRangeTo.getTime() === normalizedPresetTo.getTime()
            ) {
                setSelectedPreset(preset.name)
                return
            }
        }

        setSelectedPreset(undefined)
    }

    useEffect(() => {
        const handleResize = (): void => {
            setIsSmallScreen(window.innerWidth < 960)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            openedRangeRef.current = range;
        }
    }, [isOpen]);

    useEffect(() => {
        checkPreset()
    }, [range]);

    const PresetButton = ({
        preset,
        label,
        isSelected
    }: {
        preset: string
        label: string
        isSelected: boolean
    }): JSX.Element => (
        <Button
            className={cn(isSelected && 'pointer-events-none border-black font-bold', 'rounded-md border-1px border-grey-400 py-[6.5px] px-[16px] text-[14px]')}
            variant="outlined"
            onClick={() => { setPreset(preset) }}
        >
            {label}
        </Button>
    )

    return (
        <Popover open={isOpen} onOpenChange={(open: boolean) => {
            if (!open) {
                resetValues()
            }
            setIsOpen(open)
        }}>
            <PopoverTrigger asChild>
                <CalendarButton size={'lg'} variant="outline" className='px-[15px] py-[6px] !border-md w-full bg-light md:bg-white'>
                    <div className="flex justify-between items-center w-full gap-[15px]">
                        <FaCalendarAlt className='text-[18px] text-grey-500' />
                        <div className="text-left grow">
                            <div className="py-1 text-[14px]">
                                <div className={cn({
                                    'text-grey-400': !initialDateFrom
                                })}>
                                    {range.from ?
                                        `${formatDate(range.from, locale)}${(range.to != null && range.from.getTime() !== range.to.getTime()) ? ' - ' + formatDate(range.to, locale) : ''}` :
                                        'Date'}
                                </div>
                            </div>
                        </div>
                        {initialDateFrom ? <CloseIcon className='w-5 h-5 text-grey-500' onClick={() => onUpdate?.({
                            range: {
                                from: undefined,
                                to: undefined,
                            }
                        })} /> : null}
                    </div>
                </CalendarButton>
            </PopoverTrigger>
            <PopoverContent align="end" className="md:w-auto border-none">
                <div className="flex w-full flex-row flex-wrap gap-[10px] justify-center items-center">
                    {PRESETS.map((preset) => (
                        <PresetButton
                            key={preset.name}
                            preset={preset.name}
                            label={preset.label}
                            isSelected={selectedPreset === preset.name}
                        />
                    ))}
                </div>
                <Calendar
                    mode="range"
                    selected={range}
                    onSelect={(value: { from?: Date, to?: Date } | undefined) => {
                        if ((value?.from) != null) {
                            setRange({
                                from: value.from,
                                to: value?.to?.getTime() !== value.from.getTime() ?
                                    value?.to :
                                    undefined
                            })
                        }
                    }}
                    initialFocus
                    numberOfMonths={isSmallScreen ? 1 : 2}
                />
                <div className="flex justify-end gap-2 py-2 pr-4 items-center">
                    <div className='text-[14px] text-grey-500 mr-[20px]'>{range.from ? `${formatDate(range.from, locale)}${(range.to != null) ? ' - ' + formatDate(range.to, locale) : ''}` : ''}</div>
                    <Button
                        className='py-[6px] px-[9px] text-[12px] text-bold text-white'
                        variant="primary"
                        onClick={() => {
                            setIsOpen(false)
                            onUpdate?.({ range });
                        }}
                    >
                        Apply
                    </Button>
                    <Button
                        className='py-[6px] px-[9px] text-[12px] text-bold text-grey-600'
                        onClick={() => {
                            setIsOpen(false)
                            resetValues()
                        }}
                        variant="muted"
                    >
                        Cancel
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    )
}