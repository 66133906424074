import React, { useMemo, useRef } from 'react';
import { CommunicationChannelsHeader, CommunicationChannelsWrapper } from '../CommunicationChannelsWrappers';
import { MessengerType } from 'src/common/interfaces';
import { useScreenDetect, useSearchParams } from 'src/common/hooks';
import { Button } from 'src/ui/Button/Button';
import { cn } from 'src/common/utils/cn';
import { FaSpinner } from 'react-icons/fa6';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  InstagramSettingsInputs,
  instagramSettingsSchema,
} from 'src/common/utils/schema/channels-settings.schema';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, updateUserLocaly } from 'src/store/user/user.slice';
import { useTranslation } from 'react-i18next';
import { setInstagramSettings, setWhatsappSettings } from 'src/common/api/channels-settings';
import { useMutation } from '@tanstack/react-query';
import { catchError } from 'src/common/utils/catchError';
import { InstagramForm } from './InstagramForm';
import { InstagramTutorial } from 'src/pages/Onboarding/Subpages/Connection/Tutorials/InstagramTutorial';

export default function WhatsappSettingsPage() {
  const { t } = useTranslation();

  const { isTargetScreen } = useScreenDetect('laptop-xs');

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const form = useForm<InstagramSettingsInputs>({
    resolver: zodResolver(instagramSettingsSchema),
  });

  const { mutate: saveWhatsappSettings, isLoading: isSaving } = useMutation({
    mutationFn: async (instagramSettings: InstagramSettingsInputs) => {
      return setInstagramSettings(instagramSettings);
    },
    onSuccess: data => {
      dispatch(updateUserLocaly({ expert: { settings: { instagramSettings: data } } }));
    },
    onError: e => {
      catchError(e);
    },
  });

  const resetForm = () => {
    form.reset({
      pageId: user?.expert?.settings?.instagramSettings?.pageId || '',
      pageAccessToken: user?.expert?.settings?.instagramSettings?.pageAccessToken || '',
      username: user?.expert?.settings?.instagramSettings?.username || '',
    });
  };

  const onSubmit = (vals: InstagramSettingsInputs) => {
    saveWhatsappSettings(vals);
  };

  const watcher = useWatch({ control: form.control });

  const isFormDisabled = useMemo(() => {
    const { pageAccessToken, pageId, username } = watcher;

    return !pageAccessToken || !pageId || !username;
  }, [watcher]);

  const isChanged = form.formState.isDirty;

  return (
    <CommunicationChannelsWrapper
      title={t('communicationChannels.instagram.title')}
      isSaving={isSaving}
      messengerType={MessengerType.INSTAGRAM}
      isChanged={isChanged}
      isFormDisabled={isFormDisabled}
      onSave={() => buttonRef.current?.click()}
      onDiscard={() => resetForm()}
      form={<InstagramForm {...form} onSubmit={onSubmit} buttonRef={buttonRef} />}
      activeElements={
        <>
          <Button
            className='w-1/2 h-[40px]'
            variant='primary'
            onClick={() => buttonRef.current?.click()}
            disabled={!isChanged || isFormDisabled || isSaving}
          >
            {isSaving && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.save')}
          </Button>
          <Button
            variant='muted'
            className={cn('w-1/2 bg-secondary h-[40px]', {
              hidden: !form.formState.isDirty,
            })}
            onClick={resetForm}
          >
            {t('common.discard')}
          </Button>
        </>
      }
      tutorial={<InstagramTutorial className='!px-0 py-0' />}
    />
  );
}
