import React from 'react';
import { useBrowserNotificationsStore } from 'src/store/notifications/useBrowserNotificationsStore';
import { Button } from 'src/ui/Button/Button';
import { Switch } from 'src/ui/Switch/Switch';

const ConnectBrowserNotificationsButton = () => {
  const { isGranted, requestPermission } = useBrowserNotificationsStore();

  return (
    <Switch
      isChecked={!!isGranted}
      onChange={() => requestPermission()}
      disabled={isGranted}
      labelClassName='medium'
    />
  );
};

export { ConnectBrowserNotificationsButton };
