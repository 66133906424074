import * as z from 'zod';

const profileSchema = z.object({
  firstName: z
    .string({
      errorMap: () => ({
        message: 'Firstname is required',
      }),
    })
    .min(2, {
      message: 'First name must be at least 2 characters.',
    })
    .max(31, 'First name is too long (31 is max)')
    .refine(
      value => {
        return /^(?!\s)(.*\S)$/.test(value);
      },
      {
        message: 'First name must not have leading or trailing spaces',
      },
    ),
  lastName: z
    .string({
      errorMap: () => ({
        message: 'Lastname is required',
      }),
    })
    .min(2, {
      message: 'Lastname must be at least 2 characters.',
    })
    .max(31, 'Lastname is too long')
    .refine(
      value => {
        return /^(?!\s)(.*\S)$/.test(value);
      },
      {
        message: 'Last name must not have leading or trailing spaces',
      },
    ),
  description: z
    .string({
      errorMap: () => ({
        message: 'Description is required',
      }),
    })
    .min(10, 'Description is too short (minimum 10 symbols)')
    .max(1000, 'Description is too long (maximum 1000 symbols)')
    .refine(
      value => {
        return /^$|.*\S+.*/.test(value);
      },
      {
        message: 'Description must not have only spaces',
      },
    ),
  avatarUrl: z.string().optional().nullable(),
});

export { profileSchema };
