import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage } from 'src/store/onboarding/onboarding.slice';
import ChatMessage from 'src/ui/ChatMessage/ChatMessage';
import MessageInput from 'src/ui/MessageInput/MessageInput';
import { WritingPlaceholder } from 'src/ui/WritingPlaceholder/WritingPlaceholder';
import { useAiAssistantStore } from 'src/store/onboarding/ai-assistant.store';
import { getInteractiveElement } from './elements';
import { useSubscriptions } from 'src/common/hooks';
import { OnboardingSteps } from 'src/common/enums/onboardingSteps.enum';
import { AppStateType } from 'src/store';
import { cn } from 'src/common/utils';
import { Button } from 'src/ui/Button/Button';
import { FaArrowRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useOnboardingContext } from 'src/context/Onboarding.context';

export interface AiHelperChatProps {
  setIsAiActive: React.Dispatch<React.SetStateAction<boolean>>;
  handleSkipWizzard: () => Promise<void>;
}

export const AiHelperChat: React.FC<AiHelperChatProps> = ({ setIsAiActive, handleSkipWizzard }) => {
  const { messages, step, loading } = useSelector((state: AppStateType) => state.onboarding);
  const { user } = useSelector((state: AppStateType) => state.user);
  const [message, setMessage] = useState<string>('');

  const {} = useSubscriptions();

  const dispatch = useDispatch();
  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };
  const { t } = useTranslation();

  const { updateProfilePreview } = useOnboardingContext();
  const { loadAiChatHistory, askQuestion } = useAiAssistantStore();
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (step === OnboardingSteps.ABOUT_YOU) {
      updateProfilePreview({
        firstName: user?.firstName,
        lastName: user?.lastName,
        description: user?.expert?.description,
      });
    }
  }, [user]);

  const { isLoading: isHistoryLoading, isFetched: isHistoryLoaded } = loadAiChatHistory;

  const { mutate: sendQuestion } = askQuestion;

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];

    if (
      isHistoryLoaded &&
      (!messages.length ||
        (messages.length &&
          (lastMessage?.role === 'user' ||
            lastMessage?.renderKey === 'check-data' ||
            (lastMessage?.step !== step && lastMessage.renderKey !== 'menu'))))
    ) {
      sendQuestion({
        step,
        message: '',
        renderKey: null,
      });
    }
  }, [isHistoryLoaded]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, ref.current.scrollHeight);
    }
  }, [loading, messages]);

  const onMessageSend = () => {
    const lastMessage = messages[messages.length - 1];
    const renderKey = lastMessage?.renderKey || null;

    if (message.trim()) {
      dispatch(
        addMessage([
          {
            message,
            role: 'user',
            renderKey: null,
            senseKey: null,
            step,
          },
        ]),
      );
      sendQuestion({
        step,
        message,
        renderKey,
      });
    }
  };

  return (
    <div className='w-full flex flex-col h-[85vh] md:pt-[30px]'>
      <div className='grow overflow-y-scroll overflow-x-hidden' ref={ref}>
        {messages && messages?.length
          ? messages.map(({ message, renderKey, role, link }, idx: number, arr) => {
              return (
                <div key={idx} className='mt-2'>
                  <ChatMessage message={message} sender={role} link={link} />
                  {renderKey && idx === arr.length - 1
                    ? getInteractiveElement(renderKey, setIsAiActive)
                    : null}
                </div>
              );
            })
          : null}
        <>{isHistoryLoading || loading ? <WritingPlaceholder /> : null}</>
      </div>
      <div className='mt-[30px]'>
        <MessageInput
          placeholder={'Write your answer'}
          activeClient='bot'
          userId='test'
          isDemonstration={step >= OnboardingSteps.CHANNELS}
          onChange={handleOnChange}
          dispatch={onMessageSend}
          disabled={isHistoryLoading || loading}
          isChatbot
        />
        <div className={cn('md:mt-[44px] mt-[22px] mb-[28px] flex justify-center')}>
          <Button variant='secondary' onClick={handleSkipWizzard} className='h-fit p-0'>
            {t('onboarding.common.skipWizard')}
            <FaArrowRight />
          </Button>
        </div>
      </div>
    </div>
  );
};
