/* eslint-disable react/prop-types */
import React from 'react';
import { Button, buttonVariants } from '../Button/Button';
import { cn } from 'src/common/utils/cn';
import { useTranslation } from 'react-i18next';
import { VariantProps } from 'class-variance-authority';

type CardPickerItem = { label?: string | null; value: string | number };

interface CardPickerProps {
  headerText?: string;
  cards: CardPickerItem[];
  value?: string | number | null;
  onChange?: (val: string | number) => void;
  buttonVariant?: VariantProps<typeof buttonVariants>['variant'];
  className?: string;
  wrapperClassName?: string;
  disabled?: boolean;
}

const CardPicker: React.FC<CardPickerProps> = ({
  cards,
  headerText,
  value,
  onChange,
  className,
  wrapperClassName,
  buttonVariant = 'category',
  disabled
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn('mt-[16px]', wrapperClassName)}>
      <h3 className='header-3'>{headerText}</h3>
      <div className={cn('flex flex-wrap gap-[10px] mt-[16px]', className)}>
        {cards.map(({ value: cardValue, label }, idx) => (
          <Button
            variant={buttonVariant}
            disabled={disabled}
            type='button'
            key={idx}
            className={cn('max-w-fit', {
              'bg-primary-light text-primary':
                buttonVariant !== 'info-outline' && cardValue === value,
              'bg-info-light': buttonVariant === 'info-outline' && cardValue === value,
              'border-transparent': cardValue === value
            })}
            onClick={() => onChange?.(cardValue)}
          >
            {t(label ?? '')}
          </Button>
        ))}
      </div>
    </div>
  );
};

export type { CardPickerItem };
export { CardPicker };
