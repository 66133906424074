import * as z from 'zod';

const whatsappSettingsSchema = z.object({
  phone: z.string().regex(/^\+\d{1,3}\d{9,14}$/, 'Please, provide a real phone number'),
  phoneNumberId: z.string().min(1, "Phone number ID shouldn't be empty"),
  accessToken: z.string().min(1, "Access token shouldn't be empty"),
});

type WhatsappSettingsInputs = z.infer<typeof whatsappSettingsSchema>;

const messengerSettingsSchema = z.object({
  pageId: z.string().regex(/^\d{15}$/, 'Please, provide a real page id'),
  pageAccessToken: z.string().min(1, "Access token shouldn't be empty"),
});

type MessengerSettingsInputs = z.infer<typeof messengerSettingsSchema>;

const instagramSettingsSchema = z.object({
  pageId: z.string().regex(/^\d{15}$/, 'Please, provide a real page id'),
  pageAccessToken: z.string().min(1, "Access token shouldn't be empty"),
  username: z.string().min(1).max(30),
});

type InstagramSettingsInputs = z.infer<typeof instagramSettingsSchema>;

export type { WhatsappSettingsInputs, MessengerSettingsInputs, InstagramSettingsInputs };
export { whatsappSettingsSchema, messengerSettingsSchema, instagramSettingsSchema };
