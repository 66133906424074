import { Button } from 'src/ui/Button/Button';
import { useTranslation, Trans } from 'react-i18next';
import { Card } from 'src/ui/Card/Card';
import React from 'react';

export interface EmptyCardProps {
  handleBackToOnboarding: () => void;
}

export interface EmptyCardWrapperProps extends EmptyCardProps {
  emoji: string;
  i18nKey: string;
}

const EmptyCard = ({ handleBackToOnboarding, emoji, i18nKey }: EmptyCardWrapperProps) => {
  return (
    <Card variant='default' className='grow px-[20px] md:px-[30px] py-[22px] rounded-[12px] h-full min-h-[350px]'>
      <div className='flex flex-col items-center text-center h-full justify-center'>
        <span className='text-[64px]'>{emoji}</span>
        <div className='mt-[20px]'>
          <Trans
            i18nKey={i18nKey}
            components={{
              1: (
                <span
                  role='button'
                  className='text-primary'
                  onClick={() => handleBackToOnboarding()}
                />
              ),
            }}
          ></Trans>
        </div>
      </div>
    </Card>
  );
};

export const BusinessCardEmpty: React.FC<EmptyCardProps> = ({ handleBackToOnboarding }) => {
  return (
    <EmptyCard
      emoji='📢'
      handleBackToOnboarding={handleBackToOnboarding}
      i18nKey='dashboard.business.uncompleted'
    />
  );
};

export const AffiliateCardEmpty: React.FC<EmptyCardProps> = ({ handleBackToOnboarding }) => {
  return (
    <EmptyCard
      emoji='💸'
      handleBackToOnboarding={handleBackToOnboarding}
      i18nKey='dashboard.affiliate.uncompleted'
    />
  );
};


export const TransactionsCardEmpty: React.FC<EmptyCardProps> = ({ handleBackToOnboarding }) => {
  return (
    <EmptyCard
      emoji='💵'
      handleBackToOnboarding={handleBackToOnboarding}
      i18nKey='dashboard.transactions.uncompleted'
    />
  );
};

export const ChatsCardEmpty: React.FC<EmptyCardProps> = ({ handleBackToOnboarding }) => {
  return (
    <EmptyCard
      emoji='💬'
      handleBackToOnboarding={handleBackToOnboarding}
      i18nKey='dashboard.chats.uncompleted'
    />
  );
};
