import React, { useEffect, useRef } from 'react';
import Chart, { ChartData, ChartOptions } from 'chart.js/auto';
import { Card } from '../Card/Card';

export interface LineChartProps {
    title: string;
    summary: string | number;
    data: ChartData;
}

export const LineChart: React.FC<LineChartProps> = ({ title, summary, data }) => {
    const chartContainer = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart>();

    useEffect(() => {
        if (chartContainer.current) {
            chartInstance.current = new Chart(chartContainer.current, {
                type: 'line',
                data: data,
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            display: false
                        },
                        x: {
                            beginAtZero: true,
                            display: false
                        }
                    },
                },
            });
        }

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [data]);

    return (
      <Card variant='dashed' className='flex flex-col grow px-0 pb-0 gap-[26px]'>
        <div className='w-full pl-[15px]'>
          <div className='text-grey-500 text-[11px] uppercase tracking-widest'>{title}</div>
          <div className='text-grey-900 font-[600]'>{summary}</div>
        </div>
        <canvas ref={chartContainer} className='flex flex-grow w-6/12 h-full' />
      </Card>
    );
};

export default LineChart;