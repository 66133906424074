import React, { useEffect, useState } from 'react';
import { ConnectBrowserNotificationsButton } from './ConnectBrowserNotificationsButton';
import { useTranslation } from 'react-i18next';
import { Card } from 'src/ui/Card/Card';
import { EnableNotificationSwitch } from './EnableNotificationSwith';
import { NotificationSettings } from 'src/common/interfaces/notifications';
import {
  getNotificationSettings,
  updateNotificationSettings,
} from 'src/common/api/notification-settings';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDebounce } from 'src/common/hooks';
import { useDidMountEffect } from 'src/common/hooks/useDidMountEffect';
import { PageWrapper } from '../Common/Page.wrapper';

const NotificationsPage = () => {
  const { t } = useTranslation();
  const { refetch } = useQuery(['notification-settings'], {
    queryFn: getNotificationSettings,
    onSuccess: data => {
      setSettings(data);
    },
  });

  const { mutate: update } = useMutation({
    mutationFn: updateNotificationSettings,
    onSuccess: refetch,
  });

  const [settings, setSettings] = useState<Omit<NotificationSettings, 'id'>>({
    anamataNews: false,
    newMessagesEmail: false,
    newMessagesPush: false,
    newTransactionsEmail: false,
    newTransactionsPush: false,
  });

  const debouncedSettings = useDebounce(settings, 800);

  useDidMountEffect(() => {
    update(debouncedSettings);
  }, [debouncedSettings]);

  const onUpdate = (key: keyof typeof settings, value: boolean) => {
    setSettings(prev => ({ ...prev, [key]: value }));
  };

  return (
    <PageWrapper headerText={t('notifications.title')} activeElemets={<div className='h-[40px]' />}>
      <div className='md:mt-0 mt-[15px] flex flex-col md:flex-row gap-20 text-grey-900'>
        <Card variant={'default'} className='w-full flex flex-col sm:px-[30px] px-[20px]'>
          <div className='flex gap-2 justify-between items-center'>
            <div className='text-[14px]'>{t('notifications.chatMessages')}</div>
            <div className='flex flex-col md:flex-row items-start md:items-center gap-6 md:gap-12'>
              <div className='flex items-center gap-4 sm:justify-start min-w-[140px]'>
                <EnableNotificationSwitch
                  checked={settings?.newMessagesPush}
                  onChange={e => {
                    onUpdate('newMessagesPush', e.target.checked);
                  }}
                />
                <span className='uppercase text-grey-900 tracking-wider text-[11px]'>
                  {t('notifications.mobile')}
                </span>
              </div>
              <div className='flex items-center gap-4 sm:justify-start min-w-[140px]'>
                <EnableNotificationSwitch
                  checked={settings?.newMessagesEmail}
                  onChange={e => {
                    onUpdate('newMessagesEmail', e.target.checked);
                  }}
                />
                <div className='uppercase text-grey-900 tracking-wider text-[11px]'>
                  {t('notifications.email')}
                </div>
              </div>
            </div>
          </div>
          <hr className='border-t border-[#E5EAEE] my-[30px]' />
          <div className='flex gap-2 justify-between items-center'>
            <div className='text-[14px]'>{t('notifications.newTransactions')}</div>
            <div className='flex flex-col md:flex-row items-start md:items-center gap-6 md:gap-12'>
              <div className='flex items-center gap-4 sm:justify-start min-w-[140px] text-[11px]'>
                <EnableNotificationSwitch
                  checked={settings?.newTransactionsPush}
                  onChange={e => {
                    onUpdate('newTransactionsPush', e.target.checked);
                  }}
                />
                <span className='uppercase text-grey-900 tracking-wider'>
                  {t('notifications.mobile')}
                </span>
              </div>
              <div className='flex items-center gap-4 sm:justify-start min-w-[140px]'>
                <EnableNotificationSwitch
                  checked={settings?.newTransactionsEmail}
                  onChange={e => {
                    onUpdate('newTransactionsEmail', e.target.checked);
                  }}
                />
                <span className='uppercase text-grey-900 tracking-wider text-[11px]'>
                  {t('notifications.email')}
                </span>
              </div>
            </div>
          </div>
          <hr className='border-t border-[#E5EAEE] my-[30px]' />
          <div className='flex gap-2 justify-between items-center'>
            <div className='text-[14px]'>{t('notifications.news')}</div>
            <div className='flex items-center gap-4 sm:justify-start min-w-[140px]'>
              <EnableNotificationSwitch
                checked={settings?.anamataNews}
                onChange={e => {
                  onUpdate('anamataNews', e.target.checked);
                }}
              />
              <span className='uppercase text-grey-900 tracking-wider text-[11px]'>
                {t('notifications.email')}
              </span>
            </div>
          </div>
          <hr className='border-t border-[#E5EAEE] my-[30px]' />
          <div className='flex gap-2 justify-between items-center'>
            <div className='text-[14px]'>{t('notifications.browser')}</div>
            <ConnectBrowserNotificationsButton />
          </div>
        </Card>
      </div>
    </PageWrapper>
  );
};

export { NotificationsPage };
