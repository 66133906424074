export const chatMessageStyles = {
    user: {
        placeholder: 'justify-end',
        messageWrapper: 'bg-light',
        text: 'text-black',
        dateText: 'text-grey'
    },
    client: {
        placeholder: 'justify-start',
        messageWrapper: 'bg-white',
        text: 'text-black',
        dateText: 'text-grey'
    },
    expert: {
        placeholder: 'justify-end',
        messageWrapper: 'bg-blue',
        text: 'text-white',
        dateText: 'text-white'
    },
    assistant: {
        placeholder: 'justify-start',
        messageWrapper: 'bg-info',
        text: 'text-white',
        dateText: 'text-white'
    }
}