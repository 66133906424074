/* eslint-disable react/prop-types */

import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { FaCheck as CheckIcon } from 'react-icons/fa6';

import { cn } from 'src/common/utils';

interface CheckBoxProps {
  color?: string;
}

const CheckBox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & CheckBoxProps
>(({ className, color, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer h-6 w-6 shrink-0 rounded-md bg-secondary focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
      {
        'bg-primary text-white': props.checked,
        [`bg-${color} text-white`]: props.checked && color,
      },
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current ')}>
      <CheckIcon className='w-4 h-4 ' />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
CheckBox.displayName = CheckboxPrimitive.Root.displayName;

export { CheckBox };
