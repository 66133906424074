import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/ui/DropdownMenu/DropdownMenu';
import { FaArrowRight, FaQuestionCircle } from 'react-icons/fa';
import { Button } from 'src/ui/Button/Button';
import { ProgressBar } from 'src/ui/ProgressBar/ProgressBar';
import { Trans } from 'react-i18next';
import { CheckBox } from 'src/ui/CheckBox/CheckBox';
import { useOnboardingStatus } from 'src/common/hooks';
import { cn } from 'src/common/utils';

export const OnboardingMenuContent = ({ className }: { className?: string }) => {
  const { status, t, handleBackToOnboarding, completedAmount } = useOnboardingStatus();

  return (
    <div>
      <div className='flex items-center px-[30px] py-[17px] gap-4'>
        <div
          className={cn(
            'flex flex-col gap-2 truncate text-grey-900 leading-[22px] text-[14px] font-bold',
            className,
          )}
        >
          {t('common.onboardingStatus.desktop.title')}
        </div>
      </div>
      <ProgressBar total={Object.values(status).length} completed={completedAmount} />
      <div className='flex flex-col gap-[15px] my-[20px]'>
        {Object.values(status).map(({ completed, title }, idx) => (
          <div className='flex gap-[10px] items-center' key={idx}>
            <CheckBox checked={completed} />
            <span
              className={cn('leading-[22px] text-[14px] text-primary', {
                'text-grey-500 line-through': !completed,
              })}
            >
              <label>{title}</label>
            </span>
          </div>
        ))}
      </div>
      <DropdownMenuSeparator />
      <Button
        variant='secondary'
        className='font-bold w-full'
        onClick={() => handleBackToOnboarding()}
      >
        {t('common.onboardingStatus.desktop.backButton')}
        <FaArrowRight />
      </Button>
    </div>
  );
};

const OnboardingMenu = () => {
  const { isCompleted } = useOnboardingStatus();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className='flex justify-center hover:text-blue hover:cursor-pointer text-grey-500'>
          <FaQuestionCircle className='w-5 h-5' />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent side='right' className='w-fit px-[20px]' align='start'>
        {!isCompleted ? (
          <OnboardingMenuContent />
        ) : (
          <div className='text-[14px] py-[15px]'>
            <Trans
              i18nKey={'onboarding.common.completed'}
              components={{
                1: (
                  <a
                    href={`mailto:${process.env.REACT_APP_TARGET_EMAIL}`}
                    className='text-primary'
                  ></a>
                ),
              }}
            ></Trans>
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { OnboardingMenu };
