import React from 'react';
import { AiPhotoUploading } from './AiPhotoUploading';
import { AiExpertise } from './AiExpertise';
import { AiThematicTemplate } from './AiThematicTemplate';
import { AiSubscriptions } from './AiSubscriptions';
import { AiPaymentDate } from './AiPaymentDate';
import { AiPaymentState } from './AiPaymentStates';
import { AiMenu } from './AiMenu';
import { AiStripeConnect } from './AiStripeConnect';
import { AiStripeError } from './AiStripeError';

export const getInteractiveElement = (
  key: string,
  setIsAiActive: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  switch (key) {
    case 'avatar':
      return <AiPhotoUploading />;

    case 'expertise':
      return <AiExpertise />;

    case 'thematicTemplate':
      return <AiThematicTemplate/>;

    case 'subscriptions':
      return <AiSubscriptions/>;

    case 'birthday':
      return <AiPaymentDate/>;

    case 'state':
      return <AiPaymentState/>;

    case 'menu':
      return <AiMenu />;

    case 'stripe':
      return <AiStripeConnect/>;

    case 'check-data':
      return <AiStripeError setIsAiActive={setIsAiActive} />;

    default:
      return <></>;
  }
};
