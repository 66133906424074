import * as z from 'zod';

const emailSchema = z.object({
  email: z
    .string({
        errorMap: () => ({
          message: 'Firstname is required',
        }),
      })
    .email('This is not a valid email')
});

export { emailSchema };