import React, { useState, useMemo, useEffect } from 'react';
import { DashboardCard, DashboardCardStat, type DashboardCardProps } from './DashboardCard';
import { ROUTES } from 'src/router/routes';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getTransactionsForDashboard } from 'src/common/api/transactions';
import { useTranslation } from 'react-i18next';

export const TransactionsCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [stats, setStats] = useState<null | any>(null);
  const [dateRange, setDateRange] = useState<string | null>(null);

  const { isLoading } = useQuery(['chat-stats', dateRange], {
    queryFn: () => getTransactionsForDashboard(dateRange),
    onSuccess: data => {
      setStats(data);
    },
  });

  const params = useMemo(() => {
    return {
      btnText: t('dashboard.transactions.buttonText'),
      onClick: () => navigate(`../${ROUTES.TRANSACTIONS}`),
      title: t('dashboard.transactions.title'),
      stats,
      showDatePicker: true,
    };
  }, [stats]);

  // @ts-ignore
  return !isLoading && params.stats ? (
    <DashboardCard {...params} dateRange={dateRange} setDateRange={setDateRange} />
  ) : null;
};
