import React from 'react';
import { useTranslation } from 'react-i18next';

export const TutorialHeading: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <h2 className='md:header-2 flex items-center text-start gap-2 font-bold pt-[20px] pb-[23px] xl:pt-0 xl:pb-0 xl:block xl:my-4 xl:block xl:bg-transparent bg-white text-[16px]'>
      {children}
      {t('onboarding.tutorials.title')}
    </h2>
  );
};
