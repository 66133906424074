import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'src/common/api/user';
import { selectUser, updateUserLocaly } from '../user/user.slice';
import { AppStateType } from '..';

const useOnboardingStore = () => {
  const user = useSelector(selectUser);
  const { step } = useSelector((state: AppStateType) => state.onboarding);
  const dispatch = useDispatch();

  const backToOnboarding = useMutation({
    mutationFn: () =>
      updateUser({
        id: user?.id!,
        hadUserOnboarding: false,
      }),
    onSuccess: data => {
      dispatch(updateUserLocaly(data));
    },
  });

  return {
    step,
    backToOnboarding
  };
};

export { useOnboardingStore };
