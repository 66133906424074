import React from 'react';
import { cn } from 'src/common/utils/cn';
import { Avatar, AvatarFallback, AvatarImage } from '../Avatar/Avatar';
import { getUserInitials } from 'src/common/utils/getUserInitials';
import {
  ChatVariant,
  MessageVariant,
  MessengerType,
  ChatMessage as ChatMessageT,
  UploadedFile,
  ChatStatus,
} from 'src/common/interfaces';
import { DatePlaceholder } from 'src/components/DatePlaceholder';
import { MessengerIcon } from '../MessengerIcon/MessengerIcon';
import { useTranslation } from 'react-i18next';

export interface ChatItemProps extends Partial<Pick<ChatMessageT, 'messengerType' | 'attachments'>> {
  username: string;
  userImage: string;
  unreadCount: number;
  date?: string;
  message?: string;
  messengerType?: MessengerType;
  className?: string;
  messageVariant?: MessageVariant;
  chatVariant?: ChatStatus;
  whitespaceNormal?: boolean;
}

const ChatItem = ({
  username,
  userImage,
  unreadCount,
  date,
  message,
  messengerType,
  className,
  attachments,
  chatVariant = ChatStatus.ACTIVE,
  whitespaceNormal,
  messageVariant,
}: ChatItemProps) => {
  const { t } = useTranslation();

  const isPaymentLink =
    messageVariant === MessageVariant.PAYMENT_LINK ||
    messageVariant === MessageVariant.CLIENT_APPROVED;

  const isRequest = chatVariant === 'requested';

  const messageBody = isPaymentLink
    ? t('chats.tips.paymentLink')
    : isRequest && !message
    ? t('chats.tips.newRequest')
    : message;

  const attachmentsCount = attachments?.length;

  const preview = attachments?.[0]?.type === 'image' ? attachments?.[0] : null;
  const previewMessage = getPreviewMessageByAttachments({ attachments });

  return (
    <div
      className={cn(
        'flex items-center justify-between py-[10px] px-[15px] w-full rounded-md bg-opacity-10 cursor-pointer bg-white',
        className,
      )}
    >
      <div className='flex items-center w-full truncate'>
        <div className='relative'>
          <Avatar className='w-[60px] h-[60px]'>
            <AvatarImage src={userImage} alt={username} />
            <AvatarFallback>{getUserInitials(username)}</AvatarFallback>
          </Avatar>
          {messengerType && (
            <div
              className={cn(
                'absolute bottom-0 right-0 w-6 h-6 rounded-full flex items-center justify-center z-10 border-white border-[1.9px]',
                {
                  'bg-whatsapp': messengerType === MessengerType.WHATSAPP,
                  'bg-instagram': messengerType === MessengerType.INSTAGRAM,
                  'bg-messenger': messengerType === MessengerType.MESSENGER,
                  'bg-telegram': messengerType === MessengerType.TELEGRAM,
                },
              )}
            >
              <MessengerIcon messengerType={messengerType} className='w-4 h-4 text-white' />{' '}
            </div>
          )}
        </div>
        <div className='ml-4 flex flex-col w-full truncate text-[14px]'>
          <div className='flex w-full justify-between'>
            <p
              className={cn('font-[600] truncate text-[14px]', {
                'whitespace-normal': whitespaceNormal,
              })}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {username}
            </p>
            {date && (
              <DatePlaceholder
                date={date}
                variant='date-no-today'
                className='inline-block w-auto text-[12px]'
              />
            )}
          </div>
          <div className='flex w-full justify-between'>
            {!!attachmentsCount && (
              <div className='flex gap-2 items-center'>
                {preview && (
                  <img
                    src={preview.src}
                    alt={preview.alt || 'image'}
                    width={16}
                    height={16}
                    className='max-h-6'
                  />
                )}
                <span className='text-primary text-[14px]'>{previewMessage}</span>
              </div>
            )}
            <p
              className={cn('text-[14px] text-grey truncate', {
                'whitespace-normal': whitespaceNormal,
                'text-danger': messageVariant === MessageVariant.PAYMENT_REJECTED,
                'text-primary': isPaymentLink || chatVariant === ChatStatus.REQUESTED,
                'text-success': messageVariant === MessageVariant.PAYMENT_SUCCESS,
              })}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {messageBody}
            </p>
            {!!unreadCount && (
              <div className='text-xs bg-blue rounded-full  text-white min-w-[20px] min-h-[20px] flex items-center justify-center font-semibold'>
                {unreadCount > 9 ? '9+' : unreadCount}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const getPreviewMessageByAttachments = ({ attachments }: { attachments?: UploadedFile[] }) => {
  const { t } = useTranslation();

  const attachmentsCount = attachments?.length;

  if (attachments?.[0]?.type === 'image' || attachments?.[0]?.type === 'file') {
    return (
      attachmentsCount &&
      `${attachmentsCount} ${
        attachmentsCount > 1 ? t('chats.tips.attachment.plural') : t('chats.tips.attachment.single')
      }`
    );
  }

  if (attachments?.[0]?.type === 'audio') {
    return t('chats.tips.attachment.audio');
  }

  return '';
};

export default ChatItem;
