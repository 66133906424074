import React from 'react';
import { DashboardCard } from './DashboardCard';
import { useTranslation } from 'react-i18next';

export const AffiliateCard = () => {
  const { t } = useTranslation();

  const params = {
    btnText: 'Invite more experts',
    onClick: () => {},
    stats: [
      {
        amount: '$950',
        text: t('dashboard.affiliate.revenue'),
      },
      {
        amount: 10,
        text: t('dashboard.affiliate.new_subscribers'),
      },
      {
        amount: 23,
        text: t('dashboard.affiliate.sub_consultations'),
      },
    ],
    title: t('dashboard.affiliate.title'),
    showDatePicker: true,
  };

  return <DashboardCard {...params} dateRange={'last30'} />;
};
