import React from 'react';
import { Button } from 'src/ui/Button/Button';
import { FaSpinner } from 'react-icons/fa6';
import { Card } from 'src/ui/Card/Card';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { deleteUser } from 'src/common/api/user';
import { useLogout } from 'src/common/hooks/useLogout';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'src/ui/AlertDialog/AlertDialog';

const DeleteAccountCard = () => {
  const { t } = useTranslation();
  const { logout } = useLogout();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => deleteUser(),
    onSuccess: logout,
  });

  return (
    <Card className='rounded-[12px]'>
      <div className='flex flex-col gap-8'>
        <h2 className='text-[18px] font-bold'>{t('profileSettings.deleteAccount.title')}</h2>
        <p>
          <Trans i18nKey={'profileSettings.deleteAccount.description'} components={{ 1: <b /> }} />
        </p>
        <AlertDialog>
          <AlertDialogTrigger className='w-full' asChild>
            <Button
              variant={'outlined'}
              className='text-danger font-semibold w-full'
              type='button'
              disabled={isLoading}
            >
              {t('profileSettings.deleteAccount.button')}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent className='max-w-[800px] text-grey-900 sm:py-20 sm:rounded-xl'>
            <AlertDialogHeader className='sm:text-center'>
              <h1 className='text-[64px]'>⚠️</h1>
              <AlertDialogTitle className='font-bold text-2xl'>
                {t('profileSettings.deleteAccount.alert.title')}
              </AlertDialogTitle>
              <AlertDialogDescription>
                {t('profileSettings.deleteAccount.alert.description')}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className='flex-col sm:justify-center gap-2 sm:mt-6'>
              <AlertDialogAction asChild onClick={() => mutate()} className='font-semibold'>
                <Button variant={'danger'}>
                  {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
                  {t('profileSettings.deleteAccount.alert.action')}
                </Button>
              </AlertDialogAction>
              <AlertDialogCancel asChild className='font-semibold'>
                <Button variant={'primary'}>
                  {t('profileSettings.deleteAccount.alert.cancel')}
                </Button>
              </AlertDialogCancel>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </Card>
  );
};

export { DeleteAccountCard };
